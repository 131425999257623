import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import PaidAccount from "./PaidAccount";
import { lineSpinner } from 'ldrs';
import { maskContact } from '../customfunction/dateformatmonth';
import { Tipaccountadmin } from "./Tipacountadmin";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./QuickSearch.css";
import Serviceadmin from "./Serviceadmin";
import toast from "react-hot-toast";
import ModuleWalkin from "./ModuleWalkin";
import Paidadmin from "./Paidadmin";
import UpdateService from "./UpdateService";
import { encryptData } from "../cryptoutils/cryptoutils";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";


lineSpinner.register();

const Nearby = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true)
  const[backstate,setbackstate]=useState(false)
  const [updatemodule, setUpdatemodule] = useState(false);
  const[walkinshow ,setwalkinshow]=useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState({ startdate: "", enddate: "" });
  const [report, setReport] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [renewForm, setRenewForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [customer, setCustomerName] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [customerContact, setCustomerContact] = useState(null);
  const [paidAmount, setPaidAmount] = useState(null);
  const [branch, setBranch] = useState(null);
  const [tipShow, setTipShow] = useState(false);
  const [paidShow, setPaidShow] = useState(false);
  const [pending, setPending] = useState(null);
  const[branchh,setbranchname]=useState(null)
  const[userid,setuserid]=useState(null)
  const [branchNameData, setBranchNameData] = useState([]);
  const selector = useSelector(state => state);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  useEffect(() => {
    const fetchBranchNames = async () => {
      const obj = {
        query: `[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr: []
      };
      const getData = await Hexapi(obj);
      setBranchNameData(getData.branch || []);
    };
    fetchBranchNames();
  }, []);



  const handleCancel = async (cancelId) => {
    const obj = {
      query: `[dbo].[spa_software_web_proc_nearby_cancel_query]@cancelid='{0}'`,
      queryArr: [`${cancelId}`]
    };
    const cancelData = await Hexapi(obj);
    console.log(cancelData, "cancel neraby")
    toast.success(cancelData[""][0].msg)
    handleReport()
    // setwalkinshow(true)
  };


  const handleViewService = (user_id, memberId, cust_name, cust_contact, cust_paid, branch_name) => {
    setSelectedMemberId(memberId);
    setUserId(user_id);
    setCustomerName(cust_name);
    setCustomerContact(cust_contact);
    setPaidAmount(cust_paid);
    setBranch(branch_name);
    setShow(true);
  };

  const handleRenew = (user_id, memberId, cust_name, cust_contact) => {
    setSelectedMemberId(memberId);
    setUserId(user_id);
    setCustomerName(cust_name);
    setCustomerContact(cust_contact);
    setRenewForm(true);
  };

  const handleTip = (memberId, userId) => {
    setUserId(userId);
    setSelectedMemberId(memberId);
    setTipShow(true);
  };

  const handlePaid = (memberId, cust_bal_amount, userId) => {
    setSelectedMemberId(memberId);
    setPending(cust_bal_amount);
    setUserId(userId);
    setPaidShow(true);
  };

  const handleupdate = (userid,branchname) => {
    setuserid(userid);
    setUpdatemodule(true);
    setbranchname(branchname)
  };

  const handleReport = async () => {

    if (!data.startdate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the start date.",
      });
    
    } else if (!data.enddate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the end date.",
      });
    }else{
      try {
        setLoading(true);
      const obj = {
        query: `[dbo].[spasofware_web_proc_report_nearby]@startdate='{0}',@enddate='{1}'`,
        queryArr: [`${data.startdate}`, `${data.enddate}`]
      };
      const getData = await Hexapi(obj);
      setReport(getData[""] || []);
      setLoading(false);
      } catch (error) {
       toast.error("something went wrong please refresh")
      }
    }
    

  };

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const formattedData = report.map((item,i) => ({
    'Sr no': i+1,
    'DOJ':item.cust_dop,
    'Walking ID' : item.member_id,
    'Time In - Time Out' : `${item.time_in} - ${item.time_out}`,
    'Customer Name':item.cust_name,
    'Customer Number':item.cust_contact,
   'Payment Mode': `${item.payment_mode} -  Security code${item.security_code}` ,
   'Total Amount':item.cust_total_amount,
    'Paid Amount':item.cust_paid_amount,
   'Pending Amount': item.cust_bal_amount,
   'Total Service': item.cust_total_service ,
    'Used Service':item.cust_used_service ,
    'Balance Service': item.cust_bal_service,
   'Create By': item.create_by,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Nearby_Sale_Report' + fileExtension;
    saveAs(data, fileName);
  };
  const columns = useMemo(
    () => [
      { Header: "Sr No", accessor: (_, i) => i + 1 },
      { Header: "DOJ", accessor: "cust_dop" },
      {
        Header: "Walking Id",
        accessor: "member_id",
        Cell: ({ row }) => (
          <Link
          target="_blank" rel="noopener noreferrer"
            className=" curpoint"
            to={`/edit/${encodeURIComponent(encryptData(row.original.user_id))}`}
          >
            {row.original.member_id}
          </Link>
        )
      },
      { Header: "Time In - Time Out", accessor: (row) => `${row.time_in}-${row.time_out}` },
      { Header: "Customer Name", accessor: "cust_name" },
      { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
      { Header: "Payment Mode", accessor: (row) => `${row.payment_mode} ${row.security_code}` },
      { Header: "Total Amount", accessor: "cust_total_amount" },
      { Header: "Paid Amount", accessor: "cust_paid_amount" },
      { Header: "Pending Amount", accessor: "cust_bal_amount" },
      { Header: "Total Service", accessor: "cust_total_service" },
      { Header: "Used Service", accessor: "cust_used_service" },
      { Header: "Balance Service", accessor: "cust_bal_service" },
      { Header: "Create By", accessor: "create_by" },
      {
        Header: "Booking",
        accessor: "booking",
        Cell: ({ row }) => (
          <Link target="_blank" rel="noopener noreferrer" to={`/serviceadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Service</Link>
        )
      },
      {
        Header: "Tip",
        accessor: "tip",
        Cell: ({ row }) => (
          <>
            <Link target="_blank" rel="noopener noreferrer" to={`/tipadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link>
            {new Date().toISOString().split('T')[0] ? <button className="btn-click-3 mt-1" onClick={()=>{handleCancel(row.original.user_id)}}>cancel</button> : null}
          </>
        )
      },
      {
        Header: "Pending",
        accessor: "pending",
        Cell: ({ row }) => (
          <Link target="_blank" rel="noopener noreferrer" to={`/paidadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Paid</Link>
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter }, // Add globalFilter to state
    setGlobalFilter, // Add setGlobalFilter method
  } = useTable(
    {
      columns,
      data: report || [],
      initialState: { pageIndex: 0, pageSize: itemsPerPage }, // Set initial page index to 0
    },
    useGlobalFilter, // Add useGlobalFilter hook
    useSortBy,
    usePagination
  );
  const totalEntries = useMemo(() => {
    return report.length;
  }, [report]);

  const startIndex = pageIndex * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalEntries);
  const remainingEntries = totalEntries - endIndex;




  const handleChangeReport = (date, name) => {
    if(date){
      setData({ ...data, [name]: date.toISOString().split('T')[0] });
    }else{
      setData({ ...data, [name]: ""});
    }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date().toISOString().split('T')[0] });
    }
  };
  return (
    <div>
      {!show && !renewForm && !tipShow && !paidShow && !walkinshow && !updatemodule ? (
        <>
          <div className="quick_search_page">
            <div className="widget-title">
              <p className="name-text">Search Report</p>
              <span >

                  <FaChevronDown  onClick={toggleFormVisibility}/>

              </span>
            </div>
          </div>
          {isFormVisible && (
            <div className="widget-body form">
              <div
                name="dname"
                id="dname1"
                className="form-horizontal"

              >
                <div className="control-group-div">
                  <div className="control-group">
                    <label className="control-label">Select Start Date</label>
                    <div className="controls">
                    <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                    </div>
                  </div>
                  <div className="control-group">
                    <label className="control-label">Select End Date</label>
                    <div className="controls">
                    <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                    </div>
                  </div>
                  <div className="form-actions-control">
                    <button
                      type="submit"
                      name="ShowInfoBtn"
                      className="btn btn-success"
                      id="show_btn"
                      onClick={handleReport}
                    >
                      Show Report
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          )}

          <div className="container mb-5">
            <div className="row bg-body-tertiary mt-4">
              <div className="dashservice">
                <p className="name-text"> Walking Accounts : <span className="text-danger">Branch :</span></p>
                <FaChevronDown onClick={() => { setShowTable(!showTable) }} />
              </div>
              <div>
              {showTable && (
  <div>

<div className="d-flex justify-content-between mt-2">


   <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50, 75, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>

    <input
      type="text"
      value={globalFilter || ""}
      onChange={e => setGlobalFilter(e.target.value)}
      placeholder="Search..."
    />


          </div>
    <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>

      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? " 🔽"
                      : " 🔼"
                    : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {loading ? (
          <tr>
          <td colSpan={17} className="text-center">
          <Spinner animation="border" />
        </td>
           </tr>
        ) : report.length === 0 ? (
          <td className=" fs-6" colSpan={15}>No Data Available</td>
        ) : (
          page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })
        )}
      </tbody>
    </Table>

 <div className="d-flex justify-content-between mb-3">
    <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
    <div className="pagination">
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        Previous
      </button>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        Next
      </button>
    </div>
    </div>
    <button onClick={exportToExcel} className="btn-click-6">
        Generate Report
      </button>

  </div>
)}


              </div>
            </div>
          </div>
        </>
      ) : null}
 
    </div>
  );
};

export default Nearby;

