import React from 'react'
import useNavwidth from '../hooks/Navbarwidth'
import Sidebardata from './Sidebardata'




const CustomSidebar = ({click}) => {

    const{width}=useNavwidth()
  return (
    <div>
        { width>850 ? <Sidebardata click={click}/>: null}
    </div>
  )
}

export default CustomSidebar