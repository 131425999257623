import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import PaidAccount from "./PaidAccount";
import Viewmembershipserviceuser from "./Viewmembershipserviceuser";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { lineSpinner } from 'ldrs';
import { formatDate, maskContact } from '../customfunction/dateformatmonth';
import { Tipaccountadmin } from "./Tipacountadmin";
import { default as Select } from 'react-select';
import toast from "react-hot-toast";
import UpdateService from "./UpdateService";
import Paidadmin from "./Paidadmin";
import Serviceadmin from "./Serviceadmin";
import { encryptData } from "../cryptoutils/cryptoutils";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

lineSpinner.register();

const ModuleWalkin = () => {
  const [isFormVisible, setFormVisible] = useState(true);
  const [data, setData] = useState({ branch_name: "", startdate: "", enddate: "" });
  const [report, setReport] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [renewForm, setRenewForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [customerContact, setCustomerContact] = useState(null);
  const [paidAmount, setPaidAmount] = useState(null);
  const [branch, setBranch] = useState(null);
  const [tipShow, setTipShow] = useState(false);
  const [paidShow, setPaidShow] = useState(false);
  const [pending, setPending] = useState(null);
  const [branchNameData, setBranchNameData] = useState([]);
  const[updateservice,setupdateservice]=useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBranchNames = async () => {
      const obj = {
        query: `[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr: []
      };
      try {
        const getData = await Hexapi(obj);
        setBranchNameData(getData.branch || []);
      } catch (error) {
        toast.error("Failed to fetch branch names");
      }
    };

    fetchBranchNames();
  }, []);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleCancel = async (cancelid) => {
    try {
     setTimeout(() => {
      handleReport();
     }, 500);
    } catch (error) {
      toast.error("Failed to cancel membership. Please try again.");
      console.error("handleCancel error:", error);
    }
  };

  const handleViewService = (userId, memberId, custName, custContact, custPaid, branchName) => {
    setSelectedMemberId(memberId);
    setUserId(userId);
    setCustomerName(custName);
    setCustomerContact(custContact);
    setPaidAmount(custPaid);
    setBranch(branchName);
    setShow(true);
  };

  const handleRenew = (userId, memberId, custName, custContact) => {
    setSelectedMemberId(memberId);
    setUserId(userId);
    setCustomerName(custName);
    setCustomerContact(custContact);
    setRenewForm(true);
  };

  const handleTip = (memberId, userId) => {
    setSelectedMemberId(memberId);
    setUserId(userId);
    setTipShow(true);
  };

  const handlePaid = (memberId, custBalAmount, userId) => {
    setSelectedMemberId(memberId);
    setPending(custBalAmount);
    setUserId(userId);
    setPaidShow(true);
  };

  const handleReport = async () => {

    setTimeout(() => {
      console.log(data.branch_name,data.startdate,data.enddate)
    }, 300);
    setLoading(true);
    if (!data.branch_name) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the branch name.",
      });
    } else if (!data.startdate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the start date.",
      });
    
    } else if (!data.enddate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the end date.",
      });
     
    }else{
      const obj = {
        query: `[dbo].[spasofware_web_proc_module_view_walking_account]@branchname='{0}',@startdate='{1}',@enddate='{2}'`,
        queryArr: [`${data.branch_name}`, `${data.startdate}`, `${data.enddate}`]
      };
      try {
        const getData = await Hexapi(obj);
        console.log(getData,"getdata--------")
        setReport(getData[""] || []);
        setLoading(false)
      } catch (error) {
        toast.error("Failed to fetch report data");
      }
    }
  
  };

  const handleDateChange = (date, name) => {
   if(date){
    setData({ ...data, [name]: date.toISOString().split('T')[0] });
   }else{
    setData({ ...data, [name]: "" });
   }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date().toISOString().split('T')[0] });
    }
  };

  const handleBranchChange = (selectedOption) => {
    setData({ ...data, branch_name: selectedOption ? selectedOption.value : '' });
  };

  const branchOptions = branchNameData.map(br => ({
    value: br.branchname,
    label: br.branchname
  }));

const handleupdate=(userid)=>{
setUserId(userid)
setupdateservice(true)
  }

  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{formatDate(row.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
    { Header: "Walking ID", accessor: "member_id" },
    { Header: "Time In - Time Out", accessor: (row) => (row.time_in !== null && undefined)  && `${row.time_in} - ${row.time_out}` },
    {
      Header: "Customer Name",
      accessor: "cust_name",
      Cell: ({ row }) => (
        <Link
        target="_blank" rel="noopener noreferrer"
          className=" curpoint"
          to={`/edit/${encodeURIComponent(encryptData(row.original.user_id))}`}
        >
          {row.original.member_id}
        </Link>
      )
    },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Created By", accessor: "create_by" },
    {
      Header: "Booking",
      accessor: "booking",
      Cell: ({ row }) => (
        <Link target="_blank" rel="noopener noreferrer" to={`/serviceadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Booking</Link>
      )
    },
    {
      Header: "Tip",
      accessor: "tip",
      Cell: ({ row }) => (
        <>
        <Link target="_blank" rel="noopener noreferrer" to={`/tipadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link>

        {new Date() ? <button className="btn-click-3" onClick={()=>{
          handleCancel(row.original.user_id)
        }}>cancel</button> : null}
        </>
      )
    },
    {
      Header: "Pending",
      accessor: "pending",
      Cell: ({ row }) => (
        <Link target="_blank" rel="noopener noreferrer" to={`/paidadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>paid</Link>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);

  return (
    <>
      {!show && !renewForm && !tipShow && !paidShow && !updateservice &&  (
        <>
          <div className="quick_search_page">
            <div className="widget-title">
              <p className="name-text">Search Report</p>
              <span className="">

                  <FaChevronDown  onClick={toggleFormVisibility}/>

              </span>
            </div>
          </div>
          {isFormVisible && (
            <div className="widget-body form">
              <div name="dname" id="dname1" className="form-horizontal">
                <div className="control-group-div">
                  <div className="control-group">
                    <label className="control-label">Select SPA Branch</label>
                    <div className="controls">
                      <Select
                        className="span6"
                        id="therapist_weekly_off"
                        name="branch_name"
                        options={branchOptions}
                        value={branchOptions.find(option => option.value === data.branch_name)}
                        onChange={handleBranchChange}
                        isClearable
                        classNamePrefix="react-select"
                        placeholder="Select Branch Name"
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <label className="control-label">Select Start Date</label>
                    <div className="controls">
                      <DatePicker
                        selected={data.startdate  || ""}
                        onChange={(date) => handleDateChange(date, 'startdate')}
                        className="span6 popovers"
                        dateFormat="dd/MM/yyyy"
                        onFocus={() => setCurrentDateIfEmpty('startdate')}
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <label className="control-label">Select End Date</label>
                    <div className="controls">
                      <DatePicker
                        selected={data.enddate || ""}
                        onChange={(date) => handleDateChange(date, 'enddate')}
                        className="span6 popovers"
                        dateFormat="dd/MM/yyyy"
                        onFocus={() => setCurrentDateIfEmpty('enddate')}
                      />
                    </div>
                  </div>
                  <div className="form-actions-control">
                    <button
                      type="submit"
                      name="ShowInfoBtn"
                      className="btn btn-success"
                      id="show_btn"
                      onClick={handleReport}
                    >
                      Show Report
                    </button>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          )}
          <div className="container mb-5">
            <div className="row bg-body-tertiary mt-4">
              <div className="dashservice">
                <p className="name-text">
                  Walking Accounts: <span className="text-danger">Branch: {data.branch_name}</span>
                </p>
                <FaChevronDown onClick={() => { setShowTable(!showTable) }} />
              </div>
              <div>
                {showTable && (
                  <div className="mb-3">
                    <div className="d-flex justify-content-between mt-2">

                     <select
                        value={pageSize}
                        onChange={(e) => {
                          setItemsPerPage(Number(e.target.value));
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 20, 30, 40, 50,75,100].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                      <input
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      className=" mb-3"
                      placeholder="search..."
                    />
                      </div>
                    <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {loading ? (
                          <tr>
                          <td colSpan={17} className="text-center">
                          <Spinner animation="border" />
                        </td>
                           </tr>
                        ) : report.length === 0 ? (
                          <tr>
                            <td className="fs-6" colSpan={17}>No Data Available</td>
                          </tr>
                        ) : (
                          page.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                ))}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </Table>
                 <div className="d-flex justify-content-between mb-3">
                 <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                 <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>

                    </div>
                 </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {paidShow && <Paidadmin memberId={selectedMemberId} pending={pending} userId={userId} setback={()=>{setPaidShow(false)}}/>}
      {tipShow && <Tipaccountadmin memberId={selectedMemberId} userId={userId}  setback={()=>{setTipShow(false)}} />}
      {show && <Serviceadmin data={data} memberId={selectedMemberId} userId={userId} customerName={customerName} customerContact={customerContact} paidAmount={paidAmount} branchName={branch}  setback={()=>{setShow(false)}} />}
      {updateservice && <UpdateService userid={userId} setback={()=>{setupdateservice(false)}} handlequerydata={handleReport}/>}
    </>
  );
};

export default ModuleWalkin;
