import React from 'react';
import './Breadcrumb.css';
import { FaHome } from "react-icons/fa";
import { useSelector } from 'react-redux';

const Breadcrum = () => {
    const selector =useSelector(state=>state)

    return (
<div className='breadcrum'>

<span className='text-bread'><FaHome /></span>
                <span className='text-bread1'>{selector.details.data &&  selector.details.data[""] && selector.details.data[""][0] && selector.details.data[""][0].spa_name}</span>
                <span className='text-bread2'>{selector.details.data &&  selector.details.data[""] && selector.details.data[""][0] && selector.details.data[""][0].spa_branch_name}</span>
</div>


    );
};

export default Breadcrum;
