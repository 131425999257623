import React from 'react'
import useNavwidth from '../hooks/Navbarwidth'
import Navbarcomponent from './Navbarcomponent'
import Secondnavbar from './Secondnavbar'

const Customnavbar = () => {
    const{width}=useNavwidth()
  return (
  <>
  {width>850 ? <Navbarcomponent/> : <Secondnavbar/>}
  </>
  )
}

export default Customnavbar