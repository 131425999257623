import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from "react";
import "./QuickSearch.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown} from "react-icons/fa";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";


import { lineSpinner } from 'ldrs'
lineSpinner.register()


function Saleformat() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const[branchcode,setbranchcode]=useState([])

  const[querydata,setquerydata]=useState([])


  const [isFormVisible, setFormVisible] = useState(true);
  const[loading,setloading]=useState(false)
  const [data,setdata]=useState({
    startdate:"",
    enddate:""
  })
  const [datadop,setdatadop]=useState({
    startdatedop:-1,
    enddatedop:-1
  })

  const handlequerydata = async () => {
   
    if (datadop.startdatedop === undefined) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the Start Date.",
      });
      return;
    }
  
    if ( datadop.enddatedop === undefined) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the End Date.",
      });
      return;
    }
    if (new Date(datadop.startdatedop) > new Date(datadop.enddatedop)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Date Range",
        html: "Start Date must be before End Date.",
      });
      return;
    }
  
    const obj = {
      query: `[dbo].[spa_software_web_proc_monthly_sale_reportv2]@startdate='{0}',@enddate='{1}'`,
      queryArr: [`${datadop.startdatedop}`, `${datadop.enddatedop}`],
    };
  
    try {
     
      const getdata = await Hexapi(obj);
      console.log(getdata, "branch code");
  
      setbranchcode(getdata.branchcode);
      setquerydata(getdata);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "API Error",
        html: "An error occurred while fetching data. Please try again.",
      });
    }
  };
  
   useEffect(()=>{
      handlequerydata()
    },[datadop])

  const[showtable,setshowtable]=useState(true)
  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  function convertDateFormat(dateStr) {
    console.log(dateStr,"==========")
    const [year, month, day] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  }
  const handleChangeReport = (date, name) => {
   if(date){
    setdata({ ...data, [name]: date.toISOString().split('T')[0] })
   }else{
    setdata({ ...data, [name]: "" });
   }
  };
  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setdata({ ...data, [name]: new Date().toISOString().split('T')[0]  });
    }
  };
  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const formattedData = branchcode.map((branch) => {
      const branchData = querydata[branch.branchcode.toLowerCase()][0];
      return {
        "Spa Name": branch.branch_name,
        "Walkin Cash Sale": branchData['walkincashsale'] === 0 ? "" : branchData['walkincashsale'],
        "Walkin Cash Back Sale": branchData['walkincashbacksale'] === 0 ? "" : branchData['walkincashbacksale'],
        "Walkin Card Sale": branchData['walkingcardsale'] === 0 ? "" : branchData['walkingcardsale'],
        "Walkin Paytm Sale": branchData['walkingpaytmsale'] === 0 ? "" : branchData['walkingpaytmsale'],
        "Walkin Groupon Sale": branchData['walkinggrouponsale'] === 0 ? "" : branchData['walkinggrouponsale'],
        "Walkin Make My Trip Sale": branchData['walkingcardmakemytripsale'] === 0 ? "" : branchData['walkingcardmakemytripsale'],
        "Membership Cash Sale": branchData['membershipcashsale'] === 0 ? "" : branchData['membershipcashsale'],
        "Membership Card Sale": branchData['membershipcardsale'] === 0 ? "" : branchData['membershipcardsale'],
        "Membership Paytm Sale": branchData['membershippaytmsale'] === 0 ? "" : branchData['membershippaytmsale'],
        "Tip 100% Card Sale": branchData['total_tip_amountcard'] === 0 ? "" : branchData['total_tip_amountcard'],
        "Tip 100% Paytm Sale": branchData['totaltip_paytm'] === 0 ? "" : branchData['totaltip_paytm'],
        "Paid Pending Cash": branchData['pending_amount_cashpaid'] === 0 ? "" : branchData['pending_amount_cashpaid'],
        "Paid Pending Card": branchData['pending_amount_cardpaid'] === 0 ? "" : branchData['pending_amount_cardpaid'],
        "Paid Pending Paytm": branchData['pending_amount_paytmpaid'] === 0 ? "" : branchData['pending_amount_paytmpaid'],
        "Total Cash Back":branchData['walkincashbacksale'],
        "Total Cash In-Hand": branchData['total_cash_sale'],
        "Total Cash In-Bank":  branchData['total_bank_record'],
        "Total Sale":  branchData['net_sale'],
      };
    });
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Monthly_Sale_Report' + fileExtension;
    saveAs(data, fileName);
  };
  return (
     <>
        <div className="quick_search_page">
      <div className="widget-title">
        <h4>
          <i className="icon-reorder"></i>  Select Date Range
        </h4>
        <span className="">
            <FaChevronDown  onClick={toggleFormVisibility} style={{marginRight:"5px"}}/>
        </span>
      </div>
      </div>
      {isFormVisible && (
        <div class="widget-body form">
          <div
            name="dname"
            id="dname1"
            class="form-horizontal"
          >
            <div className="control-group-div">
              <div class="control-group">
                <label class="control-label">Select Start Date</label>
                <div class="controls">
                <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div class="control-group">
                <label class="control-label">Select End Date</label>
                <div class="controls">
                <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div class="form-actions-control">
                <button
                  type="submit"
                  name="ShowInfoBtn"
                  class="btn btn-success"
                  id="show_btn"
 onClick={()=>{setdatadop({
  startdatedop:convertDateFormat(data.startdate),
  enddatedop:convertDateFormat(data.enddate)
 })}}
                >
                  Show Report
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
        )}
<div className=" container mb-4">
<div className="row bg-body-tertiary mt-4">
<div className="dashservice">
 <p className="name-text"><span className="text-danger">SALE REPORT</span></p>
  <FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
 <div>
 {showtable && ( <Table className="mt-3 mb-3 bor-dis " id="saleformat" responsive bordered >
    <thead >
      <tr>
      <th >Date</th>
      <th >Records</th>
{ branchcode && branchcode.map((ele)=>{
  return(
    <th>{ele.branchcode} Sale</th>
  )
})}
      </tr>
    </thead>
    <tbody>
{loading ? <td colSpan={8} className="text-center"><Spinner animation='border'/></td> :
   <tr>
  {querydata!==null ?   <td style={{color:"#CC0000"}} className=" align-middle">{data.startdate!==""? (<p>
   {datadop.startdatedop===-1 ? null :convertDateFormat(datadop.startdatedop)}
   <br/>
   <br/>
    {datadop.startdatedop!==-1 && datadop.enddatedop!==-1 ? <p>to</p> : null}
   <br/>
   {datadop.enddatedop ===-1? null :convertDateFormat(datadop.enddatedop)}
  </p>): null}</td>: <td></td>}
  <td style={{width:"200px"}}>
										Walkin Cash Sale<br/>
										Walkin Cash Back<br/>
										Walkin Card Sale<br/>
										Walkin Paytm Sale<br/>
										Walkin Groupon Sale<br/>
										Walkin Make My trip Sale<br/>
										Membership Cash Sale<br/>
										Membership Card Sale<br/>
										Membership Paytm Sale<br/>
										Tip 100% Card Sale<br/>
										Tip 100% Paytm Sale<br/>
										Paid Pending Cash<br/>
										Paid Pending Card<br/>
										Paid Pending Paytm<br/>
										<b style={{color:"#000"}}>Total Cash Back</b><br/>
										<b style={{color:"#CC0000"}}>Total Cash In-Hand</b><br/>
										<b style={{color:"#0000FF"}}>Total Cash In-Bank</b><br/>
										<b style={{color:"#006600"}}>Total Sale</b><br/>
									</td>
                  {branchcode &&
    branchcode.map((ele) => (
      <td key={ele.branchcode}>
        {querydata[ele.branchcode.toLowerCase()] !== undefined ? (
          <>
            {querydata[ele.branchcode.toLowerCase()][0]['walkincashsale']=== 0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkincashsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['walkincashbacksale']=== 0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkincashbacksale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['walkingcardsale']=== 0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkingcardsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['walkingpaytmsale']=== 0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkingpaytmsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['walkinggrouponsale']=== 0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkinggrouponsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['walkingcardmakemytripsale'] ===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['walkingcardmakemytripsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['membershipcashsale']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['membershipcashsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['membershipcardsale']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['membershipcardsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['membershippaytmsale'] ===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['membershippaytmsale']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['total_tip_amountcard']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['total_tip_amountcard']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['totaltip_paytm']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['totaltip_paytm']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['pending_amount_cashpaid'] ===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['pending_amount_cashpaid']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['pending_amount_cardpaid']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['pending_amount_cardpaid']}<br />
            {querydata[ele.branchcode.toLowerCase()][0]['pending_amount_paytmpaid']===0 ? null : querydata[ele.branchcode.toLowerCase()][0]['pending_amount_paytmpaid']}<br />
            <b style={{ color: "#000" }}>
              {querydata[ele.branchcode.toLowerCase()][0]['walkincashbacksale']}
            </b><br />
            <b style={{ color: "#CC0000" }}>
              {querydata[ele.branchcode.toLowerCase()][0]['total_cash_sale']}
            </b><br />
            <b style={{ color: "#0000FF" }}>
              {querydata[ele.branchcode.toLowerCase()][0]['total_bank_record']}
            </b><br />
            <b style={{ color: "#006600" }}>
              {querydata[ele.branchcode.toLowerCase()][0]['net_sale']}
            </b>
          </>
        ) : null}
      </td>
    ))}
 </tr>
  }
   </tbody>
  </Table>)}
  <div>
              Showing 1 - 1 of 1 entries

            </div>
  <button onClick={exportToExcel} className="btn-click-6">
        Generate Report
      </button>
 </div>
      </div>
</div></>
  );
}
export default Saleformat