export function formatDate(dateString) {
    // Create a new Date object from the input date string
    let date = new Date(dateString);

    // Define an array of month names
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Return the formatted date string
    return `${day}-${month}-${year}`;
  }


  export function removeLastZero(dateTimeStr) {
    // Check if the datetime string contains milliseconds
    if (dateTimeStr.includes('.')) {
      // Remove the trailing zeros in the milliseconds part
      while (dateTimeStr.endsWith('0')) {
        dateTimeStr = dateTimeStr.slice(0, -1);
      }
      // If the last character is now a dot, remove it as well
      if (dateTimeStr.endsWith(':')) {
        dateTimeStr = dateTimeStr.slice(0, -1);
      }
    }
    return dateTimeStr;
  }

  export function maskContact(contact) {
    if (contact.length < 4) {
        return contact;
    }
    const lastFourDigits = contact.slice(-4);
    const maskedPart = '*'.repeat(contact.length - 4);
    return maskedPart + lastFourDigits;
}

