import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Updateappointment } from "./Updateappointment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { maskContact } from "../customfunction/dateformatmonth";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs'
lineSpinner.register();


export default function Appointment({setback}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);
  const [datadop,setdata]=useState("")
  const[load,setload]=useState(false)
  const[report,setreport]=useState([])
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const[showtable,setshowtable]=useState(true)
  const[loading,setloading]=useState(false)

  const [show, setShow] = useState(false);
 const[masterid,setedit]=useState(null)
  const selector= useSelector(state=>state)
  const date = new Date();
  const formattedDate = date.toISOString().split('T')[0];
  const [dopdatequery,setdopdatequery]=useState(formattedDate)
  console.log(dopdatequery,"datat")



  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };


const handleedit=(idmaster)=>{
setedit(idmaster)
setShow(true)
}

  const handlereport=async(e)=>{
// setloading(true)
    if(selector.details.data){
      const spa_branch_name=selector.details.data[""][0].spa_branch_name
      const obj={
        query:`[dbo].[spasoftware_web_proc_appointment_sheet_getdata_appointmentdata]@branchname='{0}',@dopdate='{1}'`,
        queryArr:[`${spa_branch_name}`,`${dopdatequery}`]
      }
      const getdata= await Hexapi(obj)
      console.log(getdata,"ijhuygtf")
      setreport(getdata.appointmentdata || [])
      // setloading(false)
    }

  }



useEffect(()=>{
 handlereport()
console.log(dopdatequery)
},[dopdatequery])

const handleback=()=>{
  setload(true)
  setTimeout(() => {
    setback()
    setload(false)
  }, 100);
}


const handleChangeReport = (date) => {
  if(date){
    setdata(date)
  }else{
    setdata("")
  }
};

const setCurrentDateIfEmpty = () => {
  if (!datadop) {
    setdata(new Date());
  }
};

const columns = useMemo(() => [
  { Header: "Sr No", accessor: (_, i) => i + 1 },
  { Header: "Customer Name",accessor: "cust_name"},
  { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
  { Header: "Appointment Date", accessor: (row)=>row.appointment_date.date.split(' ')[0] },
  { Header: "Appointment Time", accessor: "appointment_timein" },
  { Header: "Customer Type", accessor: "cust_type" },
  { Header: "Customer Id", accessor: "cust_id" },
  { Header: "Service Type", accessor: "services_type" },
  { Header: "Therapist Name", accessor: "therapist_name" },
  { Header: "Therapist Type", accessor: "therapist_type" },
  { Header: "Req of Staff", accessor: "staff_req" },
  { Header: "Total Hour", accessor: "total_hour" },
  { Header: "Create By", accessor: "create_by" },
  { Header: "Booking Status", accessor: "booking_status" },
  { Header: "Action", accessor: (row)=> {row.appointment_date.date === formattedDate ?  <td><button className="btn-click-1" onClick={()=>{handleedit(row.appointment_master_id)}}>Edit</button></td> :         <td><button className="btn-click-1">no Change</button></td>}
},

], []);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  setPageSize,
  state: { pageIndex, pageSize, globalFilter },
  setGlobalFilter,
} = useTable(
  {
    columns,
    data: report,
    initialState: { pageIndex: 0, pageSize: itemsPerPage },
  },
  useGlobalFilter,
  useSortBy,
  usePagination
);

const totalEntries = useMemo(() => report.length, [report]);
const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <div>
    { load ? <div className="text-center mt-5"><l-line-spinner
     size="40"
     stroke="3"
     speed="1"
     color="black"
     className="relative"

   ></l-line-spinner></div> :( <div>
    {!show ? (<>

    {/* <Createappointment/> */}
      <div className="mt-3">
    <div className="dashservice">
      <p className="name-text">
      <IoMdArrowRoundBack  onClick={handleback} /> &nbsp;&nbsp;&nbsp;  SEARCH BY APPOINTMENT SHEET
      </p>

          <FaChevronDown  onClick={toggleFormVisibility}/>
    </div>
    </div>
    {isFormVisible && (
      <div class="">
        <div

          name="dname"
          id="dname1"
          class="form-horizontal"

        >

          <div className="control-group-div">

            <div class="control-group">
              <label class="control-label">SELECT DOP DATE</label>
              <div class="controls">
              <DatePicker
                    selected={datadop}
                    onChange={(date) => handleChangeReport(date, 'datadop')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('datadop')}
                  />
              </div>
            </div>


            <div class="form-actions-control">
              <button
                type="submit"
                name="ShowInfoBtn"
                class="btn btn-success"
                id="show_btn"
                onClick={()=>setdopdatequery(datadop.toISOString().split('T')[0])}
              >
                Show Report
              </button>

            </div>
            <br />
            <br />


          </div>

        </div>
      </div>
      )}



<div className=" container mb-3">
<div className="row bg-body-tertiary mt-4">
<div className="dashservice">
<p className="name-text"> <IoMdArrowRoundBack  onClick={handleback} /> &nbsp;&nbsp;&nbsp;APPOINTMENT SHEET</p>
<FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
<div>
  {showtable && (
    <div>
<div className="d-flex justify-content-between mt-2">
  <select
    value={itemsPerPage}
    onChange={(e) => {
      setItemsPerPage(Number(e.target.value));
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 25, 50, 75, 100].map((size) => (
      <option key={size} value={size}>
        Show {size}
      </option>
    ))}
  </select>
  <input
    type="text"
    value={globalFilter || ""}
    onChange={e => setGlobalFilter(e.target.value)}
    placeholder="Search..."
  />
</div>
<Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render("Header")}
            <span>
              {column.isSorted
                ? column.isSortedDesc
                  ? " 🔽"
                  : " 🔼"
                : ""}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {loading ? (
      <tr>
     <td colSpan={17} className="text-center">
     <Spinner animation="border" />
   </td>
      </tr>
    ) : report.length === 0 ? (
      <tr>
        <td className="fs-6" colSpan={17}>No Data Available</td>
      </tr>
    ) : (
      page.map(row => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            ))}
          </tr>
        );
      })
    )}
  </tbody>
</Table>
<div className="d-flex justify-content-between mb-3">
<span>
Showing {startIndex}-{endIndex} of {totalEntries} entries
{remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
  <div className="pagination">
    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      Previous
    </button>
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
    </span>
    <button onClick={() => nextPage()} disabled={!canNextPage}>
      Next
    </button>
  </div>
</div>
</div>
)}
</div>
    </div>
</div></>):null}
    {show && <Updateappointment  appointmentid={masterid}/>}
  </div>)}</div>
  );
}