import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import Hexapi from "../../usefulcomps/HexAPI/Hexapi";

export const fetchdata=createAsyncThunk("fetchdata",async()=>{
    const obj={
        query:`[dbo].[spasoftware_web_proc_logged_in_user_details]@user_id='{0}'`,
        queryArr:[`${localStorage.getItem('userid')}`]
      }
      const getdata = await Hexapi(obj)
      console.log(getdata,"jhgfghju")
      return getdata;
})

const userdata=createSlice({
    name:"userdataslice",
    initialState:{
        isLoading:false,
        data:null,
        isError:false
    },
  extraReducers:(builder)=>{
builder.addCase(fetchdata.pending,(state,action)=>{
    state.isLoading=true
})
builder.addCase(fetchdata.fulfilled,(state,action)=>{
    state.isLoading=false;
    state.data=action.payload
})
builder.addCase(fetchdata.rejected,(state,action)=>{
    console.log(action.payload,"error")
    state.isError=true
})
  }
})

export default userdata.reducer