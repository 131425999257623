import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import Hexapi from '../usefulcomps/HexAPI/Hexapi'
import {IoMdArrowRoundBack} from 'react-icons/io';
import { lineSpinner } from 'ldrs';
lineSpinner.register();

const Walkingrouponsale = ({branchname,custdatedata,setback}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    console.log(branchname,custdatedata,"===========")
    const[data,setdata]=useState([])
    const[loading,setloading]=useState(false)

    const handlequerydata=async()=>{
const obj={
    query:`[dbo].[spa_software_web_proc_walkin_groupon_sale]@datesearch='{0}',@branchname='{1}'`,
    queryArr:[custdatedata,branchname]
}
const getdata=await Hexapi(obj)
console.log(getdata)
setdata(getdata.
    walkincardsaledata
    )
    }
    useEffect(()=>{
    handlequerydata()
    },[branchname,custdatedata])
    const handleback = () => {
        setloading(true);
        setTimeout(() => {
          setback();
          setloading(false);
        }, 100);
      }
  return (
    <div>
  { loading ? <div className="text-center mt-5"><l-line-spinner
     size="40"
     stroke="3"
     speed="1"
     color="black"
     className="relative"

   ></l-line-spinner></div> : (  <div className=' container'>
        <div className='dashservice'>
            <p className='name-text'><IoMdArrowRoundBack  onClick={handleback} /> &nbsp;&nbsp;&nbsp;Walking Groupon Sale</p>
            <FaChevronDown/>
        </div>
        <div className='row'>
        <Table responsive bordered>
            <thead>
                <tr>
                <th>Sr No</th>
                                    <th class="hidden-phone">Walking Member Id</th>
                                    <th class="hidden-phone">Customer Name</th>
                                    <th class="hidden-phone">Contact No</th>
                                    <th class="hidden-phone">Paid Amount</th>
                                    <th class="hidden-phone">Service Hour</th>
                                    <th class="hidden-phone">Manager Name</th>
                                    </tr>
            </thead>
            <tbody>

                   {data && data.map((ele,ind)=>{
                    return(
                        <tr>
                   <td>{ind+1}</td>
                    <td>{ele.member_id}</td>
                    <td>{ele.cust_name}</td>
                    <td>{ele.cust_contact}</td>
                    <td>{ele.cust_paid_amount}</td>
                    <td>{ele.cust_total_service}</td>
                    <td>{ele.create_by}</td>

                    </tr>
                    )
                   })}


            </tbody>
        </Table>
        </div>
    </div>)}
  </div>
  )
}

export default Walkingrouponsale