import React, { useEffect, useState} from 'react';
import { Alert, Table, Toast } from 'react-bootstrap';
import { FaChevronDown, FaTag } from 'react-icons/fa';
import { IoIosArrowDown, IoMdArrowRoundBack } from 'react-icons/io';
import './membershipview.css';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { formatDate } from '../customfunction/dateformatmonth';
import { Aesencryption } from '../usefulcomps/Aes/Aes';
import { default as Select } from 'react-select';
import DatePicker from "react-datepicker";
import { lineSpinner } from 'ldrs';
import { decryptData, encryptData } from '../cryptoutils/cryptoutils';
import { Link, useNavigate, useParams} from 'react-router-dom';
lineSpinner.register();

const Viewmembershipserviceuser = () => {
const{userid,memberid}=useParams()
console.log(userid,memberid,"memberid")
const userId=decryptData(userid)
const memberId=decryptData(memberid)
// console.log(userId,memberId,"------------")

const navigate=useNavigate()
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const[loading,setloading]=useState(false)
  const [isFormVisible, setisvisible] = useState(true);
  const [servicelist, setservicelist] = useState([]);
  const [therapistname, settherapistname] = useState([]);
  const [Ashow, setAShow] = useState(true);
  const [servicehourdata, setservicehour] = useState([]);
  const [customersignature, setcustomersignature] = useState({});
  const selector = useSelector(state => state);
  const branchnamesession = selector.details.data && selector.details.data[""][0].spa_branch_name;
  const managername = selector.details.data && selector.details.data[""][0].manager_name;
  const[datacustomer,setcustometdata]=useState(null)
  const[disable,setdisable]=useState(false)
  const[show,setshow]=useState(true)
  const date = new Date();
  const year = date. getFullYear();
  const branchTable = selector.details.data && selector.details.data[""][0].branchtable;
  const month = String(date. getMonth() + 1). padStart(2, '0');
  const day = String(date. getDate()). padStart(2 ,'0');
  const formattedDate = `${year}-${month}-${day}`;

  const [data, setdata] = useState({
    therapistnamee: "",
    treatmenthr: "",
    servicedate: "",
    timein: "",
    timeout: "",
    roomno: "",
    therapy_start_time:"",
    therapy_end_time:""
  });

  const handleTherapistChange = (selectedOption) => {
    setdata({ ...data, therapistnamee: selectedOption ? selectedOption.value : '' });
  };
  const handletreatmentChange = (selectedOption) => {
    setdata({ ...data, treatmenthr: selectedOption ? selectedOption.value : '' });
  };

  const therapistOptions = therapistname.map(therapist => ({
    value: therapist.therapist_name,
    label: therapist.therapist_name
  }));

  therapistOptions.push({
    value:managername,
    label:managername
  })

  const treactmentoption=servicehourdata.map(hr=>({
    value:hr.servicehour,
    label:hr.service_hour
  }))

 const updatequery=async()=>{
 const obj={
  query:` [dbo].[spasoftware_web_proc_update_service]@memberid='{0}'`,
  queryArr:[`${memberid}`]
 }
 const getchange=await Hexapi(obj)
 getservicelist()
 window.scrollTo(0, 0);
 toast.success(`Customer signature updated Customer Id : -  ${getchange.customerid[0].customerid}`)
 console.log(getchange,"--------------")
 }
  const getservicelist = async () => {
    if(memberId !==null && userId !==null){
      const obj = {
        query: `[dbo].[spasoftware_web_proc_servicelistdata_getdata]@memberid='{0}',@serviceid='{1}'`,
        queryArr: [`${memberId}`,`${userId}`]
      };
      const getdata = await Hexapi(obj);
      console.log(getdata,"getdata")
      setservicelist(getdata.servicelistgetdata || []);
      settherapistname(getdata.therapist_name || []);
      setservicehour(getdata.servicehour || []);
      setcustomersignature((getdata && getdata.customer_signature && getdata.customer_signature[0] && getdata.customer_signature[0].customer_signature) || "");
      setcustometdata((getdata && getdata[""] && getdata[""][0]) || "")
    }
  };

  useEffect(() => {
    getservicelist();
  }, [userId,memberId]);


  function convertTimeToAMPMFormat(timeString) {
    let [hours, minutes] = timeString.split(':').map(Number);
    let period = 'AM';

    if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
            hours -= 12;
        }
    }

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    return formattedTime;
  }



  const handleservicelistsubmit = async (e) => {
    setdisable(true)
    e.preventDefault();

    const timeZone = 'Asia/Kolkata';
function formatTime(date) {
    return date.toISOString().substr(11, 8);
}


const currentDate = new Date().toLocaleString('en-US', { timeZone });


const therapyStartTime = new Date(currentDate);
console.log(therapyStartTime,"tagehguh")


console.log('Therapy Start Time:', formatTime(therapyStartTime));
const treatmentHour = data.treatmenthr


const currentTime = new Date(currentDate);
const endTime = new Date(currentTime.getTime() + treatmentHour * 60 * 60 * 1000)

const timedin = convertTimeToAMPMFormat(data.timein);
const timedout=convertTimeToAMPMFormat(data.timeout);

const emptyFields = [];

if (!userId) emptyFields.push("User ID");
if (!managername) emptyFields.push("Manager Name");
if (!data.therapistnamee) emptyFields.push("Therapist Name");
if (!data.treatmenthr) emptyFields.push("Treatment Hours");
if (!data.roomno) emptyFields.push("Room No");
if (!data.servicedate) emptyFields.push("Service Date");
if (!timedin) emptyFields.push("Time In");
if (!timedout) emptyFields.push("Time Out");
if (!therapyStartTime) emptyFields.push("Therapist Start Time");
if (!endTime) emptyFields.push("Therapy End Time");


if (emptyFields.length > 0) {
  Swal.fire("Warning", `Please fill in the following fields: ${emptyFields.join(", ")}`, "warning");
  setdisable(false)
  return; 
}
    const obj = {
      query: `[dbo].[spasoftware_web_proc_new_service_userdetails_insert]@userid='{0}',@manager_name='{1}',@therapistname='{2}',@treatmenthr='{3}',@roomno='{4}',@servicedate='{5}',@timein='{6}',@timeout='{7}',@therapystart='{8}',@therapyend='{9}'`,
      queryArr: [`${userId}`, `${managername}`, `${data.therapistnamee}`, `${data.treatmenthr}`, `${data.roomno}`, `${data.servicedate}`, `${timedin}`, `${timedout}`,`${formatTime(therapyStartTime)}`,`${formatTime(endTime)}`]
    };

    const getpost = await Hexapi(obj);
    console.log(getpost, "service create");
    if(getpost){
      try {
        Swal.fire({
          text: `${getpost[""][0]['msg']}`,
          icon: 'ok',
          customClass: {
              popup: 'my-custom-swal',
              title: 'my-custom-swal-title',
              content: 'my-custom-swal-content',
              confirmButton: 'my-custom-swal-confirm',
              cancelButton: 'my-custom-swal-cancel'
          },
      })
      
      getservicelist()
      setdata((prev)=>({
        ...prev,
        therapistnamee:"",
        treatmenthr: "",
        servicedate: "",
        timein: "",
        timeout: "",
        roomno: "",
        therapy_start_time:"",
        therapy_end_time:""
      }))

      setdisable(false)
      } catch (error) {
        Swal.fire({
          text:`${getpost[""][0]['msg']}`,

          icon: 'ok',
          customClass: {
              popup: 'my-custom-swal',
              title: 'my-custom-swal-title',
              cancelButton: 'my-custom-swal-cancel'
          },
      });
      }
    }

  }

  const handleclear=()=>{
    setdata({
      therapistnamee:"",
      treatmenthr: "",
      servicedate: "",
      timein: "",
      timeout: "",
      roomno: "",
      therapy_start_time:"",
      therapy_end_time:""
    })
  }

const handleservicelist = (e) => {
  const {name, value} = e.target;
  setdata({...data, [name]: value});

};

const handleChangeReport = (date, name) => {
  if(date){
    setdata({ ...data, [name]: date.toISOString().split('T')[0] });
  }else{
    setdata({ ...data, [name]: "" });
  }
};
const setCurrentDateIfEmpty = (name) => {
  if (!data[name]) {
    setdata({ ...data, [name]: new Date().toISOString().split('T')[0] });
  }
};
  return (

    <div>
   <div className='container'>
    {memberId !==null  && Ashow ?
        <Alert className='alert-success' onClose={() => setAShow(false)} dismissible>
          <strong>Success!</strong> Your Id : {memberId !==null ? memberId : null}
        </Alert> : null
      }
   <div className='row bg-body-tertiary'>

         <div className='dashservice'>
           <p className='name-text'>Service list : <span className='text-danger'>Name -
            {datacustomer !== null ? datacustomer.cust_name : null} || Customer-No - {datacustomer !==null ? datacustomer.cust_contact : null}
            </span></p>
          <span>  <span>{datacustomer!==null && datacustomer.membership_dop !==null && datacustomer.membership_dop!=='' ?
<span>Membership DOP Date - {datacustomer.membership_dop}</span>||<span > Membership Expire Date - {datacustomer.membership_expire}</span>
          : null}
          </span><FaChevronDown  onClick={()=>{setshow(!show)}}/>
          </span>
         </div>
        {show &&

        <div className='px-2'><Table className="mt-3 mb-3 bor-dis" responsive striped>
           <thead>
             <tr>
               <th> <FaTag/> Member Id</th>
               <th> <FaTag/> Time In</th>
               <th> <FaTag/> Time Out</th>
               <th> <FaTag/> Therapist Name</th>
               <th> <FaTag/> Treatment Hour</th>
               <th> <FaTag/> Room No</th>
               <th> <FaTag/> Create By</th>
               <th> <FaTag/> Service Date</th>
             </tr>
           </thead>
           <tbody>
             {servicelist.length ? servicelist.map((ele, index) => (
               <tr key={index} className='text-center'>
                 <td>{ele.service_status === 1 ? <span className='active'></span> : <span className='inactive'></span>}&nbsp;&nbsp; {ele.member_id}</td>
                 <td>{ele.time_in}</td>
                 <td>{ele.time_out}</td>
                 <td>{ele.therapist_name}</td>
                 <td>{ele.treatment_hr} hour</td>
                 <td>{ele.room_no}</td>
                 <td>{ele.create_by}</td>
                 <td>{formatDate((ele.service_date.date).split(' ')[0])} &nbsp;&nbsp;&nbsp;&nbsp; {ele.service_status === 1 ? <span className='btactive'>Active</span> : <span className='btactive-1'>Inactive</span>}</td>
               </tr>
             )) : <tr><td className=' text-center fs-6' colSpan={17}>No Data Available </td></tr>}
           </tbody>
         </Table>

         <div className=' d-flex justify-content-end mb-3'>

           <p>{ formattedDate === (datacustomer !==null ? datacustomer.cust_dop: null) ?
            <>
            <Link className='btn-click-2'   target="_blank" rel="noopener noreferrer" to={`/invoice/${encodeURIComponent(encryptData(branchTable))}/${encodeURIComponent(encryptData(userId))}`} >View Invoice</Link>
            </>
              : null}</p> 
         </div>
         </div>
         }
   </div>

   <div className='row  mt-4'>
   {((datacustomer !==null && datacustomer.cust_paid_amount) >= 10000 || branchnamesession === (datacustomer !=null ? datacustomer.branch_name : null)) ?

<div>
<div className='dashservice'>
 <div>
   <span className="name-text">SERVICE DETAILS</span>&nbsp;&nbsp;&nbsp;&nbsp;
   <span className=' text-danger name-text'>
     {customersignature > 1
       ? <span className=' fs-6'>Customer Form Signature Status : Pending - <span className=" member" onClick={updatequery}> update</span></span>
       : (customersignature === 0 && customersignature !== 1
         ? <span className=' fs-6'>Customer Form Signature Status : Pending -<span className=" member" onClick={updatequery}> update</span></span>
         :<span className=' fs-6'>Customer Form Signature Status : - <span className=" text-danger"> complete</span></span>)}
   </span>
 </div>
 <div className="">
     <FaChevronDown  onClick={() => { setisvisible(!isFormVisible) }}/>
 </div>
</div>
{isFormVisible && (
 <form className="form-horizontal mb-4" id="ename" >
   <div className="control-group">
     <label className="control-label">Therapist Name</label>
     <div className="controls">
     <Select
  className="span6"
  id="therapist_weekly_off"
  name="therapistnamee"
  options={therapistOptions}
  value={therapistOptions.find(option => option.value === data.therapistnamee) || null} 
  onChange={handleTherapistChange}
  classNamePrefix="react-select"
  placeholder="Select Therapist Name"
/>

     </div>
   </div>
   <div className="control-group">
     <label className="control-label">Treatment Hour</label>
     <div className="controls">
     <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="treatmenthr"
                      options={treactmentoption}
                      value={treactmentoption.find(option => option.value == data.treatmenthr ) || null}
                      onChange={handletreatmentChange}
                      classNamePrefix="react-select"
                      placeholder="Select Treatment hour"
                    />
     </div>
   </div>
   <div className="control-group">
     <label className="control-label">Room Number</label>
     <div className="therapist_controls">
       <input
         type="text"
         className="span6 popovers"
         name="roomno"
         id="therapist_name"
         required
         value={data.roomno}
         onChange={handleservicelist}
       />
       <br />
     </div>
   </div>
   <div className="control-group">
     <label className="control-label">Service Date</label>
     <div className="controls">
     <DatePicker
                    selected={data.servicedate}
                    onChange={(date) => handleChangeReport(date, 'servicedate')}
                    className="span6 popovers"
                   dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('servicedate')}
                  />
     </div>
   </div>
   <div className="control-group">
     <label className="control-label">Time In</label>
     <div className="controls">
       <input
         className="span6"
         size="16"
         type="time"
         name="timein"
         required
         onChange={handleservicelist}
         value={data.timein}
       />
       <span className="add-on">
         <i className="icon-calendar"></i>
       </span>
     </div>
   </div>
   <div className="control-group">
     <label className="control-label">Time Out</label>
     <div className="controls">
       <input
         type="time"
         className="span6 popovers"
         name="timeout"
         id="biometic_id"
         required
         value={data.timeout}
         onChange={handleservicelist}
       />
     </div>
   </div>
   <div className="form-actions">
     <button
       type="submit"
       id="create-btn"
       className="btn btn-success"
       onClick={handleservicelistsubmit}
       disabled={disable}
     >
       {disable ? "Submitting" : "Create"}
     </button>
     <button type="reset" className="btn-cancel btn" onClick={handleclear}>
       Cancel
     </button>
   </div>
 </form>
)}
</div>
:
<h4 className='text-black-50 mt-5'>BUY MEMBERSHIP FROM ---<span className='text-danger'>{branchnamesession} </span><span className='text-danger'>NOT VALID IN THIS BRANCH</span> </h4>}
   </div>
 </div>
   </div>
  );
}
export default Viewmembershipserviceuser;
