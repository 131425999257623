import React, { useState } from 'react'
import Spasoft from '../images/logo.png'
import { GiHamburgerMenu } from "react-icons/gi";
import { MdAccountCircle } from "react-icons/md";
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.css'
import Hamburger from './Hamburger';

const Secondnavbar = () => {
    const[show,setshow]=useState(false)
    const handleshow=()=>{
        setshow(true)
    }
    const handleoff=()=>{
        setshow(false)
    }
  return (
    <div className="container-fluid nav-top">
    <div className="row">
        <div className="col-12">
           <div className='dis-play'>
            <div className='d-flex'>
            <div>
                    <GiHamburgerMenu onClick={handleshow} className='fs-2'/>
                    <Hamburger show={show} handleoff={handleoff}/>
                    </div>
           <div>
            <img src={Spasoft} alt="" />
            </div>
                </div>

            <div className='sec-dis'>
              <div className='dis-play'>
               <div className='dis-icon'>
               <p><MdAccountCircle className='icon-size-2'/></p>
               <NavDropdown title="Admin" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">My Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
               logout
              </NavDropdown.Item>
            </NavDropdown>
               </div>
                </div>
    </div>
    </div>
    </div>
    </div>
  </div>
  )
}

export default Secondnavbar