import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import CustomSidebar from './CustomSidebar'
import Highlight from '../components/Highlight'
import Breadcrum from './Breadcrum'


const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  return (
    <div className='main-home'>
     <div><CustomSidebar/></div>
     <div className='background-img p-4 w-100 '>
          <Highlight/>
          <Breadcrum/>
          <Outlet/>
        </div>

    </div>
  )
}

export default Home