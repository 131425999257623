import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const Mobileverification = () => {
  const selector = useSelector(state => state);
  const [contact_no, setContactNo] = useState("");
  const [data, setData] = useState({
    numberdata: "",
    verificationcode: ""
  });

  const handleclear=()=>{
    setData({
      numberdata: "",
      verificationcode: ""
    })
  }
  const [isFormVisible, setFormVisible] = useState(true);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const generateOTP = async () => {
    if (!/^\d{10}$/.test(contact_no)) {
      toast.error("Please enter a valid 10-digit contact number.");
      return;
    }

    const otpLength = 6;
    const digits = '0123456789';
    let otp = '';
    for (let i = 0; i < otpLength; i++) {
      otp += digits[Math.floor(Math.random() * 10)];
    }

    console.log(otp, "------------");

    if (selector && selector.details.data) {
      const spaname = selector.details.data[""][0].spa_name;
      const spabranchname = selector.details.data[""][0].spa_branch_name;
      const managername = selector.details.data[""][0].create_by;

      const obj = {
        query: `[dbo].[spa_software_web_proc_mobile_verification_sms]@contactno='{0}',@otp='{1}',@managername='{2}',@spaname='{3}',@spabranchname='{4}'`,
        queryArr: [`${contact_no}`, `${otp}`, `${managername}`, `${spaname}`, `${spabranchname}`]
      };

      try {
        const postData = await Hexapi(obj);
        console.log(postData, "otpdata");
        toast.success(postData[""][0].msg);
      } catch (error) {
        console.error('Error sending SMS:', error);
        toast.error("Failed to send SMS");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleVerification = async () => {

    if (!/^\d{10}$/.test(data.numberdata)) {
      toast.error("Please enter a valid 10-digit contact number.");
      return;
    }

    const obj = {
      query: `[dbo].[spa_software_web_proc_mobile_verification_userVerification]@contactno='{0}',@verification_code='{1}'`,
      queryArr: [`${data.numberdata}`, `${data.verificationcode}`]
    };

    try {
      const verification = await Hexapi(obj)
console.log(verification)
      if(verification[""][0] && verification[""][0].msg ){
        toast.success(verification[""][0].msg);
      }else{
        toast.error(verification[""][0].errmsg);
      }

    } catch (error) {
      console.error('Error verifying user:', error);
      toast.error("User verification failed");
    }
  };

  return (
    <>
      <div className="quick_search_page">
        <div className="widget-title">
          <h4>
            <i className="icon-reorder"></i> USER SMS CONFIRMATION
          </h4>
              <IoIosArrowDown onClick={toggleFormVisibility} style={{marginRight:"5px",marginTop:"5px"}}/>
        </div>
      </div>

      {isFormVisible && (
        <div className="widget-body form">
          <div name="dname" id="dname1" className="form-horizontal">
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Enter Contact Number</label>
                <div className="controls">
                  <input
                    type="tel"
                    className="span6 popovers"
                    name="contact_no"
                    id="enter_details"
                    value={contact_no}
                    onChange={(e) => setContactNo(e.target.value)}
                    pattern="[0-9]{10}"
                    required
                  />
                </div>
              </div>

              <div className="form-actions-control">
                <button
                  type="button"
                  name="ShowInfoBtn"
                  className="btn btn-success"
                  id="show_btn"
                  onClick={generateOTP}
                >
                  Send SMS
                </button>
              </div>

              <div className="control-group">
                <label className="control-label">Enter Contact Number</label>
                <div className="controls">
                  <input
                    type="tel"
                    className="span6 popovers"
                    name="numberdata"
                    id="enter_details"
                    value={data.numberdata}
                    onChange={handleChange}
                    pattern="[0-9]{10}"
                    required
                  />
                </div>
              </div>

              <div className="control-group">
                <label className="control-label">Enter User Verification Code</label>
                <div className="controls">
                  <input
                    type="tel"
                    className="span6 popovers"
                    name="verificationcode"
                    value={data.verificationcode}
                    onChange={handleChange}
                    id="enter_details"
                    pattern="[0-9]{6}"
                    required
                  />
                </div>
              </div>

              <div className="form-actions-control">
                <button
                  type="button"
                  name="ShowInfoBtn"
                  className="btn btn-success"
                  id="show_btn"
                  onClick={handleVerification}
                >
                  User Authentication
                </button>
                <button type="reset" className="btn-cancel btn" onClick={handleclear}>
                  Cancel
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Mobileverification;
