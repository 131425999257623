import { configureStore } from "@reduxjs/toolkit";
import userdata from './slice/userdataslice'
import authReducer from './slice/logout'


export const store=configureStore({
    reducer:{
details:userdata,
auth: authReducer
    }
})