import React, { useEffect, useState } from 'react';
import './QuickSearch.css';
import { FaChevronDown } from 'react-icons/fa';
import { Table } from 'react-bootstrap';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { lineSpinner } from 'ldrs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

lineSpinner.register();

function Totalsale() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    startdate: null,
    enddate: null,
  });
  const [report, setReport] = useState(null);
  const [showTable, setShowTable] = useState(true);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const convertDateFormat = dateStr => {
    const [year, month, day] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleReport = async e => {
    e.preventDefault();
  if (!data.startdate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the start date.",
      });
    
    } else if (!data.enddate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the end date.",
      });
    }else{
      setLoading(true);

      const obj = {
        query: `[dbo].[spasofware_web_proc_report_total_sale_report]@startdate='{0}',@enddate='{1}'`,
        queryArr: [convertDateFormat(data.startdate), convertDateFormat(data.enddate)],
      };
  
      const getdata = await Hexapi(obj);
      console.log(getdata,"------------dfghjuguj")
      setReport(getdata || {});
      setLoading(false);
    }
    
  };

  const handleChangeReport = (date, name) => {
    if (date) {
      setData({ ...data, [name]: date.toISOString().split('T')[0] });
    } else {
      setData({ ...data, [name]: '' });
    }
  };

  const setCurrentDateIfEmpty = name => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date().toISOString().split('T')[0] });
    }
  };

  const exportToExcel = () => {
    if (!report) {
      alert('No valid data available to export.');
      return;
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const formattedData = [ {  'Date Range': data.startdate && data.enddate ? `${data.startdate} to ${data.enddate}` : '',
      'Records': 'Walking Cash Sale',
      'Total Sale': report.walking_cash_sale[0].walkingcashsale || 0}
      ,{
        'Records': 'Walking Card Sale',
        'Total Sale': report.walking_card_sale[0].walkingcardsale || 0
      },
      {
        'Records': 'Walking Paytm Sale',
        'Total Sale': report.walking_paytm_sale[0].walkingpaytmsale || 0
      },
      {
        'Records': 'Walking Groupon Sale',
        'Total Sale': report.walking_groupon_sale[0].walkinggrouponsale || 0
      },
      {
        'Records': 'Membership Cash Sale',
        'Total Sale': report.membership_cash_sale[0].membershipcashsale || 0
      },
      {
        'Records': 'Membership Card Sale',
        'Total Sale': report.membership_card_sale[0].membershipcardsale || 0
      },
      {
        'Records': 'Membership Paytm Sale',
        'Total Sale': report.membership_paytm_sale[0].membershipaytmsale|| 0
      },
      {
        'Records': 'Tip 100% Cash Sale',
        'Total Sale': report.totaltipcash[0].totaltipamountcash|| 0
      },
      {
        'Records': 'Tip 100% Card Sale',
        'Total Sale': report.total_tip_amountcard[0].total_tipamountcard|| 0
      },
      {
        'Records': 'Tip 100% Paytm Sale',
        'Total Sale': report.totaltip_paytm[0].totaltip_paytm|| 0
      },
      {
        'Records': 'Paid Pending Cash',
        'Total Sale':report.pending_amount_cashpaid[0].pendingamountcash|| 0
      },
      {
        'Records': 'Paid Pending Card',
        'Total Sale': report.pending_amount_card[0].pending_amount_card|| 0
      },
      {
        'Records': 'Paid Pending Paytm',
        'Total Sale': report.pending_amount_paytm[0].pending_amount_paytm|| 0
      },
      {
        'Records': 'Total Cash In-Hand',
        'Total Sale': report.total_cash_sale[0].total_cash_sale|| 0
      },
      {
        'Records': 'Total Cash In-Bank',
        'Total Sale':report.total_bank_record[0].total_bank_record|| 0
      },
      {
        'Records': 'Total Sale',
        'Total Sale': report.net_sale[0].net_sale|| 0
      },

    ];

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { 'Total Sale Data': ws }, SheetNames: ['Total Sale Data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Total_sale_Report' + fileExtension;
    saveAs(dataBlob, fileName);
  };


  return (
    <>
      <div className="quick_search_page">
        <div className="widget-title">
          <h4>
            <i className="icon-reorder"></i> Search Report
          </h4>

              <FaChevronDown  onClick={toggleFormVisibility} style={{marginRight:"5px",marginTop:"5px"}}/>

        </div>
      </div>
      {isFormVisible && (
        <div className="widget-body form">
          <form name="dname" id="dname1" className="form-horizontal" onSubmit={handleReport}>
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Select Start Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.startdate ? new Date(data.startdate) : null}
                    onChange={date => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select End Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.enddate ? new Date(data.enddate) : null}
                    onChange={date => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div className="form-actions-control">
                <button type="submit" name="ShowInfoBtn" className="btn btn-success" id="show_btn">
                  Show Report
                </button>
              </div>
              <br />
              <br />
            </div>
          </form>
        </div>
      )}

      <div className="container mb-4">
        <div className="row bg-body-tertiary mt-4">
          <div className="dashservice">
            <p className="name-text">
              <span className="text-danger">SALE REPORT</span>
            </p>
            <FaChevronDown onClick={() => setShowTable(!showTable)} />
          </div>
          <div>
            {showTable && (
              <Table className="mt-3 mb-3 bor-dis" responsive bordered id="totalsale">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Records</th>
                    <th>Total Sale</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={3} className="text-center">
                        <l-line-spinner size="40" stroke="3" speed="1" color="black" className="relative"></l-line-spinner>
                      </td>
                    </tr>
                  ) : (
                    <tr>


                       {  report ?   <td style={{ color: '#CC0000' }} className="align-middle">
                            {data.startdate && (
                              <p style={{marginLeft:"50px",fontSize:"14px"}}>
                                {data.startdate}
                                <br />
                                <br />
                                <p > to </p>
                                <br />
                                {data.enddate}
                              </p>
                            )}
                          </td> : <td></td>}


                          <td>
                            Walking Cash Sale<br />
                            Walking Card Sale<br />
                            Walking Paytm Sale<br />
                            Walking Groupon Sale<br />
                            Membership Cash Sale<br />
                            Membership Card Sale<br />
                            Membership Paytm Sale<br />
                            Tip 100% Cash Sale<br />
                            Tip 100% Card Sale<br />
                            Tip 100% Paytm Sale<br />
                            Paid Pending Cash<br />
                            Paid Pending Card<br />
                            Paid Pending Paytm<br />
                            <b style={{ color: '#CC0000' }}>Total Cash In-Hand</b><br />
                            <b style={{ color: '#0000FF' }}>Total Cash In-Bank</b><br />
                            <b style={{ color: '#006600' }}>Total Sale</b><br />
                          </td>
{ report !== null?
                          <td>
                            {report?.walking_cash_sale[0]?.walkingcashsale}<br />
                            {report?.walking_card_sale[0]?.walkingcardsale}<br />
                            {report?.walking_paytm_sale[0]?.walkingpaytmsale}<br />
                            {report?.walking_groupon_sale[0]?.walkinggrouponsale}<br />
                            {report?.membership_cash_sale[0]?.membershipcashsale}<br />
                            {report?.membership_card_sale[0]?.membershipcardsale}<br />
                            {report?.membership_paytm_sale[0]?.membershipaytmsale}<br />
                            {report?.totaltipcash[0]?.totaltipamountcash}<br />
                            {report?.total_tip_amountcard[0]?.total_tipamountcard}<br />
                            {report?.totaltip_paytm[0]?.totaltip_paytm}<br />
                            {report?.pending_amount_cashpaid[0]?.pendingamountcash}<br />
                            {report?.pending_amount_card[0]?.pending_amount_card}<br />
                            {report?.pending_amount_paytm[0]?.pending_amount_paytm}<br />
                            <b style={{ color: '#CC0000' }}>{report.total_cash_sale[0].total_cash_sale}</b><br />
                            <b style={{ color: '#0000FF' }}>{report.total_bank_record[0].total_bank_record}</b><br />
                            <b style={{ color: '#006600' }}>{report.net_sale[0].net_sale}</b><br />
                          </td> : <td></td>}


                    </tr>
                  )}
                </tbody>
                <div>
              Showing 1 - 1 of 1 entries

            </div>
                <button onClick={exportToExcel} className="btn-click-6 m-2">
          Generate Report
        </button>
              </Table>
            )}
          </div>

        </div>

      </div>
    </>
  );
}

export default Totalsale;
