import React, { useEffect, useState } from "react";
// import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import './service.css'
import { useSelector } from "react-redux";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import Swal from 'sweetalert2'
import { IoMdArrowRoundBack } from "react-icons/io";
import { default as Select } from 'react-select';
import PaidAdminUpdate from "./PaidAdminUpdate";
import { Aesdecryption, Aesencryption } from '../usefulcomps/Aes/Aes';
import { useParams } from "react-router-dom";
import { decryptData, encryptData } from "../cryptoutils/cryptoutils";

const Paidadmin = () => {
    const{userid,memberid}=useParams()
    const userId=decryptData(userid)
    const memberId=decryptData(memberid)
    const [show, setshow] = useState(true)
    const [showtable, setshowtable] = useState(true)
    const [userID, setuserid] = useState("");
    const [editpaid, seteditpaid] = useState(false)
    const [data, setdata] = useState({
        paymentmode: "",
        memberid: "",
        branchname: "",
        basicpendingamount: "",
        gsttaxamount: "",
        totalpendingamount: "",
        paidamount: "",
        amountinwords: "",
        basicamount: "",
        taxamount: "",
        balanceamount: ""
    })
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [payment, setpayment] = useState([])
    const [branchName, setbranchName] = useState([])
    const [pendingdata, setpendingdata] = useState([])
    const [pendingamount,setpendingamount]=useState("")
    const [loading, setloading] = useState(false)
    console.log(memberId, "------------paidmemberid")
    const selector = useSelector(state => state)
    const branchtable = selector && selector.details && selector.details.data && selector.details.data[""][0].branchtable;
    const manager_name = selector && selector.details && selector.details.data &&  selector.details.data[""][0].manager_name
    const gstamount = Math.ceil(pendingdata * 18 / 118)
    console.log(gstamount, "gstamount")
    const basictaxamount = pendingdata - gstamount
    console.log(basictaxamount, "basictaxamount")


    const getpaymentandtabledata = async () => {
        const obj = {
            query: `[dbo].[spasoftware_web_proc_pendingpayment_account_paymentmode_v2]@memberid='{0}',@userid='{1}'`,
            queryArr: [`${memberId}`,`${userId}`]
        }
        const getdata = await Hexapi(obj)
        console.log(getdata, "paymentmode")
        setpayment(getdata.paymentmode || [])
        setbranchName(getdata.branchname || [])
        setpendingdata(getdata.paymentdetails || [])
        setpendingamount(getdata && getdata.cust_bal_amount[0] && getdata.cust_bal_amount[0]?.cust_bal_amount || 0)
    }

    useEffect(() => {
        getpaymentandtabledata();
    }, [])

    function convertNumberToWords(amount) {
        if (isNaN(amount) || amount === 0) {
            return '';
        }
        let words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        let atemp = amount.split(".");
        let number = atemp[0].split(",").join("");
        let n_length = number.length;
        let words_string = "";
        if (n_length <= 9) {
            let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            let received_n_array = new Array();
            for (let i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (let i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value = "";
            for (let i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string;
    }

    function formatDate(dateString) {
        let date = new Date(dateString);

        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let day = date.getDate();
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();

        // Return the formatted date string
        return `${day}` - `${month}` - `${year}`;
    }

    const handlechangepaidamount = (e) => {
        const paiddata = e.target.value;
        const paidvalue = parseInt(paiddata);
        const wordsamount = convertNumberToWords(paidvalue);
        const tax_amount = paiddata * 18 / 100
        const basic_amount = paiddata - tax_amount

        const balanceamount = pendingdata - paiddata
        setdata((prev) => ({
            ...prev,
            paidamount: paiddata,
            taxamount: Math.round(tax_amount),
            basicamount: Math.round(basic_amount),
            balanceamount: Math.round(balanceamount),
            amountinwords: wordsamount
        }));
        if (isNaN(paidvalue)) {
            setdata((prev) => ({
                ...prev,
                paidAmount: '',
                taxAmount: '',
                basicAmount: '',
                balanceAmount: '',
                amountInWords: ''
            }));
            return;
        }
    }

    const handleChangePaymentMode = (e) => {
        console.log(e.target.value)
        setdata((prev) => ({
            ...prev,
            paymentmode: e.target.value
        }));
    };
    const handleChangeBranchName = (e) => {
        console.log(e.target.value)
        setdata((prev) => ({
            ...prev,
            branchname: e.target.value
        }));
    };
    const handlesubmit = async (e) => {
        e.preventDefault()
        const obj = {
            query: `[dbo].[spasoftware_web_proc_subAdmin_paid_tabledata]@memberid='{0}',@userId='{1}',@paid_amount='{2}',@branch_name='{3}',@payment_mode='{4}',@managerName='{5}'`,
            queryArr: [`${memberId}`,
            `${userId}`,
            `${data.paidamount}`,
            `${data.branchname}`,
            `${data.paymentmode}`,
            `${manager_name}`
            ]
        }
        const getpost = await Hexapi(obj);
        console.log(getpost, "pending amount");
        if (getpost) {
            try {
                Swal.fire({
                    text: `${getpost[""][0]['msg']}`,
                    icon: 'ok',
                    customClass: {
                        popup: 'my-custom-swal',
                        title: 'my-custom-swal-title',
                        content: 'my-custom-swal-content',
                        confirmButton: 'my-custom-swal-confirm',
                        cancelButton: 'my-custom-swal-cancel'
                    },
                });
                setdata({
                    paymentmode: "",
                    memberid: "",
                    basicpendingamount: "",
                    gsttaxamount: "",
                    totalpendingamount: "",
                    paidamount: "",
                    amountinwords: "",
                    basicamount: "",
                    taxamount: "",
                    balanceamount: ""
                })
                getpaymentandtabledata();
            } catch (error) {
                Swal.fire({
                    text: "error",
                    icon: 'ok',
                    customClass: {
                        popup: 'my-custom-swal',
                        title: 'my-custom-swal-title',
                        cancelButton: 'my-custom-swal-cancel'
                    },
                });
            }
        }
    }


    const handleback = () => {
    
    }
    const handlebackpaid = () => {
        setloading(true);
        setTimeout(() => {
            setloading(false);
            seteditpaid(false)
        }, 100)
    }
    const handleeditpaid = (userId) => {
        console.log(userId)
        setuserid(userId)
        seteditpaid(true)
    }

    const handlebranchChange = (selectedOption) => {
        setdata({ ...data, branchname: selectedOption ? selectedOption.value : '' });
    };
    const branchOptions = branchName.map(br => ({
        value: br.branch_name,
        label: br.branch_name
    }))

    const paymentOptions = payment.map(th => ({
        value: th.paymentmode,
        label: th.paymentmode
    }));
    const handlepaymentChange = (selectedOption) => {
        setdata({ ...data, paymentmode: selectedOption ? selectedOption.value : '' });
    };
    return (
        <div>
            {loading ? <div className="text-center mt-5"><l-line-spinner
                size="40"
                stroke="3"
                speed="1"
                color="black"
                className="relative"

            ></l-line-spinner></div> : (<>
                {!editpaid ? (<div className="container top-acc highlightBcg">
                    <div className="row ">
                        <div className="dashservice">
                            <p className="name-text">
                                <IoMdArrowRoundBack onClick={handleback} /> &nbsp; &nbsp; &nbsp; PENDING PAYMENT ACCOUNT
                            </p>
                            <div className="">
                                <FaChevronDown onClick={() => { setshow(!show) }} className="hideShowBtn" />
                            </div >
                        </div>
                    </div>

                    <div className="row bg-body-tertiary">
                        {show && (
                            <form onSubmit={handlesubmit} className="form-data">
                                <div className="controls" >
                                    <div className="control-group">
                                        <label className="control-label">
                                            Payment Mode
                                        </label>
                                        <div className="controls">
                                            <Select
                                                className="span6"
                                                id="therapist_weekly_off"
                                                name="therapistnamee"
                                                options={paymentOptions}
                                                value={paymentOptions.find(option => option.value === data.paymentmode)}
                                                onChange={handlepaymentChange}
                                                isClearable
                                                classNamePrefix="react-select"
                                                placeholder="Select Payment Mode"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="control-group">
                                        <label className="control-label">
                                            Branch Name
                                        </label>
                                        <div className="controls">
                                            <Select
                                                className="span6"
                                                id="therapist_weekly_off"
                                                name="branch"
                                                options={branchOptions}
                                                value={branchOptions.find(option => option.value === data.branchname)}
                                                onChange={handlebranchChange}
                                                isClearable
                                                classNamePrefix="react-select"
                                                placeholder="Select Branch Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="control-group">
                                        <label className="control-label">
                                            MemberId
                                        </label>
                                        <div className="controls">
                                            <input
                                                type="text"
                                                className="span7  popovers"
                                                name="memberid"
                                                value={memberId}
                                                id="customer_name"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="control-group d-flex flex-wrap">
                                        <label className="control-label">
                                            Pending Amount
                                        </label>
                                        <input type="number" id="basic_amount"
                                            className="span7  popovers"
                                            name="basic_amount"
                                            value={pendingamount}
                                            disabled />

                                        <p className=" mt-4">Not Understand Call us </p>
                                        
                                    </div>
                                    <div className="control-group d-flex flex-wrap">
                                        <label className="control-label">
                                            Paid Amount</label>
                                        <div className="controls ">
                                            <input
                                                type="number"
                                                name="tax_amount"
                                                id="tax_amount"
                                                className="span6  popovers"
                                                value={data.paidamount}
                                                onChange={handlechangepaidamount}
                                            />
                                        </div>
                                        <p className=" mt-4">Enter Same Amount Showing in Pending Amount :{pendingamount}</p>
                                    </div>

                                    <div className="createcancel_btn">
                                        <button
                                            type="submit"

                                            className="btn-data"
                                            onClick={handlesubmit}
                                        >
                                            Paid
                                        </button>
                                        <button type="reset" className="cancel-btn btn">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>

                    <div className="row bg-body-tertiary mt-4">
                        <div className="dashservice">
                            <p>Pending Amount Details</p>
                            <FaChevronDown onClick={() => { setshowtable(!showtable) }} />
                        </div>
                        <div>
                            {showtable && (<table className=" table table-striped  table-bordered table-advance table-hover table-responsive table-hover mt-3 mb-3 bor-dis">
                                <thead className="">
                                    <tr>
                                        <th>Member Id</th>
                                        <th>Branch Name</th>
                                        <th>Deposit Date</th>
                                        <th>Payment Type</th>
                                        <th>Total Amount</th>
                                        <th>Paid Amount</th>
                                        <th>Pending Amount</th>
                                        <th>Deposit Amount</th>
                                        <th>Manager Name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {pendingdata && pendingdata.map((ele) => {
                                        return (
                                            <tr>
                                                <td className="curpoint" onClick={() => {
                                                    handleeditpaid(ele.pending_amount_account_id)
                                                }}>{ele.status === 1 ? <span className='active'></span> : <span className='inactive'></span>}{ele.member_id}</td>
                                                <td>{ele.branch_name}</td>
                                                <td>{ele.currdate.date.split(" ")[0]}</td>
                                                <td>{ele.payment_mode}</td>
                                                <td>{ele.total_amount}</td>
                                                <td>{ele.paid_amount}</td>
                                                <td>{ele.bal_amount}</td>
                                                <td>{ele.deposit_amount}</td>
                                                <td>{ele.create_by}</td>
                                                <td>{ele.currdate.date.substring(0, 10) === ele.currdate.date ?
                                                (<>
                                                    <Link className='btn-click-2'   target="_blank" rel="noopener noreferrer" to={`/invoice/${encodeURIComponent(encryptData(branchtable))}/${encodeURIComponent(encryptData(ele.user_id))}`} >View Invoice</Link>
                                                    </>)
                                                : null}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>)}
                        </div>
                    </div>
                </div>) : null}
                {editpaid && <PaidAdminUpdate userid={userID} setbackpaid={handlebackpaid} editupdate={editpaid} handlequerydata={getpaymentandtabledata} memberId={memberId} pendingData={pendingdata} />}
            </>)}</div>
    );
};

export default Paidadmin;