import React, { useEffect, useState } from 'react'

import Table from 'react-bootstrap/Table';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { encryptData } from '../cryptoutils/cryptoutils';


const Membershipaccounttable = ({data,handleservice,handleRenewdata,handletipdata,handlepaiddata}) => {
  const[sho,setshow]=useState(true)
  const[loading,setloading]=useState(false)

   const handleshow=(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)=>{
      handleservice(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)
   }
   const handleRenew=(user_id,memberid,cust_name,cust_contact)=>{
    handleRenewdata(user_id,memberid,cust_name,cust_contact)
   }

   const handlepaid=(memberid,cust_bal_amount,user_id)=>{
   handlepaiddata(memberid,cust_bal_amount,user_id)
   }
   const handletip=(memberid,userid)=>{
 handletipdata(memberid,userid)
   }


function formatDate(dateString) {
  let date = new Date(dateString);
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();
  return `${day}-${month}-${year}`;
}


  return (
   <>

<div className=' container mt-3 mb-5'>

<div className='bg-body-tertiary '>
      <div className='dashservice'>
        <p className='name-text'>Membership Account</p>
        <p><FaChevronDown onClick={()=>{setshow(!sho)}}/></p>
    </div>
       {sho && (
       <div className='px-2'>
       <Table responsive bordered className='mt-3 mb-3 bor-dis'>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>DOJ</th>
                <th>Branch Name</th>
                <th>Member Id</th>
                <th>Customer Name</th>
                <th>Customer Number</th>
                <th>Payment Mode</th>
                <th>Total Amount</th>
                <th>Paid Amount</th>
                <th>Total Service</th>
                <th>Used Service</th>
                <th>Balance Service</th>
                <th>Create By</th>
                <th>View Service</th>
                <th>Tip Account</th>
                <th>Pending Amount</th>
                </tr>
            </thead>
            <tbody>
               { loading ? <td colSpan={16}
               className='text-center'>
                <l-line-spinner
  size="40"
  stroke="3"
  speed="1"
  color="black"
  className="relative"

></l-line-spinner>
               </td>:(data ===null ? <td>No Data Available</td> : data !==null && data?.membershipaccount?.map((ele,ind)=>{
                return(
                    <tr>
                    <td>{ind+1}</td>
                    <td className='text-info'>{formatDate(ele.cust_dop)} <p className=' text-danger'>actual_date <br/>{ele.actual_date}</p></td>
                    <td>{ele.branch_name}</td>
                    <td>{ele.member_id}</td>
                    <td>{ele.cust_name}</td>
                    <td>{ele.cust_contact}</td>
                    <td>{ele.payment_mode}</td>
                    <td>{ele.cust_total_amount}</td>
                    <td>{ele.cust_paid_amount}</td>
                    <td>{ele.cust_total_service}</td>
                    <td>{ele.cust_used_service}</td>
                    <td>{ele.cust_bal_service}</td>
                    <td>{ele.create_by}</td>
                    <td>
                    <Link target="_blank" rel="noopener noreferrer" to={`/serviceadmin/${ encodeURIComponent(encryptData(ele.user_id))}/${encodeURIComponent(encryptData(ele.member_id))}`} className='btn-click-1'>service</Link>
                  </td>

                  <td><Link target="_blank" rel="noopener noreferrer" to={`/tipadmin/${ encodeURIComponent(encryptData(ele.user_id))}/${encodeURIComponent(encryptData(ele.member_id))}`} className='btn-click-1'>Tip</Link></td>
                  
                  <td><Link target="_blank" rel="noopener noreferrer" to={`/paidadmin/${ encodeURIComponent(encryptData(ele.user_id))}/${encodeURIComponent(encryptData(ele.member_id))}`} className='btn-click-1'>Paid</Link></td>
                </tr>
                )
               }))}
            </tbody>
        </Table>
        </div>)}
        </div>
        </div>



   </>
  )
}

export default Membershipaccounttable