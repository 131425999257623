import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { useSelector } from "react-redux";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { Spinner, Table } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronDown } from "react-icons/fa";
import './attendance.css'
import { Form } from 'react-bootstrap';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { maskContact } from "../customfunction/dateformatmonth";
import { encryptData } from "../cryptoutils/cryptoutils";
import { Link, useNavigate } from "react-router-dom";


export default function Spamarketing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate=useNavigate()
  const [visible, setvisi] = useState(true)
  const [isFormVisible, setFormVisible] = useState(true);
  const [data, setdata] = useState("")
  const [report, setquicksearch] = useState([])
  const selector = useSelector(state => state)
  const [loading, setloading] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);


  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const handlechange = (e) => {
    setdata(e.target.value)
  }

  const handlesubmit = async (e) => {
    e.preventDefault()
    setloading(true)
    if (selector.details.data) {
      const branchname = selector.details.data[""][0].spa_branch_name
      const obj = {
        query: `[dbo].[spasoftware_web_proc_searchbybalance_getdata]@branchname='{0}',@searchbalance='{1}'`,
        queryArr: [`${branchname}`, `${data}`]
      }
      try {
        const getdata = await Hexapi(obj)
        console.log(getdata)
        setquicksearch(getdata[""])
        setloading(false)
      } catch (error) {
        console.log(error)
      }

    }

  }

  const handlecancel = async (userid) => {
    if (selector.details.data) {
      const branchname = selector.details.data[""][0].spa_branch_name

      const obj = {
        query: `[dbo].[spasoftware_web_proc_view_membership_cancelquery]@branchname='{0}',@userid='{1}'`,
        queryArr: [`${branchname}`, `${userid}`]
      }
      const getquery = await Hexapi(obj)
      console.log(getquery, "getquery")
      navigate('membership_account')
    }

  }

  // for printing 1 to 101

  const numbers = Array.from({ length: 101 }, (_, index) => index)


  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row) => (<div className="text-info">{row.cust_dop}<span className="text-danger"> actual date <br /> {row.actual_date}</span> </div>) },
    { Header: "Member Id", accessor: "member_id" },
    {
      Header: "Customer Name",
      accessor: "cust_name",
    },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Create By", accessor: "create_by" },
    {
      Header: "View Service",
      accessor: "booking",
      Cell: ({ row }) => (
        <>

          {row.original.cust_paid_amount < 10000 ? (
            <>
              <Link target="_blank" rel="noopener noreferrer" to={`/viewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>View History</Link>
              <Link target="_blank" rel="noopener noreferrer" className="btn-click-2" to={`/renewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`}>Renew</ Link>
            </>
          ) : (
            <>
              {row.original.cust_used_service === 0 ? (
                <button
                  className="btn-click-3"
                  onClick={() => handlecancel(row.original.user_id)}
                >
                  Cancel
                </button>
              ) : (
                <>
                 <Link target="_blank" rel="noopener noreferrer" to={`/viewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>service</Link>
                 <Link target="_blank" rel="noopener noreferrer" className="btn-click-2" to={`/renewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`}>Renew</ Link>
                </>
              )}
            </>
          )}

        </>
      )
    },
    {
      Header: "Tip Account",
      accessor: "tip",
      Cell: ({ row }) => (
        <>
          {row.original.booking_status === 1 ?  <Link target="_blank" rel="noopener noreferrer" to={`/tipaccount/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link> : null}
        </>
      )
    },
    {
      Header: "Pending Account",
      accessor: "pending",
      Cell: ({ row }) => (
        <>
          <Link target="_blank" rel="noopener noreferrer" to={`/paidaccount/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Paid</Link>
        </>
      )
    },
    {
      Header: "Follow up",
      accessor: "follow_up",
      Cell: ({ row }) => (
        <>
          <Link target="_blank" rel="noopener noreferrer" to={`/followup/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`}className='btn-click-1'>Follow Up</Link>
        </>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
      <div className='container'>
        <div className='row topatt mb-5'>
          <div className='dashservice'>
            <p className=' d-flex'><GiHamburgerMenu className='text-new name-text-2' /> <h4 className='name-text'> MARKETING : SEARCH BY BALANCE : FOLLOW UP</h4></p>
            <FaChevronDown className='text-new' onClick={() => { setvisi(!visible) }} />
          </div>
          {visible && (<>
            <div>
              <Form onSubmit={handlesubmit}>
                <div className='form-data mt-3'>
                  <Form.Label>
                    Select Balance
                  </Form.Label>
                  <Form.Select aria-label="Default select example"
                    name="searchoption"
                    value={data}
                    onChange={handlechange}>
                    <option>Select balance</option>
                    {numbers.map((ele) => {
                      return (
                        <option key={ele} value={ele}>{ele}</option>
                      )
                    })}
                  </Form.Select>
                </div>

                <hr />
                <div className='newshow'>
                  <button className='btn-1' >Show</button>
                  <button className='btn-click-1'>Cancel</button>
                </div>
                <hr />
              </Form>
            </div>

            <marquee className="marqueetag">
              <h3>
                <b>
                  This module will help for Maketing Purpose...
                </b>
              </h3>
            </marquee></>)}
        </div>
        <div className=" row bg-body-tertiary mt-5">
          <div className='dashservice'>
            <p className=' d-flex'><GiHamburgerMenu className='text-new name-text-2' /> <h4 className='name-text'>Membership Account</h4></p>
            <FaChevronDown className='text-new' onClick={toggleFormVisibility} />
          </div>

          <div className=" px-2">
            {isFormVisible && (
            <div>
              <div className="d-flex justify-content-between mt-2">
                <select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 25, 50, 75, 100].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  value={globalFilter || ""}
                  onChange={e => setGlobalFilter(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽 "
                                : " 🔼 "
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td colSpan={17} className="text-center">
                        <Spinner animation="border" />
                      </td>
                    </tr>
                  ) : report.length === 0 ? (
                    <tr>
                      <td className="fs-6" colSpan={17}>No Data Available</td>
                    </tr>
                  ) : (
                    page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between mb-3">
                <span>
                  Showing {startIndex}-{endIndex} of {totalEntries} entries
                  {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
                </span>
                <div className="pagination">
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                  </button>
                  <span>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                  </button>
                </div>
              </div>
            </div>)}
          </div>
        </div>

      </div>
    </>
  );
}