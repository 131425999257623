import React, { useEffect, useState } from 'react'
import Newdashboarb from './Newdashboarb'
import Dashboardtable from './Dashboardtable'
import Membershipaccounttable from './Membershipaccounttable'
import { useSelector } from 'react-redux'
import Hexapi from '../usefulcomps/HexAPI/Hexapi'
import SubadminDashboard from './SubadminDashboard'
import Managermembership from './Managermembership'
import Viewmembershipserviceuser from './Viewmembershipserviceuser'
import Renew from './Renew'
import PaidAccount from './PaidAccount'
import Tipaccount from './Tipaccount'


const MainDashboard = () => {
  const selector= useSelector(state=>state)
  const[data,setData]=useState(null)
  const[userrole,setuserrole]=useState(null)
  const[branchname,setbranchname]=useState(null)
  const[loading,setLoading]=useState(true)
  const [admindata,setadmindata]=useState(null)
  const [show, setShow] = useState(false);
  const [renewForm, setRenewForm] = useState(false);
  const[tipshow,settipshow]=useState(false)
  const[paidshow,setpaidshow]=useState(false)
  const[userid,setuserid]=useState(null)
  const[customer,setcustomername]=useState(null)
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const[customer_contact,setcust_contact]=useState(null)
  const[paidamount,setpaidamount]=useState(null)
  const[branch,setbranch]=useState(null)
  const[showservice,setshowservice]=useState(false)

  const[pending,setcust_pending]=useState(null)
  const[successmessage,setsuccessmessage]=useState("")

  const handleService = (user_id,memberid,cust_name,cust_contact,cust_paid,branch_name) => {
    setSelectedMemberId(user_id);
    setSelectedMemberId(memberid);
    setuserid(user_id)
    setcustomername(cust_name)
    setcust_contact(cust_contact)
    setpaidamount(cust_paid)
    setbranch(branch_name)
    setShow(true);
    console.log("test")
  };


  const handleRenew = (user_id,memberid,cust_name,cust_contact) => {
    setSelectedMemberId(memberid);
    setuserid(user_id)
    setcustomername(cust_name)
    setcust_contact(cust_contact)
    setRenewForm(true);
    setsuccessmessage(memberid)
  };

  const handletip=(memberid,userid)=>{
    setuserid(userid)
    setSelectedMemberId(memberid);
    settipshow(true)
  }

const handlepaid=(memberid,cust_bal_amount,user_id)=>{
  setSelectedMemberId(memberid);
  setcust_pending(cust_bal_amount)
  setuserid(user_id)
  setpaidshow(true)

}

  const dataget = async () => {
    console.log(userrole,"=========user")
    setLoading(true)
    try {
      if(userrole !==null && branchname !==null){

        if(userrole==='MANAGER'){
          const obj = {
            query: `[dbo].[spasoftware_dashboard_getdata]@user_role='{0}',@branch_name='{1}'`,
            queryArr: [`${userrole}`, `${branchname}`]
          }
          const getdata = await Hexapi(obj)
          console.log(getdata,"getdata")
          setData(getdata || [])
          setLoading(false)
        }

        if(userrole==="SUB ADMIN" || "SALES" || "ADMIN" || "USER"){
          const obj = {
            query: `[dbo].[spasoftware_dashboard_getdata]@user_role='{0}'`,
            queryArr: [`${userrole}`]
          }
          const getdata = await Hexapi(obj)
          console.log(getdata,"getdata")
          setadmindata(getdata || [])
          setLoading(false)
        }
      }
      } catch (err) {
        setLoading(false)
      }


  }

  useEffect(() => {
    if (selector.details.data!=null) {
      const userrol= selector.details.data[""][0].user_role
      setuserrole(userrol)
      console.log(userrol,"---------")
      const branchnam = selector.details.data[""][0].spa_branch_name
      setbranchname(branchnam)
      console.log(branchnam,"------------")
      dataget()
    } else {
      setLoading(false)
    }
  }, [selector,userrole,branchname])



  return (

    <>
{ loading ? <p className=' text-center'>loading.....</p> :<>{!show && !renewForm && !tipshow && !paidshow && ! showservice?  ( <div>
      { data!==null ? (<>
  {userrole==="MANAGER" ? <>
  <Newdashboarb data={data}/>
  <Managermembership data={data} show={show} handleservice={(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)=>handleService(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)} handleRenewdata={(user_id,memberid,cust_name,cust_contact)=>handleRenew(user_id,memberid,cust_name,cust_contact)} handletipdata={(memberid,userid)=>handletip(memberid,userid)} handlepaiddata={(memberid,cust_bal_amount,user_id)=>handlepaid(memberid,cust_bal_amount,user_id)}/>
  <Dashboardtable data={data}/>
  </> : null}
      </>) : <p className=' text-center'></p>
}
        {
          admindata!==null ? (<>
          {userrole!=="MANAGER" ? <>
          <SubadminDashboard data={admindata}/>
          <Membershipaccounttable  data={admindata} handleservice={(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)=>handleService(user_id,member_id,cust_name,cust_contact,cust_paid_amount,branch_name)} handleRenewdata={(user_id,memberid,cust_name,cust_contact)=>handleRenew(user_id,memberid,cust_name,cust_contact)} handletipdata={(memberid,userid)=>handletip(memberid,userid)} handlepaiddata={(memberid,cust_bal_amount,user_id)=>handlepaid(memberid,cust_bal_amount,user_id)}/>
          <Dashboardtable data={admindata} />
          </> : null}
          </>) : <h6 className='text-center'></h6>
        }

    </div>) : null}
        </> }




    </>

  )
}

export default MainDashboard