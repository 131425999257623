import React, { useEffect, useState } from 'react'
import Spasoft from '../images/logo.png'
import { MdMarkEmailRead } from "react-icons/md";
import { FaBell } from "react-icons/fa6";
import { MdAccountCircle } from "react-icons/md";
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.css'
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';


const Navbarcomponent = () => {
  const selector = useSelector(state => state)
  const [adminname, setadminname] = useState(null);
  const [roomno, setroomno] = useState('');
  const [therapistname,settherapistname]=useState('');
  const [therapistendtime,setherapistendtime] = useState('')
  const [totaln,setTotal] = useState('');
  const [priortotal,setpriortotal] = useState('');
  const [followuptotal, setFollowuptotal] = useState('');
  const navigate = useNavigate();

  const handlePresentTherapist = async() => {
    if (selector.details.data) {
      const branchname = selector.details.data[""][0].spa_branch_name;

      if(branchname !== null){
        let obj = {
          query : `[dbo].[spasoftware_web_proc_NavbarTherapistInfo_getbusytherapistdata]@branch_name='{0}'`,
          queryArr:[`${branchname}`]
        }
        const resp = await Hexapi(obj)
          setTotal(resp[""][3].totaln);
          setpriortotal(resp[""][1].total);
          setFollowuptotal(resp[""][0].totalfollowup)
      }
      }
      }


  useEffect(() => {
    handlePresentTherapist();
    if (selector.details.data != null) {
      const managername = selector.details.data[""][0].manager_name
      setadminname(managername)
    }
  }, [selector])

  const handlelogout = () => {
    sessionStorage.removeItem('Apipathurl')
    window.location.href = "https://bagero.hexbss.xyz/login"
  }
  return (
    <div className="container-fluid nav-top fixed-top">
      <div className="row">
        <div className="col-12">
          <div className='dis-play'>
            <div className='dis-width '>
              <img src={Spasoft} alt="" className=' img-fluid' />
            </div>
            <div className='dis-play-1 back-color'>
              <div className='dis-icon'>
                <Dropdown onClick={handlePresentTherapist}>
                  <Dropdown.Toggle variant="none" id="dropdown-basic">
                    <FaBell className='icon-size-1' />
                    Therapist Information
                    <Badge bg="info" className='pos'>{totaln}</Badge>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Currently Busy Therapist</Dropdown.Item>
                    <Dropdown.Item href="#">Therapist Name     R.No.~: <br/>She will free after:~ </Dropdown.Item>
                    <Dropdown.Item href="#">Today Present Therapist</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='dis-icon'>
                <Dropdown>
                  <Dropdown.Toggle variant="none" id="dropdown-basic">
                    <FaBell className='icon-size-1' />
                    Prior Appointment
                    <Badge bg="info" className='pos'>{priortotal}</Badge>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Today prior appointments</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div>
              <div className='dis-play back-color'>
                <div className='dis-icon'>
                  <Dropdown>
                  <Dropdown.Toggle variant="none" id="dropdown-basic">
                    <FaBell className='icon-size-1' />
                    Today followup
                    <Badge bg="info" className='pos'>{followuptotal}</Badge>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Customer FollowUp (Call Now)</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </div>
                <div className='dis-icon'>
                  <p><MdAccountCircle className='icon-size-1' /></p>
                  <NavDropdown title={adminname != null ? adminname : "Admin Account"} id="navbarScrollingDropdown">
                    <Link to="profile" className='text-black' style={{ marginLeft: "16px" }}>  My Profile</Link>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handlelogout}>
                      logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Navbarcomponent