import { useEffect, useState } from "react"

const useNavwidth=()=>{
    const[windowsize,setwindowsize]=useState({
        width:undefined,
        height:undefined
    })

 const handlesize=()=>{
    setwindowsize({
        width:window.innerWidth,
        height:window.innerHeight
    })
 }
 useEffect(()=>{
    window.addEventListener("resize",handlesize)
    handlesize()
    return()=>window.removeEventListener("resize",handlesize)
 },[])
 return windowsize
}

export default useNavwidth