import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import DatePicker from "react-datepicker";
import { lineSpinner } from 'ldrs';
import { Spinner, Table } from "react-bootstrap";
import Swal from 'sweetalert2';
lineSpinner.register();


function Membershipreference() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState({
    startdate: "",
    enddate: "",
  });
  const [report, setReport] = useState([]);
  const selector = useSelector(state => state);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleReport = async (e) => {
    e.preventDefault();

    if (!data.startdate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the start date.",
      });
    
    } else if (!data.enddate) {
      Swal.fire({
        icon: "warning",
        title: "Missing Information",
        html: "Please select the end date.",
      });
    }else{
      setLoading(true);

      const obj = {
        query: `[dbo].[spasoftware_web_proc_membershipref_admin_getdata]@startdate='{0}',@enddate='{1}'`,
        queryArr: [`${data.startdate.toISOString().split('T')[0]}`, `${data.enddate.toISOString().split('T')[0]}`],
      };
      const getData = await Hexapi(obj);
      console.log(getData,"----------")
      setReport(getData.membershiprefdata || []);
      setLoading(false);
    }
   
  };
  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const formattedData = report.map(item => ({
      'Spa Branch Name': item.branch_name,
      'Therapist Name': item.refname,
      'Total Membership': item.count,
      'Total Amount': item.total_amount,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Membership_Reference_Report' + fileExtension;
    saveAs(data, fileName);
  };
  const columns = useMemo(() => [
    { Header: "Spa Branch Name", accessor: "branch_name" },
    { Header: "Therapist Name", accessor: "refname" },
    { Header: "Total Membership", accessor: "count" },
    { Header: "Total Amount", accessor: "total_amount" },
  ], []);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);

  const handleChangeReport = (date, name) => {
    if(date){
      setData({ ...data, [name]: date });
    }else{
      setData({ ...data, [name]: "" });
    }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date() });
    }
  };




  return (
    <>
      <div className="quick_search_page">
        <div className="widget-title">
          <h4>
            <i className="icon-reorder"></i> Search Report
          </h4>
          <span>

              <FaChevronDown onClick={toggleFormVisibility} />

          </span>
        </div>
      </div>
      {isFormVisible && (
        <div className="widget-body form mb-3">
          <form
            name="dname"
            id="dname1"
            className="form-horizontal"
            onSubmit={handleReport}
          >
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Select Start Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select End Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div className="form-actions-control mb-3">
                <button
                  type="submit"
                  name="ShowInfoBtn"
                  className="btn btn-success mb-3"
                  id="show_btn"

                >
                  Show Report
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="container mb-4">
        <div className="row bg-body-tertiary mt-4">
          <div className="dashservice">
            <p className="name-text">
              <span className="text-danger">
                {selector.details.data != null ? selector.details.data[""][0].spa_branch_name : null}
              </span>
              || <span className="text-info">Start From {data.startdate ? data.startdate.toDateString() : ""}</span>
              || <span className="text-success">Start To {data.enddate ? data.enddate.toDateString() : ""}</span>
            </p>
            <FaChevronDown />
          </div>
          <div>
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 25, 50, 75, 100].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={globalFilter || ""}
                      onChange={e => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {loading ? (
                          <tr>
                          <td colSpan={17} className="text-center">
                          <Spinner animation="border" />
                        </td>
                           </tr>
                        ) : report.length === 0 ? (
                          <tr>
                            <td className="fs-6" colSpan={17}>No Data Available</td>
                          </tr>
                        ) : (
                          page.map(row => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                ))}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                  <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                    </div>
                  </div>
                  <button onClick={exportToExcel} className="btn-click-6">
        Generate Report
      </button>
                </div>
        </div>
      </div>
    </>
  );
}

export default Membershipreference;





















