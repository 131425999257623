import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { default as Select } from 'react-select';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs';
import { maskContact } from "../customfunction/dateformatmonth";
lineSpinner.register();

function Dailysale() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [branchNames, setBranchNames] = useState([]);
  const [isFormVisible, setFormVisible] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState({
    startdate: "",
    enddate: "",
    branchname: ""
  });
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);


  useEffect(() => {
    const fetchBranchNames = async () => {
      const obj = {
        query: `[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr: []
      };

      const getData = await Hexapi(obj);
      console.log(getData.branch,"=================")
      setBranchNames(getData.branch || []);
    };

    fetchBranchNames();
  }, []);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleReport = async () => {
    console.log(data,"--------------------")
    setLoading(true);

    const obj = {
      query: `[dbo].[spasoftware_web_proc_dailybook_admin_getdata]@branchname='{0}',@startdate='{1}',@enddate='{2}'`,
      queryArr: [`${data.branchname}`, `${data.startdate}`, `${data.enddate}`]
    };

    const getData = await Hexapi(obj);
    setReport(getData[""] || []);
    setLoading(false);
  };

  const handleDateChange = (date, name) => {
    if(date){
      setData({ ...data, [name]: date.toISOString().split('T')[0] });
    }else{
      setData({ ...data, [name]: "" });
    }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date().toISOString().split('T')[0] });
    }
  };

  const handlebranchChange = (selectedOption) => {
    setData({ ...data, branchname: selectedOption ? selectedOption.value : '' });
  };
  const branchOptions = branchNames.map(br => ({
    value: br.branchname,
    label: br.branchname
  }));

  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const formattedData = report.map((item,i) => ({
      'sr no': i+1,
      'Date': item.service_date.date.split(' ')[0],
      'Customer Name': item.cust_name,
      'Customer Type': item.cust_type,
      'Customer ID':item.member_id,
      'Therapist Name' :item.therapist_name,
      'Amount':item.cust_dop === item.service_date.date.split(' ')[0] ? item.cust_paid_amount : 0,
     'Room No' : item.room_no,
      "Time In" : item.time_in,
      'Time Out': item.time_out,
      'Hour': item.cust_bal_service,
       'Manager Name'  :`${item.create_by}  ${item.service_status  === 1 ? "confirm" :"cancel"}`,

    }))
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Daily_Sale_Report' + fileExtension;
    saveAs(data, fileName);
  };


  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "Date", accessor: "service_date.date" ,Cell:({row}) => `${row.original.service_date.date.split(' ')[0]}`},
    { Header: "Customer Name", accessor:"cust_name" },
    { Header: "Customer Type", accessor:"cust_type",Cell: ({row})=>(
    <> {row.original.cust_type}
    <br/>
    {row.original.payment_mode === <span className="text-info">Complentary</span> ? (
      row.original.payment_mode
        ) : row.original.payment_mode === <span className="text-info">Cash</span> ? (
          row.original.payment_mode
        ) : row.original.payment_mode === <span className="text-info">Credit Card</span> ? (
          row.original.payment_mode
        ) : null}</>) },
    { Header: "Customer Id", accessor: "member_id" },
    { Header: "Customer No", accessor:"cust_contact" ,Cell:({row}) => maskContact(row.original.cust_contact) },
    { Header: "Therapist Name", accessor:"therapist_name" },
    { Header: "Amount", accessor: "cust_paid_amount", Cell:({row})=>(<>{row.original.cust_dop === row.original.service_date.date.split(' ')[0] ? row.original.cust_paid_amount : 0}</>) },
    { Header: "Room No", accessor: "room_no" },
    { Header: "Time In", accessor: "time_in" },
    { Header: "Time Out", accessor: "time_out" },
    { Header: "Hour", accessor: "cust_bal_service" },
    { Header: "Manager Name", accessor: "create_by" ,Cell:({row}) =>( <> {row.original.create_by} {row.original.service_status === 1 ? <span className="text-info">confirm</span> : <span className="text-danger">cancel</span>}</>) },


  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);

  return (
    <div id="mydailysalebook">
      <div className="quick_search_page">
        <div className="widget-title">
          <h4>
            <i className="icon-reorder"></i> Search Service Report
          </h4>
          <span className="">

              <IoIosArrowDown  onClick={toggleFormVisibility} />

          </span>
        </div>
      </div>
      {isFormVisible && (
        <div className="widget-body form">
          <div className="form-horizontal">
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Select Branch Name</label>
                <div className="controls">
                <Select
                      className="span6"
                      id="therapist_weekly_off"
                      name="branchname"
                      options={branchOptions}
                      value={branchOptions.find(option => option.value === data.branchname)}
                      onChange={handlebranchChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Branch Name"
                    />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select Start Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleDateChange(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select End Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleDateChange(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div className="form-actions-control">
                <button
                  type="button"
                  className="btn btn-success"
                  id="show_btn"
                  onClick={handleReport}
                >
                  Show Report
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container mb-4">
        <div className="row bg-body-tertiary mt-4">
          <div className="dashservice">
            <p className="name-text fs-6">
              Daily Sale Book &nbsp; &nbsp; || <span className="text-info">Start From {data.startdate!=="" ? data.startdate : ""}</span> || <span className="text-success">End To {data.enddate !=="" ? data.enddate : ""} || {data.branchname}</span>
            </p>
            <FaChevronDown onClick={() => setShowTable(!showTable)} />
          </div>
          {showTable && (
        <div className="mb-3">
        <div className="d-flex justify-content-between mt-2">
        <select
            value={pageSize}

            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50,75,100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        <input
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className=" mb-3"
          placeholder="search..."
        />

          </div>
       { report !==null ?  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
             <tr>
             <td colSpan={17} className="text-center">
             <Spinner animation="border" />
           </td>
              </tr>
            ) : report.length === 0 ? (
              <tr>
                <td className="fs-6" colSpan={17}>No Data Available</td>
              </tr>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table> : null}
     <div className="d-flex justify-content-between mb-3">
     <span>
  Showing {startIndex}-{endIndex} of {totalEntries} entries
  {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
     <div className="pagination">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>

        </div>
     </div>
     <button onClick={exportToExcel} className="btn-click-6">
          Generate Report
        </button>
      </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dailysale;

