import React, { useEffect, useState } from 'react'
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { default as Select } from 'react-select';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';

export default function PaidAdminUpdate({ userid, setbackpaid, editupdate, handlequerydata, memberId, pendingData }) {
    const paidaccid = userid;
    // console.log(paidaccid, "-----")
    const [show, setshow] = useState(true);
    const [showupdate, setshowupdate] = useState(true)
    const [payment, setpayment] = useState([]);
    const [paymentmode, setpaymentmode] = useState('');
    const [branchName, setbranchName] = useState([]);
    const [datadop, setdata1] = useState("")
    const [data, setdata] = useState({
        paymentmode: "",
        memberid: "",
        branchname: "",
        paidamount: "",
        basicamount: "",
        taxamount: "",
        balanceamount: "",
        memberid: '',
        depositeDate: ""
    })
    const getData = () => {
        let obj = {
            query: `[dbo].[spasoftware_web_proc_subadmin_paidgetdata]@pendingaccid='{0}',@memberId='{1}'`,
            queryArr: [`${paidaccid}`, `${memberId}`]
        }
        Hexapi(obj).then((resp) => {
            console.log(resp);
            setbranchName(resp.branch);
            setpayment(resp.paymentmode);
            setpaymentmode(resp[""][0].payment_mode);
            const payment_data = resp[""][0].payment_mode;
            const branchname_data = resp[""][0].branch_name;
            const depositdate = resp[""][0].currdate.date;
            const basicAmount = resp[""][0].bal_amount;
            const paidAmount = resp[""][0].paid_amount;
            const balanceAmount = resp[""][0].bal_amount;
            setdata({
                paymentmode: payment_data,
                branchname: branchname_data,
                depositeDate: depositdate,
                basicamount: basicAmount,
                paidamount: paidAmount,
                memberid: memberId,
                balanceamount: balanceAmount
            })
        });
    }
    useEffect(() => {
        getData();
    }, [paidaccid]);
    const handlebranchChange = (selectedOption) => {
        setdata({ ...data, branchname: selectedOption ? selectedOption.value : '' });
    };
    const handleChangeReport = (date) => {
        if (date) {
            setdata((prev) => ({
                ...prev,
                depositeDate: date.toISOString().split('T')[0]
            }))
            // setdata1(date.toISOString().split('T')[0])
        } else {
            setdata((prev) => ({
                ...prev,
                depositeDate: ""
            }))
            // setdata1("")
        }
    };
    const branchOptions = branchName.map(br => ({
        value: br.branchname,
        label: br.branchname
    }))
    const handlePaidUpdate = () => {

        let obj = {
            query: `[dbo].[spasoftware_web_proc_paidsubadmin_updatetable]@pendingaccid='{0}',@paymentmode='{1}',@balamount='{2}',@depositamount='{3}',@currdate='{4}'`,
            queryArr: [`${paidaccid}`, `${data.paymentmode}`,
            `${data.basicamount}`,
            `${data.paidamount}`,
            `${data.depositeDate}`]
        }
        Hexapi(obj).then((resp) => {
            console.log(resp);
            Swal.fire({
                text: `${resp[""][0]['msg']}`,
                icon: 'ok',
              });
            setbackpaid();
            handlequerydata();
        });


    }
    const handleback = () => {
        setbackpaid();
        handlequerydata();
    }
    const setCurrentDateIfEmpty = () => {
        if (!datadop) {
            setdata1(new Date());
        }
    };
    const paymentOptions = payment.map(th => ({
        value: th.paymentmode,
        label: th.paymentmode
    }));
    const handlepaymentChange = (selectedOption) => {
        setdata({ ...data, paymentmode: selectedOption ? selectedOption.value : '' });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setdata({ ...data, [name]: value });
    };
    return (
        <>
            <div className="container top-acc highlightBcg">
                <div className="row ">
                    <div className="dashservice">
                        <p className="name-text">
                            <IoMdArrowRoundBack onClick={handleback} /> &nbsp; &nbsp; &nbsp;UPDATE PENDING PAYMENT ACCOUNT
                        </p>
                        <div className="">
                            <FaChevronDown onClick={() => { setshow(!show) }} className="hideShowBtn" />
                        </div >
                    </div>
                </div>

                <div className="row bg-body-tertiary">
                    {show && (
                        <form onSubmit={handlePaidUpdate} className="form-data">
                            <div className="controls" >
                                <div className="control-group">
                                    <label className="control-label">
                                        Deposit Date</label>
                                    <div className="blinkTextDiv controls">
                                        <DatePicker
                                            selected={data.depositeDate}
                                            onChange={(date) => handleChangeReport(date, 'data.depositeDate')}
                                            className="span6  popovers"
                                            dateFormat="dd/MM/yyyy"
                                            onFocus={() => setCurrentDateIfEmpty('datadop')}
                                        />
                                    </div>
                                </div>
                                <div className="control-group">
                                    <label className="control-label">
                                        Payment Mode
                                    </label>
                                    <div className="controls">
                                        <Select
                                            className="span6"
                                            id="therapist_weekly_off"
                                            name="therapistnamee"
                                            options={paymentOptions}
                                            value={paymentOptions.find(option => option.value === data.paymentmode)}
                                            onChange={handlepaymentChange}
                                            isClearable
                                            classNamePrefix="react-select"
                                            placeholder="Select Payment Mode"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="control-group">
                                    <label className="control-label">
                                        Branch Name
                                    </label>
                                    <div className="controls">
                                        <Select
                                            className="span6"
                                            id="branchname"
                                            name="branchname"
                                            options={branchOptions}
                                            value={branchOptions.find(option => option.value === data.branchname)}
                                            onChange={handlebranchChange}
                                            isClearable
                                            classNamePrefix="react-select"
                                            placeholder="Select Branch Name"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="control-group">
                                    <label className="control-label">
                                        MemberId
                                    </label>
                                    <div className="controls">
                                        <input
                                            type="text"
                                            className="span7  popovers"
                                            name="memberid"
                                            value={data.memberid}
                                            id="customer_name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="control-group d-flex flex-wrap">
                                    <label className="control-label">
                                        Pending Amount
                                    </label>
                                    <input type="number" id="balance_amount"
                                        className="span7  popovers"
                                        name="balanceamount"
                                        value={data.balanceamount}
                                        disabled />
                                    <p className=" mt-4">Not Understand Call us </p>
                                    {/* <img src={imgarrow} style={{width:"40px", height:"20px" ,marginTop:"20px"}}/> */}
                                </div>
                                <div className="control-group d-flex flex-wrap">
                                    <label className="control-label">
                                        Paid Amount</label>
                                    <div className="controls ">
                                        <input
                                            type="number"
                                            name="paidamount"
                                            id="tax_amount"
                                            className="span6  popovers"
                                            value={data.paidamount}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="createcancel_btn">
                                    <button
                                        type="submit"

                                        className="btn-data"
                                        // onClick={handlePaidUpdate}
                                    >
                                        Update
                                    </button>
                                    <button type="reset" className="cancel-btn btn">Cancel</button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>

        </>
    )
}