import React, { useEffect, useState } from 'react';
import './Invoice.css';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { decryptData } from '../cryptoutils/cryptoutils';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Table } from 'react-bootstrap';


export default function WalkinInvoice() {  
  const{branchtable,userid}=useParams()
  const userId= decryptData(userid)
  const branchTable=decryptData(branchtable)
  const [response1,setresponse1]=useState({})
  const [response,setresponse]=useState({})

  const getdata=async()=>{
  
    try {
      if(branchTable !==undefined && userId !==undefined){
        const obj={
          query:`[dbo].[spasoftware_web_proc_walkinInvoice_invoicedata]@branch_table_name='{0}', @invId='{1}'`,
            queryArr:[`${branchTable}`,`${userId}`]
         }
         const resp =await Hexapi(obj)
         console.log(resp,"response")
         setresponse1(resp.companydetails[0])
         setresponse(resp.invoicedata[0])
      }
    } catch (error) {
      console.log(error,"heyy")
    }

  }
  useEffect(()=>{
getdata()
  },[])

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('invoice-content'),
  });

  return (
    <div className=" container invoice-container">
      <div id='invoice-content'>
      <div className="invoice-section " >
        
        <div className='row text-black p-2'> 
          <div className=' col-2'>
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>GSTIN</p>
            </div> 
           <div className='col-3'>
           <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{response1.gst_no}</p>
           </div>
           <div className='col-4'>
           <p style={{ fontSize: '14px', fontWeight: 'bold',marginLeft:"20px" }}>INVOICE</p>
           </div>
            <div className='col-3 '>
            <p className=' test-original'>(ORIGINAL FOR BUYER)</p>
              <p className=' test-original-1'>PH : {response1.branch_contact_no}</p>
              <p className=' test-original-1'>Mob : {response1.branch_mobile_no}</p>
            </div>
        </div>
        <div>            
             <center><h6 style={{fontWeight:"500" ,color:"black"}} ><b>{response1.spa_name}</b></h6></center> 
             <center><a style={{fontWeight:"500" ,color:"black"}}>(UNIT OF {response1.company_name})</a></center>
             <center><h6 style={{fontWeight:"500" ,color:"black"}}>{response1.branch_address}</h6></center> 
        </div>
   
  <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
    <tr>
      <td>
        <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
          <tr>
            <td>NAME</td>
            <td>{response.cust_name}</td>
            <td>INVOICE NO.</td>
            <td>{userId}</td>
          </tr>
          <tr>
            <td>ADDRESS</td>
            <td>{response.cust_address}</td>
            <td>BILL DATE</td>
            <td>{new Date(response?.booking_date?.date).toLocaleDateString("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric"
}).replace(/ /g, "-") || "No date available"}
</td>
          </tr>
          <tr>
            <td>CONTACT NO.</td>
            <td>{response.cust_contact}</td>
            <td>PAYMENT TYPE</td>
            <td>{response.payment_type}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>SERVICE TYPE</td>
            <td>{response.service_type}</td>
          </tr>
        </Table>
      </td>
    </tr>
  </Table>

  <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
    <tr>
      <td valign="top">
        <Table  
style={{ 
  border: '4px double #666', 
  borderCollapse: 'collapse', 
  marginBottom:"0px"
}} 
cellPadding="5" 
cellSpacing="0" >
          <tr style={{ border: '1px solid #666',borderStyle:'solid' }}>
            <td width="28" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Sno.</td>
            <td width="209" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Description</td>
            <td width="67" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>HSN/SAC</td>
            <td width="28" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Qty.</td>
            <td width="99" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Price</td>
            <td width="56" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>SGST</td>
            <td width="56" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>CGST</td>
            <td width="50" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Total</td>
            <td width="64" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Discount</td>
            <td width="88" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid',lineHeight:"13px"  }}>Received Amount</td>
            <td width="88" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid',lineHeight:"13px" }}>Outstanding Amount</td>
            <td width="64" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>SGST.</td>
            <td width="89" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>CGST.</td>
          </tr>
          <tr>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td style={{ border: '1px solid #666' }}>Oil Massage</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>0</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.basic_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.total_bill_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.discount_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.paid_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.balance_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
          </tr>
          <tr style={{ border: '1px solid #666' }}>
            <td colSpan="3" align="center" style={{ border: '1px solid #666',borderStyle:'solid',color:"black", fontWeight:"700" ,textAlign:"right" }}>Total</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.basic_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.total_bill_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.discount_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.paid_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.balance_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} colSpan="3">{response.amount_in_words} Only /-</td>
          </tr>
          <tr>
            <td colSpan="8" valign="bottom" style={{ border: '1px solid #666',borderStyle:'solid' }}>
              <Table responsive width="564" cellPadding="1" cellSpacing="1" style={{marginBottom:"0px"}}>
                <tr>
                  <td style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} width="274">E. &amp;. O. E.</td>
                  <td  style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} width="281" align="right">Customer Signature</td>
                </tr>
              </Table>
            </td>
            <td colSpan="5" valign="top" style={{ border: '1px solid #666',borderStyle:'solid' }}>
              <Table responsive width="324" border="0" cellPadding="1" cellSpacing="1" style={{marginBottom:"0px"}}>
                <tr>
                <td 
  align="left" 
  style={{ 
    border: '1px solid #666', 
    padding: '8px', 
    verticalAlign: 'top' 
    ,textAlign:"right"
  }}
>
  For<span style={{ backgroundColor:"transparent", fontWeight: 'bold',textAlign:"right" }}>{response?.spa_name}</span>Unit of Ganpati Arcade
</td>

                </tr>
                <tr>
                  <td align="right" style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} valign="bottom">Authorised Signatory</td>
                </tr>
              </Table>
            </td>
          </tr>
        </Table>
      </td>
    </tr>
  </Table>
    </div>
    <div className="invoice-divider">
    <span className="scissors">✂</span>
  <div className="dashed-line">
 
  </div>
  <button onClick={handlePrint} className='print-button'>Print Invoice</button>
  <div className="dashed-line">
    <span className="scissors">✂</span>
  </div>
</div>

        <div className="invoice-section" >
        
        <div className='row text-black p-2'> 
          <div className=' col-2'>
          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>GSTIN</p>
            </div> 
           <div className='col-3'>
           <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{response1.gst_no}</p>
           </div>
           <div className='col-4'>
           <p style={{ fontSize: '14px', fontWeight: 'bold',marginLeft:"20px" }}>INVOICE</p>
           </div>
            <div className='col-3 '>
            <p className=' test-original'>(ORIGINAL FOR BUYER)</p>
              <p className=' test-original-1'>PH : {response1.branch_contact_no}</p>
              <p className=' test-original-1'>Mob : {response1.branch_mobile_no}</p>
            </div>
        </div>
        <div>            
             <center><h6 style={{fontWeight:"500" ,color:"black"}} ><b>{response1.spa_name}</b></h6></center> 
             <center><a style={{fontWeight:"500" ,color:"black"}}>(UNIT OF {response1.company_name})</a></center>
             <center><h6 style={{fontWeight:"500" ,color:"black"}}>{response1.branch_address}</h6></center> 
        </div>
   
  <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
    <tr>
      <td>
        <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
          <tr>
            <td>NAME</td>
            <td>{response.cust_name}</td>
            <td>INVOICE NO.</td>
            <td>{userId}</td>
          </tr>
          <tr>
            <td>ADDRESS</td>
            <td>{response.cust_address}</td>
            <td>BILL DATE</td>
            <td>{new Date(response?.booking_date?.date).toLocaleDateString("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric"
}).replace(/ /g, "-") || "No date available"}
</td>
          </tr>
          <tr>
            <td>CONTACT NO.</td>
            <td>{response.cust_contact}</td>
            <td>PAYMENT TYPE</td>
            <td>{response.payment_type}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>SERVICE TYPE</td>
            <td>{response.service_type}</td>
          </tr>
        </Table>
      </td>
    </tr>
  </Table>

  <Table responsive  border="0" cellSpacing="1" cellPadding="1" style={{marginBottom:"0px"}}>
    <tr>
      <td valign="top">
        <Table  
style={{ 
  border: '4px double #666', 
  borderCollapse: 'collapse', 
}} 
cellPadding="5" 
cellSpacing="0" >
          <tr style={{ border: '1px solid #666',borderStyle:'solid' }}>
            <td width="28" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Sno.</td>
            <td width="209" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Description</td>
            <td width="67" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>HSN/SAC</td>
            <td width="28" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Qty.</td>
            <td width="99" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Price</td>
            <td width="56" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>SGST</td>
            <td width="56" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>CGST</td>
            <td width="50" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Total</td>
            <td width="64" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Discount</td>
            <td width="88" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Received Amount</td>
            <td width="88" rowSpan="2" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>Outstanding Amount</td>
            <td width="64" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>SGST.</td>
            <td width="89" align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>CGST.</td>
          </tr>
          <tr>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
            <td align="center" bgcolor="#CCCCCC" style={{ border: '1px solid #666',borderStyle:'solid' }}>9%</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td style={{ border: '1px solid #666' }}>Oil Massage</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>0</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.basic_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.total_bill_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.discount_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.paid_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.balance_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
          </tr>
          <tr style={{ border: '1px solid #666' }}>
            <td colSpan="3" align="center" style={{ border: '1px solid #666',borderStyle:'solid',color:"black", fontWeight:"700" ,textAlign:"right" }}>Total</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>1</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.basic_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.gst_amount / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.total_bill_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.discount_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.paid_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.balance_amount}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
            <td align="right" style={{ border: '1px solid #666',borderStyle:'solid' }}>{response.after_discount_tax / 2}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} colSpan="3">{response.amount_in_words} Only /-</td>
          </tr>
          <tr>
            <td colSpan="8" valign="bottom" style={{ border: '1px solid #666',borderStyle:'solid' }}>
              <Table responsive width="564" cellPadding="1" cellSpacing="1" style={{marginBottom:"0px"}}>
                <tr>
                  <td style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} width="274">E. &amp;. O. E.</td>
                  <td  style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} width="281" align="right">Customer Signature</td>
                </tr>
              </Table>
            </td>
            <td colSpan="5" valign="top" style={{ border: '1px solid #666',borderStyle:'solid' }} >
              <Table responsive width="324" border="0" cellPadding="1" cellSpacing="1" style={{marginBottom:"0px"}}>
                <tr>
                <td 
  align="left" 
  style={{ 
    border: '1px solid #666', 
    padding: '8px', 
    verticalAlign: 'top' 
    ,textAlign:"right"
  }}
>
  For<span style={{ backgroundColor:"transparent", fontWeight: 'bold',textAlign:"right" }}>{response?.spa_name}</span>Unit of Ganpati Arcade
</td>

                </tr>
                <tr>
                  <td align="right" style={{ border: '1px solid #666',borderStyle:'solid',textAlign:"right" }} valign="bottom">Authorised Signatory</td>
                </tr>
              </Table>
            </td>
          </tr>
        </Table>
      </td>
    </tr>
  </Table>
    </div>
    </div>
    </div>
  );
}