import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs';
lineSpinner.register();

export default function Therapistreport() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setData] = useState({
    startdate: undefined,
    enddate: undefined
  });
  const [report, setReport] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const selector = useSelector(state => state);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  function convertDateFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (selector.details.data) {
      const spa_branch_name = selector.details.data[""][0].spa_branch_name;

      const obj = {
        query: `[dbo].[spasoftware_web_proc_therapistreport_getdata]@branchname='{0}',@startdate='{1}',@enddate='{2}'`,
        queryArr: [`${spa_branch_name}`, `${convertDateFormat(data.startdate)}`, `${convertDateFormat(data.enddate)}`]
      };
      const getdata = await Hexapi(obj);
      const reportDataWithBranch = getdata[""].map(item => ({
        ...item,
        spa_branch_name // add spa_branch_name to each item
      }));
      setReport(reportDataWithBranch);
      setLoading(false);
    }
  };

  const handleChangeReport = (date, name) => {
   if(date){
    setData({ ...data, [name]: date });
   }else{
    setData({ ...data, [name]: "" });
   }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date() });
    }
  };





  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "Spa Branch Name", accessor:"spa_branch_name" },
    { Header: "Therapist Name", accessor: "therapist_name" },
    { Header: "Total Service", accessor: "service_count" },

  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
      <div className="quick_search_page">
        <div className="widget-title">
          <p className="name-text">
            Manager Accounts
          </p>


              <FaChevronDown  onClick={toggleFormVisibility} style={{marginTop:"4px", marginRight:"8x"}}/>


        </div>
      </div>

      {isFormVisible && (
        <div className="widget-body form">
          <form
            name="dname"
            id="dname1"
            className="form-horizontal"
            onSubmit={handleReport}
          >
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Select Start Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select End Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div className="form-actions-control">
                <button
                  type="submit"
                  name="ShowInfoBtn"
                  className="btn btn-success"
                  id="show_btn"
                >
                  Show Report
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="container mb-5">
        <div className="row bg-body-tertiary mt-4">
          <div className="dashservice">
            <p className="name-text">
              Therapist Report --- <span className=" text-danger">Start From {data.startdate ? data.startdate.toLocaleDateString() : ""} </span>||  <span className=" text-success">Start To {data.enddate ? data.enddate.toLocaleDateString() : ""}</span>
            </p>
            <FaChevronDown onClick={() => setShowTable(!showTable)} />
          </div>
          <div>
            {showTable && (

              <div>
              <div className="d-flex justify-content-between mt-2">
                <select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 25, 50, 75, 100].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  value={globalFilter || ""}
                  onChange={e => setGlobalFilter(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                   <td colSpan={17} className="text-center">
                   <Spinner animation="border" />
                 </td>
                    </tr>
                  ) : report.length === 0 ? (
                    <tr>
                      <td className="fs-6" colSpan={17}>No Data Available</td>
                    </tr>
                  ) : (
                    page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between mb-3">
              <span>
          Showing {startIndex}-{endIndex} of {totalEntries} entries
          {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
        </span>
                <div className="pagination">
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                  </button>
                  <span>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                  </span>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                  </button>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
