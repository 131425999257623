import React, { useEffect, useMemo, useRef, useState } from "react";
import "./QuickSearch.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import PaidAccount from "./PaidAccount";
import Viewmembershipserviceuser from "./Viewmembershipserviceuser";
import Tipaccount from "./Tipaccount";
import { lineSpinner } from 'ldrs';
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { formatDate } from "../customfunction/dateformatmonth";
import { Link, useNavigate } from "react-router-dom";
import Invoice from "./WalkinInvoice";
import WalkinInvoice from "./WalkinInvoice";
import { Aesdecryption, Aesencryption } from '../usefulcomps/Aes/Aes';
import { encryptData } from "../cryptoutils/cryptoutils";



lineSpinner.register();

export default function WalkinAccount() {
  const [isFormVisible, setFormVisible] = useState(true);
  const [data, setdata] = useState({
    startdate: "",
    enddate: ""
  });
  const [report, setreport] = useState([]);
  const [showtable, setshowtable] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [userid, setuserid] = useState(null);
  // const [branchtablename, setbranchtable] = useState(null);
  const [invoiceData, setInvoiceData] = useState('');
  const [sendResp, setSendResp] = useState('');
  const [sendResp1, setSendResp1] = useState('');
  const selector = useSelector(state => state);
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const branchtablename=selector && selector.details && selector.details.data && selector.details.data[""] && selector.details.data[""][0].branchtable
  const [dopdatequery, setdopdatequery] = useState({
    startdop: formattedDate,
    enddop: "-1"
  });

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleViewInvoice = (rowData,user_id) => {

    if (selector.details.data != null) {
      const branchtable = selector.details.data[""][0].branchtable;
      console.log(branchtable)
      console.log(user_id)
    const encryptdata = `${sessionStorage.getItem("Apipathurl")}spainvoiceapp?${Aesencryption(`branchtable=${branchtable}&user_id=${user_id}`)}`;
    console.log(encryptdata);
  }
  setuserid(user_id)

  };
  function maskContact(contact) {
    if (contact.length < 4) {
      return contact;
    }
    const lastFourDigits = contact.slice(-4);
    const maskedPart = '*'.repeat(contact.length - 4);
    return maskedPart + lastFourDigits;
  }

  const handlecurrentdata = async () => {
    setLoading(true);
    if (selector.details.data != null) {
      const spabranchname=selector.details.data && selector.details.data[""][0].
      spa_branch_name
      const branchtable =selector.details.data && selector.details.data[""][0].branchtable
      if( spabranchname !==null && branchtable !==null){
        const obj = {
          query: `[dbo].[spasoftware_web_proc_viewwalkinaccount_showreport]@branchtable='{0}',@branchname='{1}',@startdate='{2}',@enddate='{3}'`,
          queryArr: [`${branchtable}`, `${spabranchname}`, `${dopdatequery.startdop}`, `${dopdatequery.enddop}`]
        };
        const getdata = await Hexapi(obj);
        setreport(getdata.walkindata || []);
        setLoading(false);
      }

    }
  }

  useEffect(() => {
    if (selector.details.data != null) {
      handlecurrentdata()}


  }, [selector, dopdatequery]);

  const handleclear = async (cancelid) => {
    if (selector.details.data != null) {
      const branchtable =selector.details.data && selector.details.data[""][0].branchtable
      if( branchtable !==null){
    const obj = {
      query: `[dbo].[spa_software_web_proc_walking_cancel_query]@cancelid='{0}',@branchtable='{1}'`,
      queryArr: [`${cancelid}`, `${branchtable}`]
    };
    const canceldata = await Hexapi(obj);
    toast.success(canceldata[""][0].msg, {
      duration: 2000,
      position: 'top-center',
    });
    handlecurrentdata()
  }}
  };

  const handleChangeReport = (date, name) => {
    if(date){

      setdata({ ...data, [name]: date });
    }else{
      setdata({ ...data, [name]: "" });
    }
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setdata({ ...data, [name]: new Date() });
    }
  };

  function convertDateFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{formatDate(row.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
    { Header: "Walking ID", accessor: "member_id" },
    { Header: "Time In", accessor: (row) => row.time_in ===null ? "": row.time_in},
    { Header: "Time Out", accessor: (row) => row.time_out ===null ? "" : row.time_out},
    {
      Header: "Customer Name",
      accessor: "cust_name",

    },
    { Header: "Customer Number", accessor: (row) => maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Created By", accessor: "create_by" },
    {
      Header: "View Service",
      accessor: "booking",
      Cell: ({ row }) => (
        <>
          {row.original.booking_status === 1 ? (
            <>
             <Link target="_blank" rel="noopener noreferrer" to={`/viewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>service</Link>
              {row.original.cust_dop === formattedDate ? (
                <>
                  <button className="btn-click-3" onClick={() => handleclear(row.original.user_id)}>Cancel</button>
                  <Link className='btn-click-2'   target="_blank" rel="noopener noreferrer" to={`/invoice/${encodeURIComponent(encryptData(branchtablename))}/${encodeURIComponent(encryptData(row.original.user_id))}`} >View Invoice</Link>
                </>
              ) : null}
            </>
          ) : (
            <Link target="_blank" rel="noopener noreferrer" className="btn-click-4" onClick={handleclear}>cancel</Link>
          )}
        </>
      )
    },
    {
      Header: "Tip Account",
      accessor: "tip",
      Cell: ({ row }) => (
        <>
        {row.original.booking_status===1 ?  <Link target="_blank" rel="noopener noreferrer" to={`/tipaccount/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link> : <button className="btn-click-4">cancel</button>}
        </>
      )
    },
    {
      Header: "Pending Account",
      accessor: "pending",
      Cell: ({ row }) => (
        <>
        {row.original.booking_status===1 ?
          <Link target="_blank" rel="noopener noreferrer" to={`/paidaccount/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Paid</Link> : <button className="btn-click-4">cancel</button>}
          </>
      )
    }
  ], [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
      {!invoiceData && (
        <>
          <div className="quick_search_page">
            <div className="widget-title">
              <h4>
                <i className="icon-reorder"></i> Manager Accounts
              </h4>
              <span className="tools">
                <button id="arrow-button" onClick={toggleFormVisibility}>
                  <IoIosArrowDown />
                </button>
              </span>
            </div>
          </div>
          {isFormVisible && (
            <div className="widget-body form">
              <div name="dname" id="dname1" className="form-horizontal">
                <div className="control-group-div">
                  <div className="control-group">
                    <label className="control-label">Select Start Date</label>
                    <div className="controls">
                      <DatePicker
                        selected={data.startdate}
                        onChange={(date) => handleChangeReport(date, 'startdate')}
                        className="span6 popovers"
                        dateFormat="dd/MM/yyyy"
                        onFocus={() => setCurrentDateIfEmpty('startdate')}
                      />
                    </div>
                  </div>
                  <div className="control-group">
                    <label className="control-label">Select End Date</label>
                    <div className="controls">
                      <DatePicker
                        selected={data.enddate}
                        onChange={(date) => handleChangeReport(date, 'enddate')}
                        className="span6 popovers"
                        dateFormat="dd/MM/yyyy"
                        onFocus={() => setCurrentDateIfEmpty('enddate')}
                      />
                    </div>
                  </div>
                  <div className="form-actions-control">
                    <button
                      type="submit"
                      name="ShowInfoBtn"
                      className="btn btn-success mb-3"
                      id="show_btn"
                      onClick={() => {
                        setdopdatequery({
                          startdop: convertDateFormat(data.startdate),
                          enddop: convertDateFormat(data.enddate)
                        });
                      }}
                    >
                      Show Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <div className="row bg-body-tertiary mt-4">
              <div className="dashservice">
                <p className="name-text">Walkin Accounts</p>
                <FaChevronDown onClick={() => setshowtable(!showtable)} />
              </div>
              <div>
                {showtable && (
                  <>

                     <div>
                     <div className="d-flex justify-content-between mt-2">
                       <select
                         value={itemsPerPage}
                         onChange={(e) => {
                           setItemsPerPage(Number(e.target.value));
                           setPageSize(Number(e.target.value));
                         }}
                       >
                         {[10, 25, 50, 75, 100].map((size) => (
                           <option key={size} value={size}>
                             Show {size}
                           </option>
                         ))}
                       </select>
                       <input
                         type="text"
                         value={globalFilter || ""}
                         onChange={e => setGlobalFilter(e.target.value)}
                         placeholder="Search..."
                       />
                     </div>
                     <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                       <thead>
                         {headerGroups.map(headerGroup => (
                           <tr {...headerGroup.getHeaderGroupProps()}>
                             {headerGroup.headers.map(column => (
                               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                 {column.render("Header")}
                                 <span>
                                   {column.isSorted
                                     ? column.isSortedDesc
                                       ? " 🔽"
                                       : " 🔼"
                                     : ""}
                                 </span>
                               </th>
                             ))}
                           </tr>
                         ))}
                       </thead>
                       <tbody {...getTableBodyProps()}>
                         {loading ? (
                           <tr>
                          <td colSpan={18} className="text-center">
                          <Spinner animation="border" />
                        </td>
                           </tr>
                         ) : report.length === 0 ? (
                           <tr>
                             <td className="fs-6" colSpan={18}>No Data Available</td>
                           </tr>
                         ) : (
                           page.map(row => {
                             prepareRow(row);
                             return (
                               <tr {...row.getRowProps()}>
                                 {row.cells.map(cell => (
                                   <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                 ))}
                               </tr>
                             );
                           })
                         )}
                       </tbody>
                     </Table>
                     <div className="d-flex justify-content-between mb-3">
                     <span>
                 Showing {startIndex}-{endIndex} of {totalEntries} entries
                 {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
               </span>
                       <div className="pagination">
                         <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                           Previous
                         </button>
                         <span>
                           Page{' '}
                           <strong>
                             {pageIndex + 1} of {pageOptions.length}
                           </strong>{' '}
                         </span>
                         <button onClick={() => nextPage()} disabled={!canNextPage}>
                           Next
                         </button>
                       </div>
                     </div>
                   </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {invoiceData && <WalkinInvoice userId={userid} invoiceData={invoiceData} setback={() => setInvoiceData(null)} response={sendResp} response1={sendResp1} />}
    </>
  );
}