import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import DatePicker from "react-datepicker";
import { default as Select } from 'react-select';
import ModuleMembership from "./ModuleMembership";
import ModuleWalkin from "./ModuleWalkin";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { decryptData } from "../cryptoutils/cryptoutils";
import Swal from "sweetalert2";


const UpdateService = () => {
  const navigate=useNavigate()
  const{userid}=useParams()
  const user_id=decryptData(userid)
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

    const[show,setshow]=useState(true)
    const[walkinshow,setwalkinshow]=useState(false)
    const[showw,setshoww]=useState(false)
    const[branchname,setbranchname]=useState(null)
    const [data, setdata] = useState({
        dop_date: "",
        member_id:"",
        customer_name: "",
        customer_no: "",
        customer_service:"",
        reference_name:"",
        payment_mode:"",
        paid_amount:"",
        balance_account:"",
        total_amount:"",
        used_service:"",
        total_service:"",
        balservice:"",
        groupon:"",
        branchgetname:""
      })

const handleclear=()=>{
  setdata({
    dop_date: "",
        member_id:"",
        customer_name: "",
        customer_no: "",
        customer_service:"",
        reference_name:"",
        payment_mode:"",
        paid_amount:"",
        balance_account:"",
        total_amount:"",
        used_service:"",
        total_service:"",
        balservice:"",
        groupon:"",
        branchgetname:""
  })
}
    const[branchdata,setbranchnamedata]=useState([])
    const[pay,setpay]=useState([])
    const[ref,setref]=useState([])
    const[managerid,setmanagerid]=useState([])
    const fetchdata=async()=>{
        const obj={
          query:`[dbo].[spasoftware_web_proc_module_membership_edit_getdata]@userid='{0}'`,
          queryArr:[`${user_id}`]
        }
        const getdata=await Hexapi(obj)
        console.log(getdata,"previous data")
        const prevdata=getdata.prev[0]
        console.log(getdata.prev[0].refname,"refname")
      // setpay(getdata.paymentmode || [])
      setref(getdata.therapistname || [])
      setmanagerid(getdata.managerid || [])
      setbranchnamedata(getdata.branch || [])
        setdata({
          dop_date: prevdata.cust_dop,
          member_id:prevdata.member_id,
          customer_name: prevdata.cust_name,
          customer_no: prevdata.cust_contact,
          customer_service:prevdata.cust_type,
          reference_name:prevdata.refname,
          payment_mode:prevdata.payment_mode,
          paid_amount:prevdata.cust_paid_amount,
          balance_account:prevdata.cust_bal_amount,
          total_amount:prevdata.cust_total_amount,
          total_service:prevdata.cust_total_service,
          balservice:prevdata.cust_bal_service,
          used_service:prevdata.cust_used_service,
          branchgetname:prevdata.branch_name
        })
        setbranchname(data.branchgetname)
          }
useEffect(()=>{
    fetchdata()
},[])

const paymentfunction=async()=>{
  const obj = {
    query: `[dbo].[spasoftware_web_proc_createservice_servicefeild_onchange]@value='{0}'`,
    queryArr: [`${data.customer_service}`]
  }
  const datapost = await Hexapi(obj)
  setpay(datapost.paymentmode || [])
}
useEffect(()=>{
  paymentfunction()
},[data.customer_service])

  const handlechange = (e) => {
    const { name, value } = e.target;
    console.log(value)
    setdata({ ...data, [name]: value });
    console.log(data,"data")
    console.log(e,"e")
  }


  const handle_amount = (e) => {
    const paidamount = e.target.value

    setdata((prev) => ({
      ...prev,
    paid_amount:paidamount,
    balance_account:data.total_amount-paidamount
    })
    )
  }
  const handleupdateservice=async(e)=>{
   e.preventDefault()
   if(user_id ===""){
   Swal.fire({
  icon:"warning",
  title:"Missing information",
  html:"UserID is Required"
  })
   return;
   }
   if(data.dop_date ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Date is Required"
      })
       return;
   }
   if(data.customer_name ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Customer Name is Required"
      })
      return;
   }
   if(data.customer_no ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Customer Number is Required"
      })
       return;
   }
   if(data.payment_mode ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Payment Mode is Required"
      })
       return;
   }
   if(data.total_service ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Total Service Hour is Required"
      })
       return;
   }

   if(data.branchgetname ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Branch Name is Required"
      })
       return;
   }
   if(data.customer_service ==""){
    Swal.fire({
      icon:"warning",
      title:"Missing information",
      html:"Customer Service is Required"
      })
       return;
   }
   
   const obj={
    query:`[dbo].[spasoftware_web_proc_membership_updateservice]@userid='{0}',@dop_date='{1}',@branch_name='{2}',
    @customer_name='{3}',@customer_no='{4}', @customer_service='{5}', @reference='{6}', @payment_mode='{7}',@security_code='{8}',@total_amount='{9}', @paid_amount='{10}', @balance_amount='{11}',@total_service='{12}'
    , @used_service='{13}',@bal_service='{14}'`,
    queryArr:[`${user_id}`,`${data.dop_date}`,`${data.branchgetname}`,`${data.customer_name}`,`${data.customer_no}`,`${data.customer_service}`,`${data.reference_name}`,`${data.payment_mode}`,`${data.groupon}`,`${data.total_amount}`,`${data.paid_amount}`,`${data.balance_account}`,`${data.total_service}`,`${data.used_service}`,`${data.balservice}`,]
   }
   const postdata=await Hexapi(obj)
   console.log(postdata,"updated value")

    window.scrollTo(0, 0);

   toast.success("successfully updated")

   if(data.customer_service==='walking'){
    setbranchname(data.branchgetname)
   navigate("/walkin_account_view")

   }
   if(data.customer_service==='membership'){
    setbranchname(data.branchgetname)
   navigate("/membership_account_view")
   }

  }

  const handleChangeReport = (date, name) => {
    setdata({ ...data, [name]: date.toISOString().split('T')[0] });
  };
  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setdata({ ...data, [name]: new Date().toISOString().split('T')[0] });
    }
  };


const paymentOptions = pay.map(th => ({
  value: th.paymentmode,
  label: th.paymentmode
}));
  const handlepaymentChange = (selectedOption) => {
    setdata({ ...data, payment_mode: selectedOption ? selectedOption.value : '' });
  };

  const therapistOptions = ref.map(therapist => ({
    value: therapist.therapist_name,
    label: therapist.therapist_name
  }));
  managerid.forEach(ele => {
    therapistOptions.push({
      label: ele.manager_userid,
      value: ele.manager_userid
    });
  });
    const handleTherapistChange = (selectedOption) => {
      setdata({ ...data, reference_name: selectedOption ? selectedOption.value : '' });
    };

  const handlebranchChange = (selectedOption) => {
    setdata({ ...data,  branchgetname: selectedOption ? selectedOption.value : '' });
  };
  const branchOptions = branchdata.map(br => ({
    value: br.branchname,
    label: br.branchname
  }))

return (
  <div>
 {
!showw  && !walkinshow && (<div className="container top-acc highlightBcg">
  <div className="row ">
      <div className="dashservice">
        <p className="name-text">
         UPDATE SERVICE
        </p>
        <div className="">
          <FaChevronDown onClick={()=>{setshow(!show)}} className="" />
        </div >
      </div>
      </div>

    <div className="row bg-body-tertiary">
    {show && (
                <form className="form-data" onSubmit={handleupdateservice}>
                  <div className="controls">
                  <div className="control-group">
          <label className="control-label">
          Member ID</label>
            <div className="blinkTextDiv controls">
              <input
                id="dop_date"
                type="text"
                name="member_id"
value={data.member_id}
                disabled
                className="span7  popovers"

              />
              </div>
          </div>
          <div className="control-group">
          <label className="control-label">
              DOP Date</label>
            <div className="blinkTextDiv controls">
            <DatePicker
                  selected={data.dop_date}
                  onChange={(date) => handleChangeReport(date, 'dop_date')}
                  className="span6 popovers"
                 dateFormat="dd/MM/yyyy"
                  onFocus={() => setCurrentDateIfEmpty('dop_date')}
                />
              </div>
          </div>
          <div className="control-group">
  <label className="control-label">
    Branch Name</label>
  <div className="controls">
  <Select
                    className="span6"
                    id="therapist_weekly_off"
                    name="branch"
                    options={branchOptions}
                    value={branchOptions.find(option => option.value === data.branchgetname)}
                    onChange={handlebranchChange}
                    isClearable
                    classNamePrefix="react-select"
                    placeholder="Select Branch Name"
                  />
  </div>
</div>
          <div className="control-group">
            <label className="control-label">
              Customer Name
            </label>
            <div className="controls">
              <input
                type="text"
                 className="span6  popovers"
                name="customer_name"
                value={data.customer_name}
                id="customer_name"
                onChange={handlechange}
              />
            </div>
          </div>


          <div className="control-group">
          <label className="control-label">
              Customer Number
            </label>
            <div className="controls">
              <input
                className="span6  popovers"
                type="number"
                value={data.customer_no}
                name="customer_no"
                id="customer_no"
                required
                onChange={handlechange}
              />
            </div>
          </div>
          <div className="control-group">
          <label className="control-label">
              Customer Service
            </label>
            <div className="controls">
              <input
                className="span6  popovers"

                value={data.customer_service}
                name="customer_service"
                id="customer_no"
                required
                onChange={handlechange}
              />
            </div>
          </div>



<div className="control-group">
  <label className="control-label">
    Reference Name</label>
  <div className="controls">
  <Select
                  className="span6"
                  id="therapist_weekly_off"
                  name="reference_name"
                  options={therapistOptions}
                  value={therapistOptions.find(option => option.value === data.reference_name)}
                  onChange={handleTherapistChange}
                  isClearable
                  classNamePrefix="react-select"
                  placeholder="Select Therapist"
                  required
                />
  </div>
</div>

      <div className="control-group">
      <label className="control-label">
              Payment Mode
            </label>
            <div className="controls">
            <Select
                  className="span6"
                  id="therapist_weekly_off"
                  name="therapistnamee"
                  options={paymentOptions}
                  value={paymentOptions.find(option => option.value === data.payment_mode)}
                  onChange={handlepaymentChange}
                  isClearable
                  classNamePrefix="react-select"
                  placeholder="Select Payment Mode"
                  required
                />
            </div>
          </div>
          <div className="control-group">
          <label className="control-label">
              Groupon Code
            </label>
              <input type="text" id="basic_amount"
                 className="span6  popovers"
               name="groupon"
                value={data.groupon}
               onChange={handlechange}
               required
               />
          </div>
          <div className="control-group">
          <label className="control-label">
              Total Amount</label>
            <div className="controls">
              <input
                type="number"
                name="total_amount"
                id="tax_amount"

                value={data.total_amount}
                 className="span6  popovers"
                 onChange={handlechange}
                 required

              />
            </div>
          </div>

          <div className="control-group">
          <label className="control-label">
              Paid Amount
            </label>
            <div className="controls">
              <input
                type="number"
                 className="span6 popovers"
                name="paid_amount"
                id="total_bill_amount"

                value={data.paid_amount}
                onChange={handle_amount}
                required
              />
            </div>
          </div>

          <div className="control-group">
          <label className="control-label">
              Balance Amount
            </label>
            <div className="controls">
              <input
                type="text"
                 className="span6  popovers"
                name="balance_account"
                id="paid_amount"
                value={data.balance_account}
                onChange={handlechange}
                required
               />
            </div>
          </div>

          <div className="control-group">
          <label className="control-label">
              Total Services
            </label>
            <div className="controls">
              <input
                type="number"
                name="total_service"
                id="word"

                value={data.total_service}
                required
                 className="span6  popovers"
                 onChange={handlechange}

              />
            </div>
          </div>

          <div className="control-group">
          <label className="control-label">
              Used Services
            </label>
            <div className="controls">
              <input
                type="number"
                name="used_service"
                id="balance_amount"
                value={data.used_service}
                 className="span6  popovers"
                 onChange={handlechange}
              />
            </div>
          </div>

          <div className="control-group">
          <label className="control-label">
             Balance Services
            </label>
            <div className="controls">
              <input
                type="number"
                name="balservice"
                id="discount_amount"
                value={data.balservice}
         onChange={handlechange}
                 className="span6  popovers"
                 required

              />
            </div>
          </div>
          <div className="createcancel_btn">
            <button
            type="submit"
            className="btn-data"
            >
             Update
            </button>
            <button type="reset" className="cancel-btn btn" onClick={handleclear}>Cancel</button>
          </div>
          </div>
        </form>)}
    </div>
    </div>)
}
{ showw && <ModuleMembership branchh={branchname}/>}
{ walkinshow && <ModuleWalkin branchh={branchname}/>}

  </div>

);
};

export default UpdateService;