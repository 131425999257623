import React from 'react'
import './page.css'
import { FaUserAlt } from "react-icons/fa";
import { FaTags } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { ImEye } from "react-icons/im";
import { BsFileBarGraphFill } from "react-icons/bs";
import { ImCart } from "react-icons/im";
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';



const Newdashboarb = ({data}) => {
    const [show, setShow] = useState(true);

    console.log(data,"----------------")

  return (
 <div className='container-fluid'>

    <div className='row padd-r'>


   {show ?

      <Alert variant="info" onClose={() => setShow(false)} dismissible  className='welcome-text'>
        <p className='text-left'>WELCOME TO SPA SOFWARE</p>
      </Alert> : null
}
</div>


<div className='row padd-r'>
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-6'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                   <FaUserAlt  className='membership_icon'/>
                </div>
                <Link to='membership_account' className="font-circle-1 hov-cir">
                    <p>{data && data.today_membership_sale_manager[0].today_membership_sale_manager}</p>
                </Link>
                 <Link to='membership_account' className="font-circle  hov-cir"><p>Today Membership</p> </Link>
            </div>

</div>

<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_walkin_content'>
                   <FaTags className='walkin_icon'/>
               </div>
               <Link  to='walkin_account' className="font-circle-1  hov-cir">{data && data.today_walkin_sale_manager[0].walkin_sale_manager}</Link>
               <Link  to='walkin_account'>   <p className="font-circle  hov-cir">Today Walkin</p></Link>

            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_complentary_content'>
                   <TiMessages className='complentary_icon'/>
               </div>
               <Link  to='complentary_account' className="font-circle-1 hov-cir">{data && data.today_complentary_manager[0].today_complentary_manager}</Link>
               <Link  to='complentary_account'> <p className="font-circle  hov-cir">Today Complentary </p> </Link>
            </div>
</div>
<div className=' col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_cash_content'>
                   <ImEye className='cash_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_cash_sale_manager[0].today_cash_sale_manager}</p>
               <p className="font-circle">Today Cash Sale</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_card_content'>
                   <BsFileBarGraphFill className='card_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_card_sale_manager[0].today_card_sale_manager}</p>
               <p className="font-circle">Today Card Sale</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_Groupon_content'>
                   <ImCart className='Groupon_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.grouponsale_manager[0].grouponsale_manager}</p>
               <p className="font-circle">Groupon Sale</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
<div className='stats-circle'>
               <div className='Today_Groupon_content'>
                   <ImCart className='Groupon_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.make_my_trip_manager[0].make_my_trip_manager}</p>
               <p className="font-circle">Make My Trip</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-6'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                   <FaUserAlt  className='membership_icon'/>
                </div>
                <p className="font-circle-1">
                    {data && data.today_total_therapy[0].today_total_therapy}
                </p>
                <p className="font-circle">Total Therapy</p>
            </div>

</div>
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-6'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                <BsFileBarGraphFill className='card_sale_icon'/>
                </div>
                <p className="font-circle-1">
                    {data && data.paytm_sale_manager
[0].paytm_sale_manager
}
                </p>
                <p className="font-circle">Today Paytm Sale</p>
            </div>

</div>
  </div>


 </div>
  )
}

export default Newdashboarb