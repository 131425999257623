import React, { useEffect } from 'react'
import { FaChevronDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import './attendance.css'
import { Form } from 'react-bootstrap';

const Attendance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
 <div className='container'>
  <div className='row topatt'>
 <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>THERAPIST NAME</h4></p>
  <FaChevronDown className='text-new'/>
 </div>

<div>
  <Form>
   <div className='form-data mt-3'>
    <Form.Label>
     Therapist Name
    </Form.Label>
    <Form.Select aria-label="Default select example">
      <option>Select therapist Name</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
    </div>
   <hr/>
    <div className=' d-flex gap-3 py-2'>
      <button className='btn-1'>Present</button>
      <button className='btn-click-1'>Cancel</button>
    </div>
  </Form>
</div>
  </div>

 </div>
  )
}

export default Attendance