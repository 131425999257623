import React, { useEffect, useState } from 'react'
import { MdMarkEmailRead } from "react-icons/md";
import '../components/Navbar.css'
import Accordion from 'react-bootstrap/Accordion';
import './page.css'
import { Link } from 'react-router-dom';
import { BiSolidNotepad } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackOutline } from "react-icons/io5";
import { PiCalendarStarFill } from "react-icons/pi";
import { BiSolidCalendarCheck } from "react-icons/bi";
import { VscFileSubmodule } from "react-icons/vsc";
import { FaUsersViewfinder } from "react-icons/fa6";
import { IoBagAddSharp } from "react-icons/io5";
import { AiFillDashboard } from "react-icons/ai";
import { MdAccountBox } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { FaSpa } from "react-icons/fa6";
import { SiCoinmarketcap, SiTruenas } from "react-icons/si";
import { MdLibraryBooks } from "react-icons/md";
// import { FaDailymotion } from "react-icons/fa6";
// import { FaBackspace } from 'react-icons/fa';
import { IoLogOutSharp } from "react-icons/io5";

import { MdCardMembership } from "react-icons/md";
import { useSelector } from 'react-redux';
const Sidebardata = ({click}) => {
    // console.log(click,"----------")
    const[show ,setshow]=useState(true)
    const[testdata,settestdata]=useState(null)
   const selector= useSelector(state=>state)
   const[loading,setLoading]=useState(true)
    // const[testdata,settestdata]=useState("manager")
//    const testdata="MANAGER"

   useEffect(() => {
    if (selector.details.data!=null) {
      const userrol= selector.details.data[""][0].user_role
      settestdata(userrol)
      console.log(userrol,"---------")
    } else {
      setLoading(false)
    }
  }, [selector,testdata])
  const handleclicks=()=>{
    setshow(false)
  }

  const handlelogout=()=>{
    sessionStorage.removeItem('Apipathurl')
    window.location.href="https://bagero.hexbss.xyz/login"
  }
  return (<>
    { testdata !=null ?  (testdata==="MANAGER" ? <div>
        {
            show ?
           <div className='btntop'>
             <div className='itemin'>
             <li className=' btn-add d-flex justify-content-end'><button className='btndata' onClick={handleclicks}><IoIosArrowForward className=' new-btn' /></button></li>
            <ul>
                 <li>
                    <Link to="" className='dis-icon-1 '>
                            <p className='icon-size' ><AiFillDashboard  className='icon-dis-new'/></p>
                        <p className='dis-p'>Dashboard</p>
                            </Link>
                        </li>
                 <li>
                    <Link to="attendance" className='dis-icon-1 '>
                            <p className='icon-size' ><BiSolidNotepad  className='icon-dis-new'/></p>
                        <p className='dis-p'>Attendance</p>
                            </Link>
                        </li>
                <li>
                    <Link to="quicksearch" className='dis-icon-1 '>
                     <p className='icon-size' ><IoSearch  className='icon-dis-new'/></p>
                <p className='text-font'>Quick Search</p>
                    </Link>
                </li>
                <li>
                <Link to="appointment" className='dis-icon-1 '>
                    {/*  <p className='icon-size' >< FcCalendar className='icon-dis-new'/></p> */}
                     <p className='icon-size' ><BiSolidCalendarCheck  className='icon-dis-new'/></p>
                <p className='text-font'>Appointment</p>
                    </Link>
                </li>
                <li>
                <Link to="priorappointment" className='dis-icon-1 '>
                     <p className='icon-size' ><PiCalendarStarFill  className='icon-dis-new'/></p>
                <p className='text-font'>Prior Appointment</p>
                    </Link>
                </li>

                <li>
                <Link to="addtherapist" className='dis-icon-1 '>
                     <p className='icon-size' ><IoBagAddSharp  className='icon-dis-new'/></p>
                    {/*  <p className='icon-size' ><BsPersonFillAdd   className='icon-dis-new'/></p> */}
                <p className='text-font'>Add Therapist</p>
                    </Link>
                </li>
                <li>
                <Link to="mobileverification" className='dis-icon-1 '>
                    <p className='icon-size'><MdOutlineMobileFriendly className='icon-dis-new'/></p>
                <p className='text-font'>Mobile Verification</p>
                    </Link>
                </li>
            </ul>
           <div>
           <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="1" className=''>
        <Link >
            <Accordion.Header className=' text-white'><p className='icon-size'> <MdAccountBox  className='icon-dis-new'/></p> <span className='  text-font new-font-data'>Create Account</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul>
                    <li>
                   <Link  to='createaccount'>Create New Account
</Link>                     </li>
                </ul>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" >
          <Link>
            <Accordion.Header className=' text-white'> <p className='icon-size'> <FaUsersViewfinder className='icon-dis-new'/></p> <span className=' text-font new-font-data'>View Account</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul className='ultype'>
                <li><Link to='membership_account'>Membership Account</Link></li>
                <li><Link to='walkin_account'>Walkin Account</Link></li>
                <li><Link to='complentary_account'>Complentary Account</Link></li>


                </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
          <Link >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <FaSpa className='icon-dis-new'/> </p><span className=' text-font new-font-data'>SPA Master</span></Accordion.Header></Link>
            <Accordion.Body>
            <ul className='ultype'>
            <li><Link to='allmembership'>All Membership Account</Link></li>
            <li><Link to='therapistreport'>Therapist Report</Link></li>
                </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
          <Link  >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <SiCoinmarketcap className='icon-dis-new'/> </p><span className=' text-font new-font-data'>SPA Marketing</span></Accordion.Header></Link>
            <Accordion.Body>
                <ul>
                <li><Link to='spamarketing'> Search by Balance</Link></li>
                </ul>

            </Accordion.Body>
          </Accordion.Item>


        </Accordion>
        <div className='itemin '>
            <ul>

                <li>
                <Link to="dailysalebook" className='dis-icon-1 '>
                    {/*  <p className='icon-size' ><FaDailymotion  className='icon-dis-new'/></p> */}
                     <p className='icon-size' ><MdLibraryBooks className='icon-dis-new'/></p>
                <p className='text-font'>Daily Sale Book</p>
                    </Link>
                </li>
                <li>
                <Link to="membership" className='dis-icon-1 '>
                     <p className='icon-size' ><MdCardMembership  className='icon-dis-new'/></p>
                <p className='text-font'>Membership Ref.</p>
                    </Link>
                </li>
                <li>
                <Link to="logout" className='dis-icon-1 '>
                     <p className='icon-size' ><IoLogOutSharp  className='icon-dis-new'/></p>
                <p className='text-font' onClick={handlelogout}>Logout</p>
                    </Link>
                </li>
            </ul>
           <div>
           </div>
           </div>
           </div>
           </div>
           </div> : <div className='sidedata'>
           <li className=' d-flex justify-content-end'><button className='btndata-2' onClick={()=>setshow(true)}><IoChevronBackOutline  className='new-btn'/></button></li>
           </div>
        }
    </div> : null ): null}


    { testdata !=null ? (testdata==="SUB ADMIN" ? <div>
        {
            show ?
           <div className='btntop'>
             <div className='itemin'>
             <li className=' btn-add d-flex justify-content-end'><button className='btndata'  onClick={handleclicks}><IoIosArrowForward className=' new-btn' /></button></li>
            <ul>
                 <li>
                    <Link to="" className='dis-icon-1 '>
                            <p className='icon-size' ><AiFillDashboard  className='icon-dis-new'/></p>
                        <p className='dis-p'>Dashboard</p>
                            </Link>
                        </li>

                <li>
                    <Link to="advancesearchsubadmin" className='dis-icon-1 '>
                     <p className='icon-size' ><IoSearch  className='icon-dis-new'/></p>
                <p className='text-font'>Advance Search</p>
                    </Link>
                </li>

                <li>
                    <Link to="quicksearchsubadmin" className='dis-icon-1 '>
                     <p className='icon-size' ><IoSearch  className='icon-dis-new'/></p>
                <p className='text-font'>Quick Search</p>
                    </Link>
                </li>
            </ul>
           <div>
           <Accordion defaultActiveKey="0" flush>


          <Accordion.Item eventKey="5">
          <Link  >
            <Accordion.Header className=' text-white'> <p className='icon-size'> <TbReport className='icon-dis-new'/> </p><span className=' text-font new-font-data'>Report Master</span></Accordion.Header></Link>
            <Accordion.Body>
             <ul>
             <li><Link to='salereport'>Sale Report</Link></li>
             <li><Link to='saleformat'>Sale Report Format 2</Link></li>
             <li><Link to='totalsale'>Total Sale Report</Link></li>
             <li><Link to='nearbysale'>Nearby Sale Report</Link></li>
             <li><Link to='membershipref'>Membership Ref Report</Link></li>
             <li><Link to='dailyservicebook'>Daily Service Book</Link></li>
             </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
          <Link>
            <Accordion.Header className=' text-white'><p className='icon-size'>  <VscFileSubmodule className='icon-dis-new'/></p> <span className=' new-font-data'>Modules</span></Accordion.Header></Link>
            <Accordion.Body>
             <ul>
             <li><Link to='membership_account_view'>View Membership Account</Link></li>
                <li><Link to='walkin_account_view'>View Walkin Account</Link></li>
                <li><Link to='complentary_account_view'>View Complentary Account</Link></li>
             </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className='itemin '>
            <ul>


                <li>
                <Link to="logout" className='dis-icon-1 '>
                     <p className='icon-size' ><IoLogOutSharp  className='icon-dis-new'/></p>
                <p className='text-font' onClick={handlelogout}>Logout</p>
                    </Link>
                </li>
            </ul>
           <div>
           </div>
           </div>
           </div>
           </div>
           </div> : <div className='sidedata'>
           <li className=' d-flex justify-content-end'><button className='btndata-2' onClick={()=>setshow(true)}><IoChevronBackOutline  className='new-btn'/></button></li>
           </div>
        }
    </div> : null ): null}



    {testdata==="user"?  <div>
        {
            show ?
           <div className='btntop'>
             <div className='itemin'>
             <li className=' btn-add d-flex justify-content-end'><button className='btndata' onClick={()=>setshow(false)}><IoIosArrowForward /></button></li>

        <div className='itemin '>
            <ul>


                <li>
                <Link to="logout" className='dis-icon-1 '>
                     <p className='icon-size' ><MdMarkEmailRead className='icon-dis-new'/></p>
                <p className='text-font' onClick={handlelogout} >Logout</p>
                    </Link>
                </li>
            </ul>
           <div>
           </div>
           </div>
           </div>
           </div>
           : <div className='sidedata'>
           <li className=' d-flex justify-content-end'><button className='btndata' onClick={()=>setshow(true)}><IoChevronBackOutline /></button></li>
           </div>
        }
    </div>: null}




    </>

  )
}

export default Sidebardata