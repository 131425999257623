import React, { useEffect, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import Hexapi from '../usefulcomps/HexAPI/Hexapi'
import { useSelector } from 'react-redux'
import {IoMdArrowRoundBack } from 'react-icons/io';
import { lineSpinner } from 'ldrs';
import toast from 'react-hot-toast'
import DatePicker from "react-datepicker";
import { decryptData } from '../cryptoutils/cryptoutils';
import { useNavigate, useParams } from 'react-router-dom';
lineSpinner.register();

 const Followup = () => {

  const{userid,memberid}=useParams()
  const userId=decryptData(userid)
  const memberId=decryptData(memberid)
  const navigate=useNavigate()
  const[cust,setcust]=useState({
    custname:"",
    custcontact:""
  })
  const datafollowget=async()=>{
    console.log(memberId,userId,"here outside")
    try {
      if(memberId !==undefined && userId !==undefined){
        console.log(memberId,userId,"here")
        const obj={
          query:`[dbo].[spasoftware_web_proc_spamarketing_followup_getdata]@memberid='{0}',@userid='{1}'`,
          queryArr:[`${memberId}`,`${userId}`]
      }
      const getdata= await Hexapi(obj)
      console.log(getdata,"data")
    
      setcust((prev)=>({
        ...prev,
        custname:getdata?.followdata[0]?.cust_name,
        custcontact:getdata?.followdata[0]?.cust_contact
      }))
      }
     
    } catch (error) {
      console.log(error)
    }
  
  }

  useEffect(()=>{
    datafollowget()
  },[userId,memberId])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [visible,setvisible]=useState(true)
    const[loading,setloading]=useState(false)
    const[countdata,setcountdata]=useState("")
    const[data,setdata]=useState({
        followdate:"",
        message:""
    })
    const[datafollow,setdatafollow]=useState({
        followdateupdate:"",
        messageupdate:""
    })
    const selector=useSelector(state=>state)
    const handlefollowdata=async()=>{
        const obj={
            query:`[dbo].[spasoftware_web_proc_spamarketing_followup]@memberid='{0}'`,
            queryArr:[`${memberId}`]
        }
        const getdata= await Hexapi(obj)
        console.log(getdata,"followup")
        setcountdata(getdata.countdata[0].count)

if(getdata.countdata && getdata.countdata[0].count>0){
  setdatafollow({
    followdateupdate: getdata[""][0].followup_date.date.split(' ')[0] ,
    messageupdate:getdata[""][0].message
})
}

    }
const handlechange=(e)=>{
    const{name,value}=e.target
    setdata({...data,[name]:value})
}
const handlefollowchange=(e)=>{
    const{name,value}=e.target
    setdatafollow({...datafollow,[name]:value})
}
const followinsert=async(e)=>{
e.preventDefault()
if(selector.details.data){
    const managername=selector.details.data[""][0].manager_name
    const obj={
        query:`[dbo].[spasoftware_web_proc_followup_insertdata]@userid='{0}',@managername='{1}',@followdate='{2}',@message='{3}'`,
        queryArr:[`${userId}`,`${managername}`,`${data.followdate}`,`${data.message}`,]
    }
    const postdata =await Hexapi(obj)
    console.log(postdata)
   navigate('/spamarketing')
   window.scrollTo(0,0)
   toast.success(postdata[""][0]['msg'])
}
    }



    const followupdate=async(e)=>{
        e.preventDefault()
        console.log(datafollow,"------------")
        if(selector.details.data){
            const managername=selector.details.data[""][0].manager_name
            const obj={
                query:`[dbo].[spasoftware_web_proc_followup_updatedata]@userid='{0}',@managername='{1}',@followdate='{2}',@message='{3}'`,
                queryArr:[`${userId}`,`${managername}`,`${datafollow.followdateupdate}`,`${datafollow.messageupdate}`,]
            }
            const postdata =await Hexapi(obj)
            console.log(postdata)
            navigate('/spamarketing')
            toast.success(postdata[""][0]['msg'])
        }
            }
    useEffect(()=>{
           handlefollowdata()
    },[])



    const handleChangeReport = (date, name) => {
      if(date){
       setdatafollow({ ...datafollow, [name]: date.toISOString().split('T')[0] });
      }else{
        setdatafollow({ ...datafollow, [name]: "" });
      }
     };

     const setCurrentDateIfEmpty = (name) => {
       if (!data[name]) {
        setdatafollow({ ...datafollow, [name]: new Date().toISOString().split('T')[0] });
       }
     };
    const handleChangeReport2 = (date, name) => {
      if(date){
       setdata({ ...data, [name]: date.toISOString().split('T')[0] });
      }else{
        setdata({ ...data, [name]: "" });
      }
     };

     const setCurrentDateIfEmpty2 = (name) => {
       if (!data[name]) {
        setdata({ ...data, [name]: new Date().toISOString().split('T')[0] });
       }
     };
  return (
    <div>
    { loading ? <div className="text-center mt-5"><l-line-spinner
     size="40"
     stroke="3"
     speed="1"
     color="black"
     className="relative"

   ></l-line-spinner></div> : (   <div className='container'>
    <div className='row topatt'>
   <div className='dashservice'>
    <p className=' d-flex'><h4 className='name-text'>Marketing Followup</h4></p>
    <FaChevronDown className='text-new' onClick={()=>{setvisible(!visible)}}/>
   </div>
  {visible && (
  <div>
  <form  className="form-data">
                      <div className="controls">

              <div className="control-group">
                <label className="control-label">
                  Customer Name
                </label>
                <div className="controls">
                  <input
                    type="text"
                     className="span7  popovers"
                    name="customer_name"

                    id="customer_name"
                    disabled
                    value={cust.custname}

                  />
                </div>
              </div>

              <div className="control-group">
              <label className="control-label">
                  Customer Number
                </label>
                <div className="controls">
                  <input
                    type="text"
                     className="span7  popovers"
                    name="customer_address"
                    id="customer_address"
                    disabled
                    value={cust.custcontact}
                  />
                </div>
              </div>
              <hr/>

       { countdata >0 ? (<>          <div className="control-group">
              <label className="control-label">
                 Follow Up Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={datafollow.followdateupdate}
                    onChange={(date) => handleChangeReport(date, 'followdateupdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('followdateupdate')}
                  />
                  </div>
              </div>
              <div className="control-group">
              <label className="control-label">
                  Message</label>
                <div className="blinkTextDiv controls">
                  <textarea rows={7} className='span6'name='messageupdate' value={datafollow.messageupdate} onChange={handlefollowchange}></textarea>

                  </div>
              </div>
              <hr/>

              <div className='newshow mb-3'>
        <button className='btn-1' type='submit' onClick={followupdate}>Update</button>
        <button className='btn-click-1'>Cancel</button>
      </div></>):(<><div className="control-group">
              <label className="control-label">
                 Follow Up Date</label>
                <div className="blinkTextDiv controls">
                <DatePicker
                    selected={data.followdate}
                    onChange={(date) => handleChangeReport2(date, 'followdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty2('followdate')}
                  />
                  </div>
              </div>
              <div className="control-group">
              <label className="control-label">
                  Message</label>
                <div className="blinkTextDiv controls">
                  <textarea rows={5} className='span6' name='message' value={data.message} onChange={handlechange}></textarea>

                  </div>
              </div>
              <hr/>

              <div className='newshow mb-3'>
        <button className='btn-1' type='submit' onClick={followinsert}>Create</button>
        <button className='btn-click-1'>Cancel</button>
      </div></>) }

              </div>
              </form>
  </div>)}
  </div>
  </div>)}
   </div>


  )
}

export default Followup
