import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IoIosArrowDown } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { lineSpinner } from 'ldrs';
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import DatePicker from "react-datepicker";
import { Spinner, Table } from "react-bootstrap";
import { formatDate, maskContact } from "../customfunction/dateformatmonth";
lineSpinner.register();

export default function DailySaleBook() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    startdate: null,
    enddate: null
  });

  const date = new Date();
  const formattedDate = date.toISOString().split('T')[0];

  const [dopdatequery, setDopdatequery] = useState({
    startdopdate: formattedDate,
    enddopdate: "-1"
  });

  const [report, setReport] = useState([]);
  const [filterText, setFilterText] = useState('');
  const selector = useSelector(state => state);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleChangeReport = (date, name) => {
    setData({ ...data, [name]: date });
  };

  const setCurrentDateIfEmpty = (name) => {
    if (!data[name]) {
      setData({ ...data, [name]: new Date() });
    }
  };

  const handleReport = async () => {
    setLoading(true);
    if (selector.details.data) {
      const spa_branch_name = selector.details.data[""][0].spa_branch_name;
      const obj = {
        query: `[dbo].[spasoftware_web_proc_dailybook_getdata]@branchname='{0}',@startdate='{1}',@enddate='{2}'`,
        queryArr: [`${spa_branch_name}`, `${dopdatequery.startdopdate}`, `${dopdatequery.enddopdate}`]
      };
      const getData = await Hexapi(obj);
      setReport(getData[""] || []);
      setLoading(false);
      console.log(getData, "dailybook");
    }
  };

  useEffect(() => {
    handleReport();
  }, [dopdatequery]);


  const exportToExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const formattedData = report.map((item,i) => ({
      'sr no': i+1,
      'Date': item.service_date.date.split(' ')[0],
      'Customer Name': item.cust_name,
      'Customer Type': item.cust_type,
      'Customer ID':item.member_id
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = 'Membership_Reference_Report' + fileExtension;
    saveAs(data, fileName);
  };

  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "Date", accessor:"service_date.date" ,Cell:({row})=> row.original.service_date.date.split(' ')[0]},
    { Header: "Customer Name", accessor: "cust_name" },
    { Header: "Customer Type", accessor:"cust_type" ,Cell:({row})=>(<>{row.original.cust_type} <br/> {row.original.payment_mode === 'Complimentary' ? row.original.payment_mode : row.original.payment_mode}</>) },
    { Header: "Customer ID", accessor: "member_id"},
    { Header: "Customer Number", accessor:"cust_contact",Cell: ({row}) => maskContact(row.original.cust_contact) },
    { Header: "Therapist Name", accessor: "therapist_name" },
    { Header: "Amount", accessor: "cust_total_amount" },
    { Header: "Room no", accessor: "room_no" },
    { Header: "Time-In", accessor: "time_in" },
    { Header: "Time-Out", accessor: "time_out" },
    { Header: "Hour", accessor: "treatment_hr" },
    { Header: "Create By", accessor: "create_by",Cell:({row})=>(<>{row.original.create_by}  {row.original.service_status === 1 ? <span className="text-info">Confirm</span> : ''}</>) },
  ], []);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);

  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);




  return (
    <div>
      <div className="quick_search_page">
        <div className="widget-title">
          <h4>
           Select Date Range
          </h4>
              <FaChevronDown onClick={toggleFormVisibility} style={{marginTop:"4px", marginRight:"4x"}} />

        </div>
      </div>
      {isFormVisible && (
        <div className="widget-body form">
          <div
            name="dname"
            id="dname1"
            className="form-horizontal"
          >
            <div className="control-group-div">
              <div className="control-group">
                <label className="control-label">Select Start Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.startdate}
                    onChange={(date) => handleChangeReport(date, 'startdate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('startdate')}
                  />
                </div>
              </div>
              <div className="control-group">
                <label className="control-label">Select End Date</label>
                <div className="controls">
                  <DatePicker
                    selected={data.enddate}
                    onChange={(date) => handleChangeReport(date, 'enddate')}
                    className="span6 popovers"
                    dateFormat="dd/MM/yyyy"
                    onFocus={() => setCurrentDateIfEmpty('enddate')}
                  />
                </div>
              </div>
              <div className="form-actions-control">
                <button
                  type="submit"
                  name="ShowInfoBtn"
                  className="btn btn-success mb-3"
                  id="show_btn"
                  onClick={() => setDopdatequery({
                    startdopdate: data.startdate ? data.startdate.toISOString().split('T')[0] : "",
                    enddopdate: data.enddate ? data.enddate.toISOString().split('T')[0] : ""
                  })}
                >
                  Show Report
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row bg-body-tertiary mt-4">
          <div className="dashservice">
            <p className="name-text fs-6">
              <span className="text-danger">{selector.details.data != null ? selector.details.data[""][0].spa_branch_name : null}</span>
              ||
              <span className="text-info">Start From {data.startdate === null ? null : data.startdate.toISOString().split('T')[0]}</span>
              ||
              <span className="text-success">Start To {data.enddate === null ? null : data.enddate.toISOString().split('T')[0]}</span>
            </p>
            <FaChevronDown onClick={() => { setShowTable(!showTable) }} />
          </div>
          <div>
            {showTable && (
              <div>

                 <div>
                  <div className="d-flex justify-content-between mt-2">
                    <select
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(Number(e.target.value));
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 25, 50, 75, 100].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      value={globalFilter || ""}
                      onChange={e => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {loading ? (
                        <tr>
                       <td colSpan={17} className="text-center">
                       <Spinner animation="border" />
                     </td>
                        </tr>
                      ) : report.length === 0 ? (
                        <tr>
                          <td className="fs-6" colSpan={17}>No Data Available</td>
                        </tr>
                      ) : (
                        page.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                              ))}
                            </tr>
                          );
                        })
                      )}
                    </tbody>

                  </Table>
                  <div className="d-flex justify-content-between mb-3">
                  <span>
              Showing {startIndex}-{endIndex} of {totalEntries} entries
              {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
            </span>
                    <div className="pagination">
                      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <span>
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                      </span>
                      <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                    </div>
                  </div>


{/* <button onClick={exportToExcel} className="btn-click-6">
          Generate Report
        </button> */}


                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
