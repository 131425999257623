import React, { Component, useEffect, useRef, useState } from "react";
import noimg from "../images/noimg.png"
import "./addtherapist.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoReorderThreeOutline } from "react-icons/io5";
// import {DatePicker} from "../usefulcomps/calendar/Calender"
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import Swal from 'sweetalert2';
import { FaChevronDown, FaUserAlt } from "react-icons/fa";
import { FaLeaf ,FaTags} from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import AddTherapistDoc from "./AddTherapistDoc";
import { Link } from "react-router-dom";
import AddTherapistUpdateForm from "./AddTherapistUpdateForm";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default function Addtherapist() {
  const selector = useSelector(state => state)
  // const branch_name = selector.details.data[""][0].spa_branch_name;
  // const parts = branch_name.split(' - ');
  // const branch_code = parts[1];
  // console.log(mgf)
  const fileInputRef = useRef(null);
  const [showtable, setshowtable] = useState(true);
  const [report, setreport] = useState([]);
  const [showUpdForm, setShowUpdForm] = useState(false);
  const [isFormVisible, setFormVisible] = useState(true);
  const [file, setFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(noimg);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [dopDate, setdopDate] = useState('');
  const [dojDate, setdojDate] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [isValueChange, setIsValueChange] = useState({
    therapist_real_name: "",
    therapist_name: "",
    therapist_dob: null,
    therapist_doj: null,
    biometic_id: "",
    therapist_number: "",
    type_of_therapist: "",
    therapist_email: "",
    therapist_weekly_off: "",
    therapist_shift_timing: "",
    type_of_salary: "",
    file: null
  });


  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const getTableData = async() => {
    if (selector.details.data) {
      const branchname = selector.details.data[""][0].spa_branch_name
      let obj = {
        query: `[dbo].[spasoftware_web_proc_addTherapist_getDataTable]@branch_name='{0}'`,
        queryArr: [`${branchname}`]
      }
      const resp = await Hexapi(obj)
      console.log(resp,"-------------")
      setreport([])
        setBranchCode(resp.branchcode[0].branchcode);
        setreport(resp[""]);
    }
  }
  useEffect(() => {
      getTableData();
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return isNaN(date) ? "" : date.toISOString().split('T')[0];
};
  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setIsValueChange({
      ...isValueChange,
      [name]: value
    });
  }
  const handleDateChange = (date, name) => {
    console.log(date)
    console.log(name)
    setIsValueChange({ ...isValueChange, [name]: date });
  };
  const handleDateDobChange = (dojdate, name) => {
    console.log(dojdate)
    console.log(name)
    setIsValueChange({ ...isValueChange, [name]: dojdate });
  };

  const handleFileChange = (e) => {
    // const file = e.target.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setSelectedImage(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    //   setIsValueChange({
    //     ...isValueChange,
    //     file: file.name
    //   });
    const file = e.target.files[0];
    setFile(file.name)
    console.log(file)
    setIsValueChange({ ...isValueChange, file });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  // function removeLastZero(dateTimeStr) {
  //   // Check if the datetime string contains milliseconds
  //   if (dateTimeStr.includes('.')) {
  //     // Remove the trailing zeros in the milliseconds part
  //     while (dateTimeStr.endsWith('0')) {
  //       dateTimeStr = dateTimeStr.slice(0, -1);
  //     }
  //     // If the last character is now a dot, remove it as well
  //     if (dateTimeStr.endsWith('.')) {
  //       dateTimeStr = dateTimeStr.slice(0, -1);
  //     }
  //   }
  //   return dateTimeStr;
  // }
  const handleRemoveImage = () => {
    // setSelectedImage(noimg);
    // setIsValueChange({
    //   ...isValueChange,
    //   file: null
    // });
    // fileInputRef.current.value = null;
    setIsValueChange({ ...isValueChange, file: noimg });
    setSelectedImage(noimg);
  };
  const handleUpdateViewClick = (docUrl) => {
    console.log(docUrl)
    setCurrentDoc(docUrl);
  };
  const handleDocUpdate = (newDocUrl) => {
    setCurrentDoc(newDocUrl);
  };
  const handleClosePopup = () => {
    setSelectedTherapist(null);
  };
  const Createtherapistuser = (e) => {

    e.preventDefault();
    // console.log(isValueChange)
    // console.log(isValueChange.therapist_name)
    if (selector.details.data) {
      const branchname = selector.details.data[""][0].spa_branch_name;
      const manager_name = selector.details.data[""][0].manager_name;
      let obj = {
        query: `[dbo].[spaSoftware_web_proc_AddTherapist_InsertData] @branch_name='{0}',
     @therapist_real_name='{1}',@therapist_name='{2}',@therapist_dob='{3}',@therapist_number='{4}',@type_of_therapist='{5}',@therapist_email='{6}',@therapist_weekly_off='{7}',
     @therapist_shift_timing='{8}',@type_of_salary='{9}',@therapist_doj='{10}',@biometic_id='{11}',@id_proof='{12}',@police_verification='{13}',@police_verification_name='{14}',
     @appointment_letter='{15}',@admin_status='{16}',@therapist_status='{17}',@currdate_time='{18}',@create_by='{19}'`,
        queryArr: [
          `${branchname}`,
          `${isValueChange.therapist_real_name}`,
          `${isValueChange.therapist_name}`,
          `${isValueChange.therapist_dob.toISOString().split('T')[0]}`,
          `${isValueChange.therapist_number}`,
          `${isValueChange.type_of_therapist}`,
          `${isValueChange.therapist_email}`,
          `${isValueChange.therapist_weekly_off}`,
          `${isValueChange.therapist_shift_timing}`,
          `${isValueChange.type_of_salary}`,
          `${isValueChange.therapist_doj.toISOString().split('T')[0]}`,
          `${isValueChange.biometic_id}`,
          `${isValueChange.file}`,
          `${"0"}`,
          `${"0"}`,
          `${"0"}`,
          `${"0"}`,
          `${"1"}`,
          `${formattedDate}`,
          `${manager_name}`
        ]
      }
      Hexapi(obj).then((resp) => {
        console.log(resp,"insert")
        if (resp[""][0]["command"] == 1) {
          console.log("command 1")
          Swal.fire({
            text: `${resp[""][0]['msg']}`,
            icon: 'ok',
          });
          getTableData();
        } else {
          Swal.fire({
            text: "Could not insert data",
            icon: 'ok',
          });
        }
      })
    }

  }
  const setCurrentDateIfEmpty = (name) => {
    if (!isValueChange[name]) {
      setIsValueChange({ ...isValueChange, [name]: new Date() });
    }
  };
  const handleUpdateForm = (therapist) => {
    setShowUpdForm(true)
    setSelectedTherapist(therapist);
  }
  const handleCancelUpdate = () => {
    setSelectedTherapist(null);
  };

  const handleUpdateDetails = (updatedTherapist) => {
    console.log(updatedTherapist)
    setreport((prevReport) =>
      prevReport.map((therapist) =>
        therapist.biometic_id === updatedTherapist.biometic_id ? updatedTherapist : therapist
      )
    );
    setShowUpdForm(false);
    setSelectedTherapist(null);
  };
  return (
    <>
      {
        !currentDoc && !showUpdForm ? (
          <div className="add_therepist">
            <div className="create_therapist_heading">
              <h4 className="create-heading4">
                CREATE THERAPIST ACCOUNT{" "}
              </h4>
              <div>

                  <IoIosArrowDown onClick={toggleFormVisibility} style={{marginRight:"5px"}}/>

                {/* <button id="close-button">x</button> */}
              </div>

            </div>

            {isFormVisible && (
              <form
                action=""
                name="ename"
                id="ename"
                className="form-horizontal"
                method="post"
                enctype="multipart/form-data"
              >
                <div className="control-group">
                  <label className="control-label">Therapist Real Name</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="therapist_real_name"
                      id="therapist_real_name"
                      value={isValueChange.therapist_real_name}
                      data-trigger="hover"
                      data-content="Enter Therapist Real Name"
                      data-original-title="Therapist Real Name"
                      autocomplete="off"
                      required=""
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">Therapist Comman Name</label>
                  <div className="therapist_controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="therapist_name"
                      id="therapist_name"
                      value={isValueChange.therapist_name}
                      data-trigger="hover"
                      data-content="Enter Therapist Comman Name"
                      data-original-title="Therapist Comman Name"
                      autocomplete="off"
                      required=""
                      onChange={handleChanges}
                    />{" "}
                    <div style={{ marginTop: "20px" }}>{branchCode}</div>
                    <br />

                    <a style={{ color: "#F00", display: "block" }} className="blink-line">
                      [Only Enter Therapist Name. Branch Code automatic will add
                      after Name]
                    </a>
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">Date of Birth</label>
                  <div className="controls">

                    <DatePicker
                      selected={isValueChange.therapist_dob}
                      onChange={(date) => handleDateChange(date, 'therapist_dob')}
                      className="span6 popovers"
                      dateFormat="dd/MM/yyyy"
                      onFocus={() => setCurrentDateIfEmpty('therapist_dob')}

                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Date of Joining</label>
                  <div className="controls">
                    <DatePicker
                      selected={isValueChange.therapist_doj}
                      onChange={(date) => handleDateDobChange(date, 'therapist_doj')}
                      className="span6 popovers"
                      dateFormat="dd/MM/yyyy"
                      onFocus={() => setCurrentDateIfEmpty('therapist_doj')}

                    />
                    <span className="add-on">
                      <i className="icon-calendar"></i>
                    </span>
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Biometic Id</label>
                  <div className="controls">
                    <input
                      type="number"
                      className="span6  popovers"
                      name="biometic_id"
                      value={isValueChange.biometic_id}
                      id="biometic_id"
                      data-trigger="hover"
                      data-content="Enter Biometic Id"
                      data-original-title="Enter Biometic Id"
                      required=""
                      onChange={handleChanges}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Enter Therapist Number</label>
                  <div className="controls">
                    <input
                      type="number"
                      className="span6  popovers"
                      name="therapist_number"
                      id="therapist_number"
                      value={isValueChange.therapist_number}
                      data-trigger="hover"
                      data-content="Enter Therapist Number"
                      data-original-title="Therapist Number"
                      required=""
                      onChange={handleChanges}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Types of Therapist</label>
                  <div className="controls">
                    <select
                      className="span6 "
                      data-placeholder="Select Type of Therapist"
                      tabindex="1"
                      name="type_of_therapist"
                      value={isValueChange.type_of_therapist}
                      id="type_of_therapist"
                      required="required"
                      onChange={handleChanges}
                    >
                      <option value="">Select Type of Therapist</option>
                      <option value="North Indian">North Indian</option>
                      <option value="North East">North East</option>
                      <option value="Thai">Thai</option>
                    </select>
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Therapist Email Id</label>
                  <div className="controls">
                    <input
                      type="text"
                      className="span6  popovers"
                      name="therapist_email"
                      id="therapist_email"
                      value={isValueChange.therapist_email}
                      data-trigger="hover"
                      data-content="Enter Therapist Email"
                      data-original-title="Therapist Email"
                      autocomplete="off"
                      onChange={handleChanges}
                    />
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Weekly off</label>
                  <div className="controls">
                    <select
                      className="span6 "
                      data-placeholder="Select Types of Salary"
                      tabindex="1"
                      name="therapist_weekly_off"
                      id="therapist_weekly_off"
                      value={isValueChange.therapist_weekly_off}
                      required="required"
                      onChange={handleChanges}
                    >
                      <option value="">Select Therapist Weekly off</option>
                      <option>Adjustable</option>
                      <option>Sunday</option>
                      <option>Monday</option>
                      <option>Tuesday</option>
                      <option>Wednesday</option>
                      <option>Thursday</option>
                      <option>Friday</option>
                      <option>Saturday</option>
                    </select>
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Shift Timing</label>
                  <div className="controls">
                    <select
                      className="span6 "
                      data-placeholder="Select Shift Timing"
                      tabindex="1"
                      name="therapist_shift_timing"
                      id="therapist_shift_timing"
                      value={isValueChange.therapist_shift_timing}
                      required="required"
                      onChange={handleChanges}
                    >
                      <option value="">Select Shift Timing</option>
                      <option>Morning 11:00AM to 07:30PM</option>
                      <option>Evening 01:00PM to 09:30PM</option>
                      <option>Evening 01:00PM to 10:00PM</option>
                      <option>Straight</option>
                    </select>
                  </div>
                </div>

                <div className="control-group">
                  <label className="control-label">Types of Salary</label>
                  <div className="controls">
                    <select
                      className="span6 "
                      data-placeholder="Select Types of Salary"
                      tabindex="1"
                      name="type_of_salary"
                      id="type_of_salary"
                      value={isValueChange.type_of_salary}
                      required="required"
                      onChange={handleChanges}
                    >
                      <option value="">Select Types of Salary</option>
                      <option value="Salaried">Salaried</option>
                      <option value="Non Salaried">Non Salaried</option>
                    </select>
                  </div>
                </div>
                <div className="control-group">
                  <label className="control-label">Upload ID Proof</label>
                  <div className="controlsFile">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <div
                      className="fileupload-new thumbnail"
                      style={{
                        width: "200px",
                        height: "150px",
                        marginLeft: "2rem",
                      }}
                    >
                      <img src={selectedImage} alt="" className="selected_img" />
                    </div>
                    {selectedImage !== noimg ? (
                      <div className="file-preview">
                        {/* <img src={selectedImage} alt="Selected" className="selected-image" /> */}
                        <div className="button-group">
                          <button type="button" onClick={handleButtonClick} className="select_btn">
                            Change
                          </button>
                          <button type="button" onClick={handleRemoveImage} className="select_btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="file-preview">
                        {/* <img src={selectedImage} alt="No Image" className="no-image" /> */}
                        <button type="button" onClick={handleButtonClick} className="select_btn">
                          Select Image
                        </button>
                      </div>
                    )}
                    <button className="mt-2 label-important">NOTE!</button>
                    <span className="noteLabel">Upload Id Proof, Click on Select Image Button</span>
                  </div>
                </div>
                <div className="form-actions-control">
                  <button
                    type="submit"
                    id="create-btn"
                    //  name="therapistUserBtn"
                    onClick={Createtherapistuser}
                    className="btn btn-success"
                  >
                    Create
                  </button>
                  <button type="reset" className="btn-cancel btn">
                    Cancel
                  </button>
                </div>
              </form>
            )}
            <div className=" container mb-3">
              <div className="row bg-body-tertiary mt-4">
                <div className="dashservice">
                  <p className="name-text"><FaTags /> APPOINTMENT LIST</p>
                  <FaChevronDown onClick={() => { setshowtable(!showtable) }} />
                </div>
                <div>
                  {showtable && (

                    <div className="px-2">
                      <Table className="mt-3 mb-3 bor-dis" responsive bordered >
                        <thead >
                          <tr>
                            <th><FaUserAlt /> Name</th>
                            <th ><FaLeaf /> Number</th>
                            <th ><FaLeaf /> Biometic ID</th>
                            <th ><FaUserAlt /> DOJ</th>
                            <th ><FaUserAlt /> Therapist Type</th>
                            <th ><FaUserAlt /> Weekly off</th>
                            <th ><FaUserAlt /> Shift Timing</th>
                            <th ><FaUserAlt /> ID proof</th>
                            <th ><FaLeaf /> Police verification</th>
                            <th ><FaLeaf /> Appointment letter</th>
                            <th ><FaUserAlt /> Create By</th>
                            <th ><FaTags /> Create Date</th>
                            <th >Action</th>
                          </tr>
                        </thead>

                        <tbody>


                          {report === null ? <tr><td colSpan={16}>No Data Available in this table</td></tr> : (report && report.map((ele, ind) => {
                            return (
                              <tr key={ind+1}>
                                <td onClick={() => handleUpdateForm(ele)} className="updateFormLink"><div class="success"></div>    &nbsp;&nbsp;&nbsp;&nbsp;{ele.therapist_name + " " + branchCode}</td>
                                <td>{ele.therapist_number}</td>
                                <td>{ele.biometic_id}</td>
                                <td>{formatDate(ele.therapist_doj && ele.therapist_doj.date == null ? <span></span> : ele.therapist_doj?.date)}</td>
                                <td>{ele.type_of_therapist}</td>
                                <td>{ele.therapist_weekly_off}</td>
                                <td>{ele.therapist_shift_timing}</td>
                                {/* <td><button onClick={()=>handleViewUploadClick(ele)} className="btactive">View/Upload</button></td> */}
                                <td><button onClick={handleUpdateViewClick} className="btn-click-1">View/Upload</button></td>
                                <td>{ele.police_verification === 0 ? <span className='btn-click-2'>Pending</span> : <span className='btactive-1'>Active</span>}</td>
                                <td>{ele.appointment_letter === 0 ? <span className='btn-click-2'>Pending</span> : <span className='btactive-1'>Active</span>}</td>
                                <td>{ele.create_by}</td>
                                <td>{ele.currdate_time.date.split(' ')[0]}&nbsp;&nbsp;&nbsp; {ele.therapist_status === 1 ? <span className='btactive'>Active</span> : <span className='btactive-1'>Inactive</span>}</td>
                                <td>{ele.therapist_status === 1 ? <span className='btn-click-1'>Delete</span> : <span className='btactive-1'>Active</span>}</td>
                              </tr>
                            )
                          }))}
                        </tbody>
                      </Table>

                    </div>)}
                </div>
              </div>
            </div>
          </div>
        ) : null}

      {currentDoc && (
        <>
          <AddTherapistDoc
            viewfunc={currentDoc}
            onDocUpdate={handleDocUpdate} />
        </>
      )}
      {showUpdForm && (
        <AddTherapistUpdateForm
          therapist={selectedTherapist}
          onCancel={handleCancelUpdate}
          onUpdate={handleUpdateDetails}
          tableUpdate={getTableData}
          branchCode={branchCode}
        />

      )}
    </>
  );
}