import './page.css'
import { FaUserAlt } from "react-icons/fa";
import { FaTags } from "react-icons/fa";
import { TiMessages } from "react-icons/ti";
import { ImEye } from "react-icons/im";
import { BsFileBarGraphFill } from "react-icons/bs";
import { ImCart } from "react-icons/im";



const SubadminDashboard = ({data}) => {
//   console.log(data,"subadmin")

  return (
 <div className='container-fluid'>

    <div className='row padd-r'>
    <div className='dashboard_welcome_text'>
           <p className='welcomeHeading'>WELCOME TO <b>SPA SOFTWARE</b></p>
           <button className='closeButton'>x</button>
        </div>
    </div>

<div className='row padd-r'>
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-12'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                   <FaUserAlt  className='membership_icon'/>
                </div>
                <p className="font-circle-1">
                    {data && data.today_membership_sale[0].membership_sale}
                </p>
                <p className="font-circle">Today Membership</p>
            </div>

</div>

<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_walkin_content'>
                   <FaTags className='walkin_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_walkin_sale[0].walkin_sale}</p>
               <p className="font-circle">Today Walkin</p>

            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_complentary_content'>
                   <TiMessages className='complentary_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_complentary_sale[0].complentary_sale}</p>
               <p className="font-circle">Today Complentary </p>
            </div>
</div>
<div className=' col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_cash_content'>
                   <ImEye className='cash_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_cash_sale[0].today_cash_sale}</p>
               <p className="font-circle">Today Cash Sale</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_card_content'>
                   <BsFileBarGraphFill className='card_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_card_sale[0].today_card_sale}</p>
               <p className="font-circle">Today Card Sale</p>
            </div>
</div>
<div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_Groupon_content'>
                   <ImCart className='Groupon_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.today_groupon_sale[0].today_groupon_sale}</p>
               <p className="font-circle">Groupon Sale</p>
            </div>
</div>

{/* <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12'>
<div className='stats-circle'>
               <div className='Today_Groupon_content'>
                   <ImCart className='Groupon_sale_icon'/>
               </div>
               <p className="font-circle-1">{data && data.make_my_trip[0].make_my_trip}</p>
               <p className="font-circle">Make My Trip</p>
            </div>
</div> */}
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-12'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                   <FaUserAlt  className='membership_icon'/>
                </div>
                <p className="font-circle-1">
                    {data && data.today_total_therapy[0].today_total_therapy}
                </p>
                <p className="font-circle">Total Therapy</p>
            </div>

</div>
<div className='col-xl-2 col-lg-3 col-md-4  col-sm-6 col-12'>
<div className='stats-circle'>
                <div className='Today_membership_content'>
                <BsFileBarGraphFill className='card_sale_icon'/>
                </div>
                <p className="font-circle-1">
                    {data && data.today_paytm_sale
[0].today_paytm_sale
}
                </p>
                <p className="font-circle">Today Paytm Sale</p>
            </div>

</div>
  </div>


 </div>
  )
}

export default SubadminDashboard