import React, { useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import { FaChevronDown} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";

import Viewmembershipserviceuser from "./Viewmembershipserviceuser";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import Tipaccount from "./Tipaccount";
import { encryptData } from "../cryptoutils/cryptoutils";
import { Link } from "react-router-dom";




export default function Complementary() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isFormVisible, setFormVisible] = useState(true);

  const[report,setreport]=useState([])
  const[showtable,setshowtable]=useState(true)
  const[loading,setloading]=useState(false)

  const [show, setShow] = useState(false);
  const [renewForm, setRenewForm] = useState(false);
  const[userid,setuserid]=useState(null)
  const[customer,setcustomername]=useState(null)
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const[customer_contact,setcust_contact]=useState(null)
  const[paidamount,setpaidamount]=useState(null)
  const[branch,setbranch]=useState(null)
  const[tipshow,settipshow]=useState(false)
  const[paidshow,setpaidshow]=useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const selector= useSelector(state=>state)


  function formatDate(dateString) {
    // Create a new Date object from the input date string
    let date = new Date(dateString);

    // Define an array of month names
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract the day, month, and year from the Date object
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    // Return the formatted date string
    return `${day}-${month}-${year}`;
  }

  const handleViewService = (user_id,memberid,cust_name,cust_contact,cust_paid,branch_name) => {
    setSelectedMemberId(user_id);
    setSelectedMemberId(memberid);
    setuserid(user_id)
    setcustomername(cust_name)
    setcust_contact(cust_contact)
    setpaidamount(cust_paid)
    setbranch(branch_name)
    setShow(true);
  };



  const handletip=(memberid,userid)=>{
    setuserid(userid)
    setSelectedMemberId(memberid);
    settipshow(true)
  }


function maskContact(contact) {
  if (contact.length < 4) {
      return contact;
  }
  const lastFourDigits = contact.slice(-4);
  const maskedPart = '*'.repeat(contact.length - 4);
  return maskedPart + lastFourDigits;
}


  const handlereport=async(e)=>{
    setloading(true)
    if(selector.details.data){
      const spa_branch_name=selector.details.data[""][0].spa_branch_name

      const obj={
        query:`[dbo].[spasoftware_web_proc_complentaryaccount_getdata]@branchname='{0}'`,
        queryArr:[`${spa_branch_name}`]
      }
      const getdata= await Hexapi(obj)
      setreport(getdata.walkindata || [])
      console.log(getdata.walkindata,"walkinaccount")
      setloading(false)
    }

  }

  useEffect(()=>{

      handlereport()

  },[selector])

  const columns = useMemo(() => [
    { Header: "Sr No", accessor: (_, i) => i + 1 },
    { Header: "DOJ", accessor: (row)=>(<div className="text-info">{(row.cust_dop)}</div>)},
    { Header: "Walking ID", accessor: "member_id" },
    { Header: "Time In", accessor:"time_in" },
    { Header: "Time Out", accessor:"time_out" },
    {
      Header: "Customer Name",
      accessor: "cust_name",
    },
    { Header: "Customer Number", accessor: (row) =>maskContact(row.cust_contact) },
    { Header: "Payment Mode", accessor: "payment_mode" },
    { Header: "Total Amount", accessor: "cust_total_amount" },
    { Header: "Paid Amount", accessor: "cust_paid_amount" },
    { Header: "Pending Amount", accessor: "cust_bal_amount" },
    { Header: "Total Service", accessor: "cust_total_service" },
    { Header: "Used Service", accessor: "cust_used_service" },
    { Header: "Balance Service", accessor: "cust_bal_service" },
    { Header: "Created By", accessor: "create_by" },
    {
      Header: "View Service",
      accessor: "booking",
      Cell: ({ row }) => (
        <>
              <Link target="_blank" rel="noopener noreferrer" to={`/viewservice/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Booking</Link>


        </>
      )
    },
    {
      Header: "Tip Account",
      accessor: "tip",
      Cell: ({ row }) => (
        <>
        <Link target="_blank" rel="noopener noreferrer" to={`/tipaccount/${ encodeURIComponent(encryptData(row.original.user_id))}/${ encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link>
        </>
      )
    },

  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: report,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const totalEntries = useMemo(() => report.length, [report]);
  const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
  const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
  const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <>
      {!show && !renewForm && !tipshow && !paidshow ? (<>
<div className=" container">
<div className="row bg-body-tertiary mt-4 mb-2">
<div className="dashservice">
  <p className="name-text">Walking Complentary Accounts</p>
  <FaChevronDown onClick={()=>{setshowtable(!showtable)}}/>
</div>
 <div>
 {showtable && (

<div>
<div className="d-flex justify-content-between mt-2">
  <select
    value={itemsPerPage}
    onChange={(e) => {
      setItemsPerPage(Number(e.target.value));
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 25, 50, 75, 100].map((size) => (
      <option key={size} value={size}>
        Show {size}
      </option>
    ))}
  </select>
  <input
    type="text"
    value={globalFilter || ""}
    onChange={e => setGlobalFilter(e.target.value)}
    placeholder="Search..."
  />
</div>
<Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render("Header")}
            <span>
              {column.isSorted
                ? column.isSortedDesc
                  ? " 🔽"
                  : " 🔼"
                : ""}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {loading ? (
      <tr>
     <td colSpan={17} className="text-center">
     <Spinner animation="border" />
   </td>
      </tr>
    ) : report.length === 0 ? (
      <tr>
        <td className="fs-6" colSpan={17}>No Data Available</td>
      </tr>
    ) : (
      page.map(row => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            ))}
          </tr>
        );
      })
    )}
  </tbody>
</Table>
<div className="d-flex justify-content-between mb-3">
<span>
Showing {startIndex}-{endIndex} of {totalEntries} entries
{remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
  <div className="pagination">
    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      Previous
    </button>
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
    </span>
    <button onClick={() => nextPage()} disabled={!canNextPage}>
      Next
    </button>
  </div>
</div>
</div>


  )}
 </div>
      </div>
</div></>):null}


      {tipshow && <Tipaccount memberId={selectedMemberId} userid={userid} setback={()=>{settipshow(false)}} />}
      {show && <Viewmembershipserviceuser memberId={selectedMemberId} userid={userid} customer_name={customer} cust_contact={customer_contact} paidamount={paidamount} branchname={branch} setback={()=>{setShow(false)}} />}

    </>
  );
}

