import React, { useEffect, useMemo, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import Hexapi from '../usefulcomps/HexAPI/Hexapi'
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import {IoMdArrowRoundBack} from 'react-icons/io';
import { lineSpinner } from 'ldrs';
lineSpinner.register();

const Pendingcash = ({branchname,custdatedata,setback}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    console.log(branchname,custdatedata,"===========")
    const[data,setdata]=useState([])
    const[loading,setloading]=useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const handlequerydata=async()=>{
const obj={
    query:`[dbo].[spa_software_web_proc_pendingcash_sale]@datesearch='{0}',@branchname='{1}'`,
    queryArr:[`${custdatedata}`,`${branchname}`]
}
const getdata=await Hexapi(obj)
console.log(getdata)
setdata(getdata[""]

    )
    }
    useEffect(()=>{
    handlequerydata()
    },[branchname,custdatedata])
    const handleback = () => {
        setloading(true);
        setTimeout(() => {
          setback();
          setloading(false);
        }, 100);
      }


      const columns = useMemo(() => [
        { Header: "Sr No", accessor: (_,i)=>i+1 },
        { Header: "Member Id", accessor: "member_id" },
        { Header: "Customer Service", accessor: "cust_type" },

        { Header: "Total Amount", accessor: "total_amount" },
        { Header: "Paid Amount", accessor: "paid_amount" },
        { Header: "Balance Amount", accessor: "bal_amount" },

        { Header: "Deposit Amount", accessor: "deposit_amount" },
      ], []);



      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, globalFilter },
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data: data,
          initialState: { pageIndex: 0, pageSize: itemsPerPage },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
      );

      const totalEntries = useMemo(() => data.length, [data]);

      const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
      const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
      const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
  return (
    <div>
    { loading ? <div className="text-center mt-5"><l-line-spinner
       size="40"
       stroke="3"
       speed="1"
       color="black"
       className="relative"

     ></l-line-spinner></div> :
     <div className=' container bg-body-tertiary'>
      <div className='row'>
     <div className='dashservice'>
         <p className='name-text'><IoMdArrowRoundBack  onClick={handleback} /> &nbsp;&nbsp;&nbsp;Pending Cash Sale</p>
         <FaChevronDown/>
     </div>
     </div>
     <div className='row'>

     <div className="d-flex justify-content-between mt-2">
       <select
         value={itemsPerPage}
         onChange={(e) => {
           setItemsPerPage(Number(e.target.value));
           setPageSize(Number(e.target.value));
         }}
       >
         {[10, 25, 50, 75, 100].map((size) => (
           <option key={size} value={size}>
             Show {size}
           </option>
         ))}
       </select>
       <input
         type="text"
         value={globalFilter || ""}
         onChange={e => setGlobalFilter(e.target.value)}
         placeholder="Search..."
       />
     </div>
     <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render("Header")}
                 <span>
                   {column.isSorted
                     ? column.isSortedDesc
                       ? "🔽"
                       : "🔼"
                     : ""}
                 </span>
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
           {loading ? (
             <tr>
             <td colSpan={17} className="text-center">
             <Spinner animation="border" />
           </td>
              </tr>
           ) : data.length === 0 ? (
             <tr>
               <td className="fs-6" colSpan={17}>No Data Available</td>
             </tr>
           ) : (
             page.map(row => {
               prepareRow(row);
               return (
                 <tr {...row.getRowProps()}>
                   {row.cells.map(cell => (
                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                   ))}
                 </tr>
               );
             })
           )}
         </tbody>
     </Table>
     <div className="d-flex justify-content-between mb-3">
     <span>
  Showing {startIndex}-{endIndex} of {totalEntries} entries
  {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
  </span>
       <div className="pagination">
         <button onClick={() => previousPage()} disabled={!canPreviousPage}>
           Previous
         </button>
         <span>
           Page{' '}
           <strong>
             {pageIndex + 1} of {pageOptions.length}
           </strong>{' '}
         </span>
         <button onClick={() => nextPage()} disabled={!canNextPage}>
           Next
         </button>
       </div>
     </div>
     </div>

   </div>

      }
    </div>
  )
}

export default Pendingcash