import React, { useEffect, useState } from "react";
import "./createaccount.css";
import { FaChevronDown } from "react-icons/fa";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import './service.css'
import Swal from 'sweetalert2';


export const Updateappointment = ({appointmentid}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const[show,setshow]=useState(true)
  const appointment_id=appointmentid
  const[data,setdata]=useState({
    booking_status:"",
    datedop:"",
    customername:"",
    customernumber:"",
    service_type:"",
    customer_id:"",
    staffreq:"",
    totalservice:"",
    appointmenttime:"",
    therapistname:"",
    therapisttype:""
  })
  const handlechange=(e)=>{
    const{name,value}=e.target
    setdata({...data,[name]:value})
  }

const handleappointment=async()=>{
  const obj={
    query:`[dbo].[spasotware_web_proc_createappoinmentedit]@appointment_id='{0}'`,
    queryArr:[`${appointment_id}`]
  }
  const getdata=await Hexapi(obj)
  const getall=getdata.appointmentdata[0]
  console.log(getall.
    cust_id,"customer")
  setdata((prev)=>({
   ...prev,
   booking_status:getall.booking_status,
   datedop:"02-03-2000",
   customername:getall.customer_name,
   customernumber:getall.customer_no,
   customer_id:getall.cust_id,
   service_type:getall.services_type,
   totalservice:getall.total_hour,
   staffreq:getall.staff_req,
   appointmenttime:getall.appointment_timein,
   therapistname:getall.therapist_name,
   therapisttype:getall.therapist_type
  }))
}
useEffect(()=>{
  handleappointment()
},[])


const updateappointment=async(e)=>{
  console.log(data,"kjhgfdsfghj")
  e.preventDefault()
  const obj={
    query:`[dbo].[spasoftware_web_proc_updateappointment]@booking_status='{0}',@master_id='{1}'`,
    queryArr:[`${data.booking_status}`,`${appointmentid}`]
  }
  const hitdata=await Hexapi(obj)
  console.log(hitdata,"hitdata")
  if(hitdata){
    try {
      console.log(hitdata)
      setdata({
        booking_status:"",
    datedop:"",
    customername:"",
    customernumber:"",
    service_type:"",
    customer_id:"",
    staffreq:"",
    totalservice:"",
    appointmenttime:"",
    therapistname:"",
    therapisttype:""
      })
    } catch (error) {
      console.log(error)
    }
  }
}

return (
  <div className="container top-acc highlightBcg">
    <div className="row ">
        <div className="dis-d">
          <p className="font-dis">
          UPDATE APPOINTMENT
          </p>
          <div className="head-hide-btn">
            <FaChevronDown  className="hideShowBtn" onClick={()=>{setshow(!show)}} />
          </div >
        </div>
        </div>

      <div className="row bg-body-tertiary">
      {show && (
                  <form className="form-data">



                    <div className="controls">
                    <div className="control-group">
            <label className="control-label">
                Booking Status<a className="blink">*</a>
              </label>
              <div className="blinkTextDiv controls">
                <select
                  className="span6  popovers"
                  data-placeholder="Select Customer Form Signature"
                  name="booking_status"
                  id="customer_signature"
                  value={data.booking_status}
                  onChange={handlechange}
                >
                  <option selected>{data.booking_status}</option>
                  {data.booking_status==='Pending' ? <>
									<option>Confirm</option>
									<option>Running</option>
									<option>Completed</option></> : null }
                  {data.booking_status==='Confirm' ? <> <option>Pending</option>

									<option>Running</option>
									<option>Completed</option></> : null }
                  {data.booking_status==='Running' ? <> <option>Pending</option>
									<option>Confirm</option>

									<option>Completed</option></> : null }
                  {data.booking_status==='Completed' ? <> <option>Pending</option>
									<option>Confirm</option>

									<option>Running</option></> : null }
                </select>


              <a className="blink" id="blinkText">
              * Just Update Customer Service Status
                </a>
                </div>
            </div>
            <div className="control-group">
            <label className="control-label">
                DOP Date</label>
              <div className="blinkTextDiv controls">
                <input
                  id="dop_date"
                  type="date"
                  name="datedop"
                  value={data.datedop}
                  onChange={handlechange}
                  className="span7  popovers"
                />
                </div>
            </div>
            <div className="control-group">
              <label className="control-label">
                Customer Name
              </label>
              <div className="controls">
                <input
                  type="text"
                   className="span6  popovers"
                  name="customername"
 value={data.customername}
 onChange={handlechange}
                  id="customer_name"

                />
              </div>
            </div>


            <div className="control-group">
            <label className="control-label">
                Customer Number
              </label>
              <div className="controls">
                <input
                  className="span6  popovers"
                  type="number"
value={data.customernumber}
                  name="customernumber"
                  onChange={handlechange}
                  id="customer_no"


                />
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
            Services Type
              </label>
              <div className="blinkTextDiv controls">
                <select
                  className="span6  popovers"
                  data-placeholder="Select Customer Form Signature"
                  name="service_type"
                  value={data.service_type}
                  id="customer_signature"
                  onChange={handlechange}

                >

                  { data.service_type==="membership" ? <option value='walking'>Walking</option>: <option value='membership'>Membership</option> }
                  <option selected> {data.service_type}</option>
                </select>
                </div>
            </div>
            <div className="control-group">
            <label className="control-label">
            Customer Id<a className="blink">*</a>
              </label>
                <input type="text" id="basic_amount"
                   className="span6  popovers"
                   value={data.customer_id}
                 name="customer_id"
                 onChange={handlechange}

                 />

            </div>
            <div className="control-group">
            <label className="control-label">
            Therapist type <a className="blink">*</a>
              </label>
                <input type="text" id="basic_amount"
                   className="span6  popovers"
                   value={data.therapisttype}
                 name="therapisttype"
                 onChange={handlechange}

                 />

            </div>

            <div className="control-group">
            <label className="control-label">
            Therapist Name
              </label>
              <div className="controls">
                <select
                  className="span6  popovers"
                  data-placeholder="Select Customer Service"
                  name="therapistname"
                  id="customer_service"
                  value={data.therapistname}

                >
                  <option>{data.therapistname}</option>
                  <option>select therapist name</option>

                </select>
              </div>
            </div>

        <div className="control-group">
        <label className="control-label">
        Customer Requirement of staff<a className="blink">*</a>
              </label>
              <div className="controls">
                <select
                  data-placeholder="Select Payment Mode"
                  name="staffreq"
                  id="payment_mode"
  value={data.staffreq}
  onChange={handlechange}
                  className="span6  popovers"

                >
                    <option selected>{data.staffreq}</option>
                  <option>Request</option>
                  <option>Suggest</option>
                </select>
              </div>
            </div>
            <div className="control-group">
            <label className="control-label">
            Appointment Booking Time<a className="blink">*</a>
              </label>
                <input type="time" id="basic_amount"
                   className="span6  popovers"
                   value={data.appointmenttime}
                   onChange={handlechange}
                 name="basic_amount"   />
            </div>
            <div className="control-group">
            <label className="control-label">
            Total Services Hour<a className="blink">*</a>
              </label>
              <div className="controls">
                <select
                  className="span6  popovers"
                  data-placeholder="Select Customer Service"
                  name="customer_service"
                  id="customer_service"h
 value={data.totalservice}
 onChange={handlechange}
                >
                  <option>{data.totalservice}</option>

                </select>
              </div>
            </div>

            <div className="createcancel_btn">
              <button
              type="submit"

              className="btn-data"
              onClick={updateappointment}
              >
               Update
              </button>
              <button type="reset" className="cancel-btn btn">Cancel</button>
            </div>
            </div>
          </form>)}
      </div>
      </div>
);
};
