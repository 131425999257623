import React, {useEffect, useMemo, useState } from "react";
import "./QuickSearch.css";
import Hexapi from "../usefulcomps/HexAPI/Hexapi";
import { Spinner, Table } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronDown } from "react-icons/fa";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import './attendance.css'
import { Form } from 'react-bootstrap';
import { lineSpinner } from 'ldrs'
import { Tipaccountadmin } from "./Tipacountadmin";
import Serviceadmin from "./Serviceadmin";
import Renewadmin from "./Renewadmin";
import UpdateService from "./UpdateService";
import { default as Select } from 'react-select';
import ModuleMembership from "./ModuleMembership";
import toast from "react-hot-toast";
import Paidadmin from "./Paidadmin";
import { Link } from "react-router-dom";
import { encryptData } from "../cryptoutils/cryptoutils";
lineSpinner.register()




export default function Quicksearchsubadmin() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const[show,setShow]=useState(false)
  const[visible,setvisi]=useState(true)
  const [isFormVisible, setFormVisible] = useState(true);
  const[data,setdata]=useState({
    branch:"",
    searchoption:"",
    enter_details:""
  })
  const [renewForm, setRenewForm] = useState(false);
  const[userid,setuserid]=useState(null)
  const[customer,setcustomername]=useState(null)
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const[customer_contact,setcust_contact]=useState(null)
  const[paidamount,setpaidamount]=useState(null)
  const[branch,setbranch]=useState(null)
  const[tipshow,settipshow]=useState(false)
  const[paidshow,setpaidshow]=useState(false)
  const[pending,setcust_pending]=useState(null)
  const[report,setquicksearch]=useState([])
 const[loading,setloading]=useState(false)
 const [branchdata,setbranchdata]=useState([])
 const [updatemodule, setUpdatemodule] = useState(false);
 const [itemsPerPage, setItemsPerPage] = useState(10);
 const[backstate,setbackstate]=useState(false)
 const[branchh,setbranchname]=useState(null)


 useEffect(()=>{
 const handlebranchdata=async()=>{
    const obj={
        query:`[dbo].[spasofware_web_proc_module_view_membership_branchname_getdata]`,
        queryArr:[]
     }
     const getdata=await Hexapi(obj)
     console.log(getdata)
     setbranchdata(getdata.branch)
 }
 handlebranchdata()
 },[])
  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };
  const handlechange=(e)=>{
 const{name,value}=e.target
 setdata({...data,[name]:value})
  }

  const handlesubmit=async(e)=>{
    e.preventDefault()
    setloading(true)


      const obj={
        query:`[dbo].[spasoftware_web_proc_quicksearch_subadmin_getdata_query]@branchname='{0}',@searchoption='{1}',@enterdetails='{2}'`,
        queryArr:[`${data.branch}`,`${data.searchoption}`,`${data.enter_details}`]
      }
     try {
      const getdata=await Hexapi(obj)
      console.log(getdata)
      setquicksearch(getdata.quickadvancesearch || [])
     } catch (error) {
            console.log(error)
     }


    setloading(false)

  }

  const handleViewService = (user_id,memberid,cust_name,cust_contact,cust_paid,branch_name) => {

    setSelectedMemberId(memberid);
    setuserid(user_id)
    setcustomername(cust_name)
    setcust_contact(cust_contact)
    setpaidamount(cust_paid)
    setbranch(branch_name)
    setShow(true);
  };

  const handleRenew = (user_id,memberid,cust_name,cust_contact) => {
    setSelectedMemberId(memberid);
    setuserid(user_id)
    setcustomername(cust_name)
    setcust_contact(cust_contact)
    setRenewForm(true);
  };

const handleTip=(memberid,userid)=>{
  setuserid(userid)
  setSelectedMemberId(memberid);
  settipshow(true)
}

const handlePaid=(memberid,cust_bal_amount,user_id)=>{
  setSelectedMemberId(memberid);
  setcust_pending(cust_bal_amount)
  setuserid(user_id)
  setpaidshow(true)

}
const handleupdate = (userid,branchname) => {
  setuserid(userid);
  setUpdatemodule(true);
  setbranchname(branchname)
};
function formatDate(dateString) {
  // Create a new Date object from the input date string
  let date = new Date(dateString);

  // Define an array of month names
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Extract the day, month, and year from the Date object
  let day = date.getDate();
  let month = monthNames[date.getMonth()];
  let year = date.getFullYear();

  // Return the formatted date string
  return `${day}-${month}-${year}`;
}



const handlebranchChange = (selectedOption) => {
  setdata({ ...data,  branch: selectedOption ? selectedOption.value : '' });
};
const branchOptions = branchdata.map(br => ({
  value: br.branchname,
  label: br.branchname
}))


const handleSearchOptionChange = (selectedOption) => {
  setdata({ ...data, searchoption: selectedOption ? selectedOption.value : '' });
};


const searchOptions = [
  { value: "cust_name", label: "Customer Name" },
  { value: "cust_contact", label: "Customer Number" },
  { value: "member_id", label: "Customer Member Id" },
];


const handlecancel=async(cancelid)=>{
try {
  const obj={
    query:`[dbo].[spa_software_web_proc_quick_Search_cancel_query]@cancelid='{0}'`,
    queryArr:[`${cancelid}`]
  }
  const getcancel = await Hexapi(obj)
  console.log(getcancel,"------cancel")
  toast.success(`${getcancel[""][0].msg}`)
  setbackstate(true)
} catch (error) {
toast.error("cancel unsuccessfull")
}
}




const columns = useMemo(() => [
  { Header: "Sr No", accessor: (_, i) => i + 1 },
  { Header: "DOJ", accessor: "cust_dop" ,Cell : ({row})=>(<div className="text-info">{formatDate(row.original.cust_dop)}<span className="text-danger"> actual date <br/> {row.actual_date}</span> </div>)},
  { Header: "Branch Name", accessor: "branch_name" },
  {
    Header: "Member Id",
    accessor: "member_id",
    Cell: ({ row }) => (
      <Link
      target="_blank" rel="noopener noreferrer"
        className=" curpoint"
        to={`/edit/${encodeURIComponent(encryptData(row.original.user_id))}`}
      >
        {row.original.member_id}
      </Link>
    )
  },
  { Header: "Customer Name", accessor: "cust_name" },
  { Header: "Customer Number", accessor: (row) => row.cust_contact },
  { Header: "Payment Mode", accessor: "payment_mode" },
  { Header: "Total Amount", accessor: "cust_total_amount" },
  { Header: "Paid Amount", accessor: "cust_paid_amount" },
  { Header: "Pending Amount", accessor: "cust_bal_amount" },
  { Header: "Total Service", accessor: "cust_total_service" },
  { Header: "Used Service", accessor: "cust_used_service" },
  { Header: "Balance Service", accessor: "cust_bal_service" },
  { Header: "Create By", accessor: "create_by" },
  {
    Header: "View Service",
    accessor: "booking",
    Cell: ({ row }) => (
      <>
         <Link target="_blank" rel="noopener noreferrer" to={`/serviceadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>service</Link>
      { row.original.cust_bal_service===0 ?    <Link target="_blank" rel="noopener noreferrer" to={`/renewserviceadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-2'>Renew</Link>: null}

  {row.original.cust_used_service===0 ?<button className="btn-click-4" onClick={() => handlecancel(row.original.user_id)}>
 Cancel
  </button> : null}
      </>
    )
  },
  {
    Header: "Tip Account",
    accessor: "tip",
    Cell: ({ row }) => (
      <Link target="_blank" rel="noopener noreferrer" to={`/tipadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Tip</Link>
    )
  },
  {
    Header: "Pending Account",
    accessor: "pending",
    Cell: ({ row }) => (
      <Link target="_blank" rel="noopener noreferrer" to={`/paidadmin/${ encodeURIComponent(encryptData(row.original.user_id))}/${encodeURIComponent(encryptData(row.original.member_id))}`} className='btn-click-1'>Paid</Link>
    )
  }
], []);



const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  setPageSize,
  state: { pageIndex, globalFilter },
  setGlobalFilter,
} = useTable(
  {
    columns,
    data: report,
    initialState: { pageIndex: 0, pageSize: itemsPerPage },
  },
  useGlobalFilter,
  useSortBy,
  usePagination
);

const totalEntries = useMemo(() => report.length, [report]);

const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);

  return (
    <>
{ !backstate && <>
   {!show && !renewForm && !tipshow && !paidshow && !updatemodule ? (<div className='container mb-5'>
  <div className='row topatt'>
 <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>Search Report</h4></p>
  <FaChevronDown className='text-new' onClick={()=>{setvisi(!visible)}}/>
 </div>
{visible && (<>
  <div>
  <Form onSubmit={handlesubmit}>
  <div className='form-data mt-3'>
    <Form.Label>
     Select Branch Name
    </Form.Label>
    <Select


                  id="therapist_weekly_off"
                      name="branch"
                      options={branchOptions}
                      value={branchOptions.find(option => option.value === data.branch)}
                      onChange={handlebranchChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Branch Name"
                    />
    </div>
   <div className='form-data mt-3'>
    <Form.Label>
     Select Search Option
    </Form.Label>
    <Select

                  id="therapist_weekly_off"
                      name="searchoption"
                      options={searchOptions}
                      value={searchOptions.find(option => option.value === data.searchoption)}
                      onChange={handleSearchOptionChange}
                      isClearable
                      classNamePrefix="react-select"
                      placeholder="Select Search Option"
                    />
    </div>
   <div className='form-data mt-3'>
    <Form.Label>
    Enter Details
    </Form.Label>
    <input
    name="enter_details"
      value={data.enter_details}
      onChange={handlechange}
      className='span8'
   />

    </div>
   <hr/>
    <div className='newshow'>
      <button id="show_btn" >Show Report</button>

    </div>
    <hr/>
  </Form>
</div>
</>)}
  </div>


         <div className="row">
          <div className="disapp">

      </div>
      </div>

        <div className=" row bg-body-tertiary mt-5">
        <div className='dashservice'>
  <p className=' d-flex'><GiHamburgerMenu  className='text-new name-text-2'/> <h4 className='name-text'>Membership Accounts : <span className="text-danger">Branch : {data.branch}</span></h4></p>
  <FaChevronDown className='text-new' onClick={toggleFormVisibility}/>
 </div>

  <div className=" px-2">
          { isFormVisible && (

<div>
<div className="d-flex justify-content-between mt-2">
  <select
    value={itemsPerPage}
    onChange={(e) => {
      setItemsPerPage(Number(e.target.value));
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 25, 50, 75, 100].map((size) => (
      <option key={size} value={size}>
        Show {size}
      </option>
    ))}
  </select>
  <input
    type="text"
    value={globalFilter || ""}
    onChange={e => setGlobalFilter(e.target.value)}
    placeholder="Search..."
  />
</div>
<Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
  <thead>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render("Header")}
            <span>
              {column.isSorted
                ? column.isSortedDesc
                  ? " 🔽"
                  : " 🔼"
                : ""}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
  <tbody {...getTableBodyProps()}>
    {loading ? (
      <tr>
     <td colSpan={17} className="text-center">
     <Spinner animation="border" />
   </td>
      </tr>
    ) : report.length === 0 ? (
      <tr>
        <td className="fs-6" colSpan={17}>No Data Available</td>
      </tr>
    ) : (
      page.map(row => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => (
              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
            ))}
          </tr>
        );
      })
    )}
  </tbody>
</Table>
<div className="d-flex justify-content-between mb-3">
<span>
Showing {startIndex}-{endIndex} of {totalEntries} entries
{remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
</span>
  <div className="pagination">
    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      Previous
    </button>
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' '}
    </span>
    <button onClick={() => nextPage()} disabled={!canNextPage}>
      Next
    </button>
  </div>
</div>

</div>
        )}
        </div>
        </div>
        </div>):null}
        {updatemodule && <UpdateService userid={userid} setback={()=>{setUpdatemodule(false)}} setbackmember={()=>{setbackstate(true)}} branchdata={branchh}/>}
        {paidshow && <Paidadmin  memberId={selectedMemberId} pending={pending} userid={userid} setback={()=>{setpaidshow(false)}}/>}
      {tipshow && <Tipaccountadmin  memberId={selectedMemberId} userid={userid}  setback={()=>{settipshow(false)}}/>}
      {show && <Serviceadmin data={data} memberId={selectedMemberId} userid={userid} customer_name={customer} cust_contact={customer_contact} paidamount={paidamount} branchname={branch}  setback={()=>{setShow(false)}} />}
      {renewForm && <Renewadmin data={data} memberId={selectedMemberId} userid={userid} customer_name={customer} cust_contact={customer_contact} />}
    </>
    }

    </>
  );
}