import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import "./addtherapist.css";
import { IoIosArrowDown } from "react-icons/io";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';

const AddTherapistUpdateForm = ({ therapist, onCancel, onUpdate,tableUpdate,branchCode }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const [isFormVisible, setFormVisible] = useState(true);
    const selector = useSelector(state => state);
    // const [branchCode, setBranchCode] = useState('');
    const [formData, setFormData] = useState({
        therapist_real_name: "",
        therapist_name: "",
        therapist_dob: "",
        therapist_doj: "",
        biometic_id: "",
        therapist_number: "",
        type_of_therapist: "",
        therapist_email: "",
        therapist_weekly_off: "",
        therapist_shift_timing: "",
        type_of_salary: "",
        file: null,
    });


    useEffect(() => {
        console.log(therapist)
        if (therapist) {
            const formatDate = (dateString) => {
                if (!dateString) return "";
                const date = new Date(dateString);
                return isNaN(date) ? "" : date.toISOString().split('T')[0];
            };

            const formattedTherapist = {
                ...therapist,
                therapist_dob: formatDate(therapist.therapist_dob?.date || therapist.therapist_dob),
                therapist_doj: formatDate(therapist.therapist_doj?.date || therapist.therapist_doj),
            };
            setFormData(formattedTherapist);
        }
    }, [therapist]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async(e) => {
        if (selector.details.data) {
            const branchname = selector.details.data[""][0].spa_branch_name;
            const managername=selector.details.data[""][0].manager_name
        let obj = {
            query: `[dbo].[spasoftware_web_proc_Addtherapistupdateform_updateform]@branch_name='{0}',@managerName='{1}',@therapistRealName='{2}',@therapistDOB='{3}',@thrapistNumber='{4}',@typeoftherapist='{5}',@therapistEmail='{6}',@therapistWeeklyOff='{7}',@therapistShiftTiming='{8}',@typeOfSalary='{9}',@therapistDOJ='{10}',@biometic_id='{11}',@therapist_master_id='{12}'`,
            queryArr:[
               `${branchname}`,
               `${managername}`,
               `${formData.therapist_real_name}`,
               `${formData.therapist_dob}`,
               `${formData.therapist_number}`,
               `${formData.type_of_therapist}`,
               `${formData.therapist_email}`,
               `${formData.therapist_weekly_off}`,
               `${formData.therapist_shift_timing}`,
               `${formData.type_of_salary}`,
               `${formData.therapist_doj}`,
               `${formData.biometic_id}`,
               `${therapist.therapist_master_id}`
            ]
        }
        Hexapi(obj).then((resp)=>{
            console.log(resp);
            // setBranchCode(resp[""][0].branch_code)
            Swal.fire({
                text: "Form Updated Successfully",
                icon: 'ok',
            });
        })
        // try {
        //     const resp = await Hexapi(obj);
        //     console.log(resp);
        //     Swal.fire({
        //         text: "Form Updated Successfully",
        //         icon: 'success',
        //     });
        //     onUpdate(formData);
        //     tableUpdate(formData);
        // } catch (error) {
        //     console.error("Error updating therapist:", error);
        //     Swal.fire({
        //         text: "Failed to update therapist. Please try again.",
        //         icon: 'error',
        //     });
        // }
    }
        e.preventDefault();
        onUpdate(formData);
        if (selector.details.data) {
            tableUpdate(formData);
        }

    };

    const toggleFormVisibility = () => {
        setFormVisible(!isFormVisible);
    };
    const handleClick = () => {
        window.location.href = '/addtherapist';
        // window.history.back();
      };
    return (
        <>
        <div className="back-button" onClick={handleClick}>
                <FaArrowLeft className="back-icon" />
                <span className="back-text">Back</span>
            </div>
        <div className="add_therepist">
            <div className="create_therapist_heading">
                <h4 className="create-heading4">
                    UPDATE THERAPIST ACCOUNT{" "}
                </h4>
                <div className="head-btn">
                    <button id="arrow-button" onClick={toggleFormVisibility}>
                        <IoIosArrowDown />
                    </button>
                    {/* <button id="close-button">x</button> */}
                </div>

            </div>

            {isFormVisible && (
                <form name="ename" id="ename" className="form-horizontal" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div className="control-group">
                        <label className="control-label">Therapist Real Name</label>
                        <div className="controls">
                            <input
                                type="text"
                                className="span6 popovers"
                                name="therapist_real_name"
                                id="therapist_real_name"
                                value={formData.therapist_real_name}
                                data-trigger="hover"
                                data-content="Enter Therapist Real Name"
                                data-original-title="Therapist Real Name"
                                autoComplete="off"
                                required=""
                                onChange={handleChanges}
                            />
                        </div>
                    </div>
                    <div className="control-group">
                        <label className="control-label">Therapist Comman Name</label>
                        <div className="therapist_controls">
                            <input
                                type="text"
                                className="span6  popovers"
                                name="therapist_name"
                                id="therapist_name"
                                value={formData.therapist_name}
                                data-trigger="hover"
                                data-content="Enter Therapist Comman Name"
                                data-original-title="Therapist Comman Name"
                                autocomplete="off"
                                required=""
                                onChange={handleChanges}
                            />{" "}
                            <div style={{ marginTop: "20px" }}>{branchCode}</div>
                            <br />

                            <a style={{ color: "#F00", display: "block" }} className="blink-line">
                                [Only Enter Therapist Name. Branch Code automatic will add
                                after Name]
                            </a>
                        </div>
                    </div>
                    <div className="control-group">
                        <label className="control-label">Date of Birth</label>
                        <div className="controls">
                            <input
                                className="m-ctrl-medium date-picker"
                                size="16"
                                type="date"
                                name="therapist_dob"
                                value={formData.therapist_dob}
                                required=""
                                onChange={handleChanges}
                            />
                            <span className="add-on">
                                <i className="icon-calendar"></i>
                            </span>
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Date of Joining</label>
                        <div className="controls">
                            <input
                                className=" m-ctrl-medium date-picker"
                                size="16"
                                type="date"
                                name="therapist_doj"
                                value={formData.therapist_doj}
                                required=""
                                onChange={handleChanges}
                            />
                            <span className="add-on">
                                <i className="icon-calendar"></i>
                            </span>
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Biometic Id</label>
                        <div className="controls">
                            <input
                                type="number"
                                className="span6  popovers"
                                name="biometic_id"
                                value={formData.biometic_id}
                                id="biometic_id"
                                data-trigger="hover"
                                data-content="Enter Biometic Id"
                                data-original-title="Enter Biometic Id"
                                required=""
                                onChange={handleChanges}
                            />
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Enter Therapist Number</label>
                        <div className="controls">
                            <input
                                type="number"
                                className="span6  popovers"
                                name="therapist_number"
                                id="therapist_number"
                                value={formData.therapist_number}
                                data-trigger="hover"
                                data-content="Enter Therapist Number"
                                data-original-title="Therapist Number"
                                required=""
                                onChange={handleChanges}
                            />
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Types of Therapist</label>
                        <div className="controls">
                            <select
                                className="span6 "
                                data-placeholder="Select Type of Therapist"
                                tabindex="1"
                                name="type_of_therapist"
                                value={formData.type_of_therapist}
                                id="type_of_therapist"
                                required="required"
                                onChange={handleChanges}
                            >
                                <option value="">Select Type of Therapist</option>
                                <option value="North Indian">North Indian</option>
                                <option value="North East">North East</option>
                                <option value="Thai">Thai</option>
                            </select>
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Therapist Email Id</label>
                        <div className="controls">
                            <input
                                type="text"
                                className="span6  popovers"
                                name="therapist_email"
                                id="therapist_email"
                                value={formData.therapist_email}
                                data-trigger="hover"
                                data-content="Enter Therapist Email"
                                data-original-title="Therapist Email"
                                autocomplete="off"
                                onChange={handleChanges}
                            />
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Weekly off</label>
                        <div className="controls">
                            <select
                                className="span6 "
                                data-placeholder="Select Types of Salary"
                                tabindex="1"
                                name="therapist_weekly_off"
                                id="therapist_weekly_off"
                                value={formData.therapist_weekly_off}
                                required="required"
                                onChange={handleChanges}
                            >
                                <option value="">Select Therapist Weekly off</option>
                                <option>Adjustable</option>
                                <option>Sunday</option>
                                <option>Monday</option>
                                <option>Tuesday</option>
                                <option>Wednesday</option>
                                <option>Thursday</option>
                                <option>Friday</option>
                                <option>Saturday</option>
                            </select>
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Shift Timing</label>
                        <div className="controls">
                            <select
                                className="span6 "
                                data-placeholder="Select Shift Timing"
                                tabindex="1"
                                name="therapist_shift_timing"
                                id="therapist_shift_timing"
                                value={formData.therapist_shift_timing}
                                required="required"
                                onChange={handleChanges}
                            >
                                <option value="">Select Shift Timing</option>
                                <option>Morning 11:00AM to 07:30PM</option>
                                <option>Evening 01:00PM to 09:30PM</option>
                                <option>Evening 01:00PM to 10:00PM</option>
                                <option>Straight</option>
                            </select>
                        </div>
                    </div>

                    <div className="control-group">
                        <label className="control-label">Types of Salary</label>
                        <div className="controls">
                            <select
                                className="span6 "
                                data-placeholder="Select Types of Salary"
                                tabindex="1"
                                name="type_of_salary"
                                id="type_of_salary"
                                value={formData.type_of_salary}
                                required="required"
                                onChange={handleChanges}
                            >
                                <option value="">Select Types of Salary</option>
                                <option value="Salaried">Salaried</option>
                                <option value="Non Salaried">Non Salaried</option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" id="create-btn" className="btn btn-success">Update Details</button>
                    <button type="button" onClick={onCancel} className="btn-cancel btn">Cancel</button>
                </form>
            )}
        </div>
        </>
    );
};

export default AddTherapistUpdateForm;