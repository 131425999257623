import './dashboardtable.css'
import Table from 'react-bootstrap/Table';
import { GiThreeLeaves } from "react-icons/gi";
import { IoPersonSharp } from "react-icons/io5";
import { FaTags } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import { useState } from 'react';


const Dashboardtable = ({data}) => {
    console.log(data,"data")
    const[show,setshow]=useState(true)
    function removeLastZero(dateTimeStr) {
      // Check if the datetime string contains milliseconds
      if (dateTimeStr.includes('.')) {
        // Remove the trailing zeros in the milliseconds part
        while (dateTimeStr.endsWith('0')) {
          dateTimeStr = dateTimeStr.slice(0, -1);
        }
        // If the last character is now a dot, remove it as well
        if (dateTimeStr.endsWith('.')) {
          dateTimeStr = dateTimeStr.slice(0, -1);
        }
      }
      return dateTimeStr;
    }



  return (
<>
{
  data===null ? "....loading" : ( <div className='container mb-5'>
    <div className='bg-body-tertiary '>
      <div className='dashservice'>
        <p className='name-text'>SPA Branch Code Master List</p>
        <p><FaChevronDown onClick={()=>{setshow(!show)}}/></p>
    </div>
 { show &&
<div className='p-3'>
 <Table responsive  hover striped className=' mt-2 mb-2'>
    <thead>
      <tr className=' datamain-m'>
        <th><IoPersonSharp  className='wind' /> Branch Name</th>
        <th ><GiThreeLeaves  className='wind'/> Branch Code</th>
        <th ><IoPersonSharp className='wind' /> Create By</th>
        <th ><FaTags className='wind' /> Create Date</th>
      </tr>
    </thead>
    <tbody>
      {
          data ? (data.spa_branch_code_master_list
            .map((ele)=>{
                return(
                    <tr>
                    <td >{ele.status===1 ? <span className='active'> </span> : <span className='inactive'></span>}  &nbsp;&nbsp;&nbsp;<span className='backcolor'>{ele.branch_name}</span></td>
                    <td>{ele.branch_code}</td>
                    <td>{ele.create_by}</td>
                    <td>{removeLastZero(ele.currdate_time.date)} &nbsp;&nbsp;&nbsp; {ele.status===1 ? <span className='btactive'>Active</span>:<span className='btactive-1'>Inactive</span>}</td>
                    </tr>
                )
            })): <td colSpan={7}> No Data Available</td>

      }
    </tbody>


  </Table>
  <p className=' d-flex justify-content-end text-decoration-underline curpoint' style={{marginRight:"0px"}} onClick={()=>{window.scrollTo(0,0)}}><a>all Spa's</a></p>
  </div>
  }
  </div>
  </div>
)
}
</>


  )
}

export default Dashboardtable