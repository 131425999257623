import React, { useEffect, useState, useMemo } from 'react';
import "./page.css";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from 'react-redux';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { FaChevronDown } from "react-icons/fa";
import Swal from 'sweetalert2';
import { Spinner, Table } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { lineSpinner } from 'ldrs'
import { maskContact } from '../customfunction/dateformatmonth';
lineSpinner.register()

const Priorappointment = () => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   // const purchaseDate = useSelector(state => state.purchaseTime.split('T')[0]);
   const selector = useSelector(state => state)
   // console.log(selector.details.data[""][0].user_role, "-------------prior appointment")

   const [report, setreport] = useState([]);
   const [itemsPerPage, setItemsPerPage] = useState(10);
   const [loading, setloading] = useState(false)
   const [showtable, setshowtable] = useState(true);
   const [isFormVisible, setFormVisible] = useState(true);
   const [isTableVisible, setIsTableVisible] = useState(true);
   const [therapistName, setTherapistName] = useState([]);
   const [data, setData] = useState({
      dop_date: "",
      customer_name: '',
      customer_numb: '',
      cust_type: '',
      service_type: '',
      therapist_name: '',
      cust_reqr_staff: '',
      booking_time: '',
      total_serv_hrs: '',
      booking_status: ''

   });
   const date = new Date();
   const formattedDate = date.toISOString().split('T')[0];
   const [dop, setDopDate] = useState("")
   const [dopdatequery, setdopdatequery] = useState(formattedDate)
   const queryData = () => {
      if (selector.details.data) {
         const branchname = selector.details.data[""][0].spa_branch_name
         const obj = {
            query: `[dbo].[spasoftware_web_proc_priorappointment_getdata]@branch_name='{0}'`,
            queryArr: [`${branchname}`]
         }
         Hexapi(obj).then((resp) => {
            console.log(resp);
            setTherapistName(resp[""])
         })
      }
   }
   const tableData = () => {
      if (selector.details.data) {
         const branchname = selector.details.data[""][0].spa_branch_name
         const obj = {
            query: `[dbo].[spasoftware_web_proc_priorappointment_GetTableData]@dop_date='{0}',@branch_name='{1}'`,
            queryArr: [`${dop}`, `${branchname}`]
         }
         Hexapi(obj).then((resp) => {
            console.log(resp);
            setreport(resp[""] || [])
         })
      }
   }




   const toggleFormVisibility = () => {
      setFormVisible(!isFormVisible);
   };
   const toggleTableVisibility = () => {
      setIsTableVisible(!isTableVisible);
   };
   const handleChange = (e) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
      console.log(e.target.value);
   }


   const handleSubmit = () => {
      if (selector.details.data) {
         const branchname = selector.details.data[""][0].spa_branch_name;
         const manager_name = selector.details.data[""][0].manager_name;
         const spa_name = selector.details.data[""][0].spa_name;
         let obj = {
            query: `[dbo].[spasoftware_web_proc_priorappointment_insertData]@dop_date='{0}',@branch_name='{1}',@bookingTime='{2}',@total_hour='{3}',@spa_name='{4}',@customer_name='{5}',@customer_no='{6}',@cust_type='{7}',@serviceType='{8}',@therapist_name='{9}',@staff_req='{10}',@manager_name='{11}',@booking_status='{12}'`,
            queryArr: [
               `${data.dop_date}`,
               `${branchname}`,
               `${data.booking_time}`,
               `${data.total_serv_hrs}`,
               `${spa_name}`,
               `${data.customer_name}`,
               `${data.customer_numb}`,
               `${data.cust_type}`,
               `${data.service_type}`,
               `${data.therapist_name}`,
               `${data.cust_reqr_staff}`,
               `${manager_name}`,
               `${data.booking_status}`
            ]
         }
         Hexapi(obj).then((resp) => {
            console.log(resp);
            if (resp) {
               try {
                  Swal.fire({
                     text: `${resp[""][0]['msg']}`,
                     icon: 'ok',
                     customClass: {
                        popup: 'my-custom-swal',
                        title: 'my-custom-swal-title',
                        content: 'my-custom-swal-content',
                        confirmButton: 'my-custom-swal-confirm',
                        cancelButton: 'my-custom-swal-cancel'
                     },
                  });
               } catch (error) {
                  Swal.fire({
                     text: `error`,
                     text: `error`,
                     icon: 'ok',
                     customClass: {
                        popup: 'my-custom-swal',
                        title: 'my-custom-swal-title',
                        cancelButton: 'my-custom-swal-cancel'
                     },
                  });
               }
            }
         })
      }
   }


   useEffect(() => {
      queryData();
      tableData()
   }, [dopdatequery]);

   const setCurrentDateIfEmpty = (name) => {
      if (!data[name]) {
         setData({ ...data, [name]: new Date() });
      }
   };
   const handleChangeReport = (date, name) => {
      if (date) {
         const formattedDate = date.toISOString().split('T')[0];
         setData(prevState => ({ ...prevState, [name]: formattedDate }));
      } else {
         setData(prevState => ({ ...prevState, [name]: '' }));
      }
   };

   const handleChangedataReport = (date, name) => {
      if (date) {
         setDopDate(date.toISOString().split('T')[0]);
      } else {
         setDopDate('');
      }
   };



   const setCurrentDatedataIfEmpty = () => {
      if (!dop) {
         setDopDate(new Date());
      }
   };


   const columns = useMemo(() => [
      { Header: "Sr No", accessor: (_, i) => i + 1 },
      { Header: "Customer Name", accessor: "customer_name" },
      { Header: "Customer Number", accessor: (row) => maskContact( row.customer_no) },
      { Header: "Appointment Date", accessor: (row) => row.appointment_date.date.split(' ')[0] },
      { Header: "Appointment Time", accessor: "appointment_timein" },
      { Header: "Customer Type", accessor: "cust_type" },
      { Header: "Service Type", accessor: "services_type" },
      { Header: "Therapist Name", accessor: "therapist_name" },
      { Header: "Req of Staff", accessor: "staff_req" },
      { Header: "Total Hour", accessor: "total_hour" },
      { Header: "Create By", accessor: "create_by" },
      { Header: "Booking Status", accessor: "booking_status",
         Cell: ({ row }) => (
         <>
         {row.original.booking_status==='Confirm' ?
           <button className='btn-click-3'>Confirm</button> : null}
         {row.original.booking_status==='Pending' ?
           <button className='btn-click-4'>Pending</button> :null}
         {row.original.booking_status==='Running' ?
           <button className='btn-click-2'>Running</button> : null}
         {row.original.booking_status==='Completed' ?
           <button className='btn-click-5'>Completed</button> : null}

           </>
       )
    }

   ], []);

   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageOptions,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
      setGlobalFilter,
   } = useTable(
      {
         columns,
         data: report,
         initialState: { pageIndex: 0, pageSize: itemsPerPage },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
   );

   const totalEntries = useMemo(() => report.length, [report]);
   const startIndex = useMemo(() => pageIndex * itemsPerPage + 1, [pageIndex, itemsPerPage]);
   const endIndex = useMemo(() => Math.min(startIndex + itemsPerPage - 1, totalEntries), [startIndex, itemsPerPage, totalEntries]);
   const remainingEntries = useMemo(() => totalEntries - endIndex, [totalEntries, endIndex]);
   return (
      <div classNameName='container'>
         <div className="row-fluid">
            <div className="span12">
               <div className="widget">
                  <div className="widget-title">
                     <h4 className='prior_title'>PRIOR APPOINTMENT</h4>
                     <span className="tools">
                        <button id="arrow-button" onClick={toggleFormVisibility}>
                           <IoIosArrowDown />
                        </button>
                        {/* <button id="close-button">x</button> */}
                     </span>
                  </div>
                  {isFormVisible && (
                     <div className="widget-body form">
                        <div action="" name="dname" id="dname" className="form-horizontal" method="post" autocomplete="off">
                           <div className="control-group-div">
                              <div className="control-group">
                                 <label className="control-label">DOP Date</label>
                                 <div className="controls">
                                    <DatePicker
                                       selected={data.dop_date}
                                       onChange={(date) => handleChangeReport(date, 'dop_date')}
                                       className="span6 popovers"
                                       dateFormat="dd-MM-yyyy"
                                       onFocus={() => setCurrentDateIfEmpty('dop_date')}
                                    />
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Customer Name<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <input type="text" className="span6  popovers" name="customer_name" id="customer_name" data-trigger="hover" data-content="Enter Customer Name" data-original-title="Customer Name" autocomplete="off" required value={data.customer_name} onChange={handleChange} />
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Customer Number<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <input type="number" className="span6  popovers" name="customer_numb" value={data.customer_numb} autocomplete="off" id="customer_no" data-trigger="hover" data-content="Enter Customer Number" data-original-title="Customer Number" required onChange={handleChange} />
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Customer Type<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Customer Type" name="cust_type" id="cust_type" required="required" value={data.cust_type} onChange={handleChange}>
                                       <option value=""></option>
                                       <option>Old</option>
                                       <option>New</option>
                                    </select>
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Services Type<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Services Type" tabindex="1" name="service_type" value={data.service_type} required="required" onChange={handleChange}>
                                       <option value=""></option>
                                       <option value="walking">Walkin Service</option>
                                       <option value="membership">Membership Service</option>
                                    </select>
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Therapist Name<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Therapist Name" tabindex="1" name="therapist_name" value={data.therapist_name} required="required" onChange={handleChange}>
                                       <option value="">Select Therapist Name</option>
                                       {therapistName.map((name, index) => (
                                          <option key={index} value={name.therapist_name}>{name.therapist_name}</option>
                                       ))}

                                    </select>
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Customer Requirement of staff<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Choose Customer Requirement of staff" name="cust_reqr_staff" value={data.cust_reqr_staff} required="required" onChange={handleChange}>
                                       <option value=""></option>
                                       <option>Request</option>
                                       <option>Suggest</option>
                                    </select>
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Appointment Booking Time<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <input type="time" className="span6  popovers" name="booking_time" value={data.booking_time} id="bookingTime" data-trigger="hover" data-content="Enter Appointment Booking Time" data-original-title="Appointment Booking Time" autocomplete="off" onChange={handleChange} />
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Total Services Hour<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Add Services Hour" tabindex="2" name="total_serv_hrs" id="total_hour" value={data.total_serv_hrs} required="required" onChange={handleChange}>
                                       <option value=""></option>
                                       <option value="1">1 Hour - 60 Min</option>
                                       <option value="1.5">1:30 Hour - 90 Min</option>
                                       <option value="2">2 Hour - 120 Min</option>
                                       <option value="2.5">2:30 Hour - 150 Min</option>
                                       <option value="3">3 Hour - 180 Min</option>
                                    </select>
                                 </div>
                              </div>

                              <div className="control-group">
                                 <label className="control-label">Booking Status<a className="blink" style={{ color: "#F00" }}> * </a></label>
                                 <div className="controls">
                                    <select className="span6 chosen" data-placeholder="Select Booking Status" tabindex="2" name="booking_status" id="booking_status" required="required" value={data.booking_status} onChange={handleChange}>
                                       <option value=""></option>
                                       <option>Pending</option>
                                       <option>Confirm</option>
                                       <option>Running</option>
                                       <option>Completed</option>
                                    </select>
                                 </div>
                              </div>

                              <input type="hidden" name="code" />
                              <input type="hidden" name="userId" />
                              <input type="hidden" name="custBal" />

                              <div className="form-actions">
                                 <button onClick={handleSubmit} name="CreateAppointmentBtn" className="Book_btn btn-success">Book Appointment</button>
                                 <button type="reset" className="cancel_btn btn">Cancel</button>
                              </div>
                           </div>

                        </div>

                     </div>
                  )}
                  <div className="mt-5">
                     <div className="dashservice">
                        <p className="name-text">
                           SEARCH BY APPOINTMENT SHEET
                        </p>

                        <FaChevronDown onClick={toggleTableVisibility} />
                     </div>
                  </div>
                  {isTableVisible && (
                     <div class="">
                        <div
                           name="dname"
                           id="dname1"
                           class="form-horizontal"
                        >
                           <div className="control-group-div">

                              <div class="control-group">
                                 <label class="control-label">SELECT DOP DATE</label>
                                 <div class="controls">
                                    <DatePicker
                                       selected={dop}
                                       onChange={(date) => handleChangedataReport(date, 'dop')}
                                       className="span6 popovers"
                                       dateFormat="dd/MM/yyyy"
                                       onFocus={() => setCurrentDatedataIfEmpty('dop')}
                                    />
                                 </div>
                              </div>

                              <div class="form-actions-control">
                                 <button className="btn btn-success mb-3" id="show_btn" onClick={() => setdopdatequery(dop)}>Show Report</button>

                              </div>
                              <br />
                              <br />


                           </div>

                        </div>
                     </div>
                  )}
                  <div className=" container mb-3">
                     <div className="row bg-body-tertiary mt-4">
                        <div className="dashservice">
                           <p className="name-text">APPOINTMENT SHEET</p>
                           <FaChevronDown onClick={() => { setshowtable(!showtable) }} />
                        </div>
                        <div>
                           {showtable && (

                              <div>
                                 <div className="d-flex justify-content-between mt-2">
                                    <select
                                       value={itemsPerPage}
                                       onChange={(e) => {
                                          setItemsPerPage(Number(e.target.value));
                                          setPageSize(Number(e.target.value));
                                       }}
                                    >
                                       {[10, 25, 50, 75, 100].map((size) => (
                                          <option key={size} value={size}>
                                             Show {size}
                                          </option>
                                       ))}
                                    </select>
                                    <input
                                       type="text"
                                       value={globalFilter || ""}
                                       onChange={e => setGlobalFilter(e.target.value)}
                                       placeholder="Search..."
                                    />
                                 </div>
                                 <Table className="mt-3 mb-3 bor-dis" responsive bordered {...getTableProps()}>
                                    <thead>
                                       {headerGroups.map(headerGroup => (
                                          <tr {...headerGroup.getHeaderGroupProps()}>
                                             {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                   {column.render("Header")}
                                                   <span>
                                                      {column.isSorted
                                                         ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                         : ""}
                                                   </span>
                                                </th>
                                             ))}
                                          </tr>
                                       ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                       {loading ? (
                                          <tr>
                                             <td colSpan={17} className="text-center">
                                                <Spinner animation="border" />
                                             </td>
                                          </tr>
                                       ) : report.length === 0 ? (
                                          <tr>
                                             <td className="fs-6" colSpan={17}>No Data Available</td>
                                          </tr>
                                       ) : (
                                          page.map(row => {
                                             prepareRow(row);
                                             return (
                                                <tr {...row.getRowProps()}>
                                                   {row.cells.map(cell => (
                                                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                   ))}
                                                </tr>
                                             );
                                          })
                                       )}
                                    </tbody>
                                 </Table>
                                 <div className="d-flex justify-content-between mb-3">
                                    <span>
                                       Showing {startIndex}-{endIndex} of {totalEntries} entries
                                       {remainingEntries > 0 && `, ${remainingEntries} entries remaining`}
                                    </span>
                                    <div className="pagination">
                                       <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                          Previous
                                       </button>
                                       <span>
                                          Page{' '}
                                          <strong>
                                             {pageIndex + 1} of {pageOptions.length}
                                          </strong>{' '}
                                       </span>
                                       <button onClick={() => nextPage()} disabled={!canNextPage}>
                                          Next
                                       </button>
                                    </div>
                                 </div>
                              </div>


                           )}
                        </div>
                     </div>
                  </div>
               </div>


            </div>
         </div>
      </div>
   )
}

export default Priorappointment