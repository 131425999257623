import 'bootstrap/dist/css/bootstrap.min.css';
import Customnavbar from './components/Customnavbar';
import Home from './pages/Home';
import { BrowserRouter as Router , Routes ,Route, useLocation, useNavigate } from 'react-router-dom';
import Priorappointment from './pages/Priorappointment';
import Appointment from './pages/Appointment';
import Quicksearch from './pages/Quicksearch';
import Addtherapist from './pages/Addtherapist';
import Mobileverification from './pages/Mobileverification';
import './App.css'
import MembershipAccount from './pages/MembershipAccount';
import MembershipRef from './pages/MembershipRef';
import DailySaleBook from './pages/DailySaleBook';
import Attendance from './pages/Attendance';
import Createservice from './pages/Createservice';
import WalkinAccount from './pages/WalkinAccount';
import Complementary from './pages/Complementary';
import AllmembershipAccount from './pages/AllmembershipAccount';
import Therapistreport from './pages/Therapistreport';
import SpaMaster from './pages/SpaMaster';
import Salereport from './pages/Salereport';
import Saleformat from './pages/Saleformat';
import Totalsale from './pages/Totalsale';
import Nearby from './pages/Nearby';
import Membershipreference from './pages/Membershipreference';
import Dailysale from './pages/Dailysale';
import ModuleComplentary from './pages/ModuleComplentary';
import ModuleWalkin from './pages/ModuleWalkin';
import ModuleMembership from './pages/ModuleMembership';
import Spamarketing from './pages/Spamarketing';
import MainDashboard from './pages/MainDashboard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { fetchdata } from './store/slice/userdataslice'
import Viewmembershipserviceuser from './pages/Viewmembershipserviceuser';
import Profile from './pages/Profile';
import Quicksearchsubadmin from './pages/Quicksearchsubadmin';
import Advancesearchsubadmin from './pages/Advancesearchsubadmin';
import { Toaster } from 'react-hot-toast';
import Tipaccount from './pages/Tipaccount';
import PaidAccount from './pages/PaidAccount';
import Renew from './pages/Renew';
import Followup from './pages/Followup';
import Serviceadmin from './pages/Serviceadmin';
import { Tipaccountadmin } from './pages/Tipacountadmin';
import Paidadmin from './pages/Paidadmin';
import Renewadmin from './pages/Renewadmin';
import UpdateService from './pages/UpdateService';
import WalkinInvoice from './pages/WalkinInvoice';
import { logout } from './store/slice/logout';
import axios from 'axios';


function App() {
  useEffect(()=>{
    const getapi=async()=>{
      const api= await axios.get('/api.json')
     let data=api.data.Apipathurl
      console.log(api,"apihex------------")
      if(data){
        localStorage.setItem('Apipathurl',`${data}`)
        // localStorage.setItem('userid','78')
      }
  }
  getapi()
  
  },[])
  const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
      let timeout;

      const resetTimer = () => {       
          if (timeout) {
              clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
              dispatch(logout());
              window.location.href = "https://bagero.hexbss.xyz/login"; 
          }, 30 * 60 * 1000 ); 
      };
      const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible') {    
              resetTimer();
          }
      };
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('click', resetTimer);
      window.addEventListener('scroll', resetTimer);
      document.addEventListener('visibilitychange', handleVisibilityChange);
      resetTimer();
      return () => {          
          window.removeEventListener('mousemove', resetTimer);
          window.removeEventListener('keypress', resetTimer);
          window.removeEventListener('click', resetTimer);
          window.removeEventListener('scroll', resetTimer);
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          clearTimeout(timeout);
      };
  }, [dispatch, navigate]);


  useEffect(()=>{
    if(localStorage.getItem('userid')===null){
      window.location.href="https://bagero.hexbss.xyz/login"
    }else{
      dispatch(fetchdata())
    }

  },[])
const location =useLocation()
  const showNavbar = !location.pathname.startsWith('/invoice');
  return (

   <>
   { showNavbar &&  <Customnavbar/> }
      
       <Toaster containerStyle={{ position: 'absolute' }} />
      <Routes>
        <Route>
            <Route path="/" element={<Home/>}>
            <Route path='' element={<MainDashboard/>}/>
            <Route path='profile' element={<Profile/>}/>
            <Route path="priorappointment" element={<Priorappointment/>}/>
            <Route path="appointment" element={<Appointment/>}/>
            <Route path="viewservice/:userid/:memberid" element={<Viewmembershipserviceuser/>}/>
            <Route path="Serviceadmin/:userid/:memberid" element={<Serviceadmin/>}/>
            <Route path='paidaccount/:userid/:memberid' element={<PaidAccount/>}/>
            <Route path='paidadmin/:userid/:memberid' element={<Paidadmin/>}/>
            <Route path='tipaccount/:userid/:memberid' element={<Tipaccount/>}/>
            <Route path='tipadmin/:userid/:memberid' element={<Tipaccountadmin/>}/>
            <Route path='renewservice/:userid/:memberid' element={<Renew/>}/>
            <Route path='renewserviceadmin/:userid/:memberid' element={<Renewadmin/>}/>
            <Route path='followup/:userid/:memberid' element={<Followup/>}/>
            <Route path='quicksearch' element={<Quicksearch/>}/>
            <Route path='quicksearchsubadmin' element={<Quicksearchsubadmin/>}/>
            <Route path='advancesearchsubadmin' element={<Advancesearchsubadmin/>}/>
            <Route path='createaccount' element={<Createservice/>}/>
            <Route path='addtherapist' element={<Addtherapist/>}/>
            <Route path='membership_account' element={<MembershipAccount/>}/>
            <Route path='membership_account_view' element={<ModuleMembership/>}/>
            <Route path='walkin_account' element={<WalkinAccount/>}/>
            <Route path='walkin_account_view' element={<ModuleWalkin/>}/>
            <Route path='complentary_account' element={<Complementary/>}/>
            <Route path='complentary_account_view' element={<ModuleComplentary/>}/>
            <Route path='mobileverification' element={<Mobileverification/>}/>
            <Route path='membership_account' element={<MembershipAccount/>}/>
            <Route path='dailysalebook' element={<DailySaleBook/>}/>
            <Route path='membership' element={<MembershipRef/>}/>
            <Route path='attendance' element={<Attendance/>}/>
            <Route path='allmembership' element={<AllmembershipAccount/>}/>
            <Route path='therapistreport' element={<Therapistreport/>}/>
            <Route path='spamaster' element={<SpaMaster/>}/>
            <Route path='edit/:userid' element={<UpdateService/>} />
            <Route path='spamarketing' element={<Spamarketing/>}/>
            <Route path='salereport' element={<Salereport/>}/>
            <Route path='saleformat' element={<Saleformat/>}/>
            <Route path='totalsale' element={<Totalsale/>}/>
            <Route path='nearbysale' element={<Nearby/>}/>
            <Route path='membershipref' element={<Membershipreference/>}/>
            <Route path='dailyservicebook' element={<Dailysale/>}/>
           
            </Route>
            <Route path="/invoice/:branchtable/:userid" element={<WalkinInvoice/>}/>
        </Route>
      </Routes>
      </>
  )
}

export default App

